import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";

const StyledIcon = makeStyles((theme) => ({
  iconButton: {},
  image: {
    width: "50px",
    height: "50px",
    objectFit: "contain",
  },
}));

const Icon = ({
  iconName = "",
  icon,
  onClick = null,
  type = "jsx",
  width = "40px",
  height = "40px",
  marginTop = "",
  marginRight = "",
  marginLeft = "",
  ...props
}) => {
  const classes = StyledIcon();

  return type === "img" ? (
    <img alt={iconName} src={icon} className={classes.image} />
  ) : (
    <IconButton
      aria-label={iconName}
      className={classes.iconButton}
      onClick={onClick}
      sx={{
        ...props,
        width: width,
        height: height,
        marginRight: "0px !important",
        marginTop: marginTop,
        marginLeft: marginLeft,
      }}
    >
      {icon}
    </IconButton>
  );
};

export default Icon;
