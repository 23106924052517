import { Box, Grid } from "@mui/material";
import {
  MonthlyCallsDataArray,
  callsHistoryRow,
  callsHistoryTablecolumns,
} from "assets/static/ccmStaticData";
import { genders, monthlyCallOptions } from "assets/static/options";
import { useFormik } from "formik";
import Button from "jsx/Common/Button/Button";
import CustomRadioGroup from "jsx/Common/FormComponents/CustomRadioGroup";
import InputField from "jsx/Common/FormComponents/InputField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import CustomTable from "jsx/Common/Table/CustomTable";
import Text from "jsx/Common/Typography/Text";
import { useEffect, useState } from "react";
import { monthlyCallSchema } from "../Validations/MonthlyCall/monthlyCallSchema";
import Wrapper from "jsx/Common/Wrapper";

const MonthlyCall = () => {
  const formik = useFormik({
    initialValues: {
      patientName: "",
      dateOfDiagnosis: "",
      birthDate: "",
      gender: "",
      contactNumber: "",
      insurance: "",
      insuranceNo: "",
      date: "",
      time: "",
      status: "",
      callRemarks: "",
      patientCurrentCondition: "",
      currentTreatmentTherapy: "",
      chronic1: "",
      chronic2: "",
      chronic3: "",
      subType1: "",
      subType2: "",
      subType3: "",
      carePlanGoals: "",
      medicalManagement: "",
      careCoordination: "",
      psychosocialSupport: "",
      educationAndLifestyleModifications: "",
      followUpAndMonitoring: "",
      carePlanEvaluation: "",
    },
    validationSchema: monthlyCallSchema,
    onSubmit: async (values) => {
      formik.resetForm();
    },
  });

  const [phone, setPhone] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures the effect runs only once after mount

  return (
    <>
        <Grid container spacing={2}>
          <InputField
            label={"Patient Name :"}
            backgroundColor="#fff"
            cols={3}
            name="patientName"
            formik={formik}
          />
          <InputField
            label={"DoB :"}
            type="date"
            cols={3}
            name="birthDate"
            formik={formik}
          />
          <InputField
            label={"Gender :"}
            name="gender"
            options={genders}
            placeholder="Female"
            cols={3}
            formik={formik}
          />
          <InputField
            label={"Contact Number :"}
            placeholder="0123456789"
            cols={3}
            name="contactNumber"
            formik={formik}
          />
          <InputField
            label={"Date of Diagnosis :"}
            placeholder="XYZ Insurance"
            cols={3}
            name="dateOfDiagnosis"
            formik={formik}
          />
          <InputField
            label={"Insurance # :"}
            placeholder="0123456789"
            cols={3}
            name="insurance"
            formik={formik}
          />
          <InputField
            label={"Insurance :"}
            placeholder="XYZ Insurance"
            cols={3}
            name="insuranceNo"
            formik={formik}
          />
        </Grid>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item desktop={4} laptop={3} tablet={6} mobile={12}>
            <Box
              sx={{
                backgroundColor: "#F3F3F3",
                p: 2,
                borderRadius: "10px",
                height: "100%",
              }}
            >
              <Text variant="h4">Call Log</Text>
              <Grid container spacing={2}>
                <InputField
                  cols={12}
                  label={"Pick a date :"}
                  type="date"
                  name="date"
                  formik={formik}
                />
                <InputField
                  cols={12}
                  label={"Pick a time :"}
                  type="time"
                  name="time"
                  formik={formik}
                />
                <InputField
                  label={"Status :"}
                  placeholder="Success"
                  cols={12}
                  name="status"
                  formik={formik}
                />
                <CustomRadioGroup
                  marginTop="10px"
                  label={"Attended by :"}
                  name="consentRecorded"
                  formik={formik}
                  children={["Patient", "Caregiver", "Other"]}
                  cols={6}
                />

                <InputField
                  cols={12}
                  multiline
                  rows={10}
                  label={"Call Remarks :"}
                  name="callRemarks"
                  formik={formik}
                />
                <Grid item sx={{ width: "100%" }}>
                  <Box
                    sx={{ width: "100%" }}
                    className="designCareTable second"
                  >
                    <Text variant="body2" mt={"6px"} mb={"10px"}>
                      Calls History :
                    </Text>
                    <CustomTable
                      data={callsHistoryRow}
                      columns={callsHistoryTablecolumns}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Button
                text={"Save"}
                sx={{ width: "100%" }}
                onClick={formik.submitForm}
              />
            </Box>
          </Grid>
          <Grid item desktop={8} laptop={8} tablet={6} mobile={12}>
            <Box
              sx={{
                backgroundColor: "#F3F3F3",
                p: 2,
                borderRadius: "10px",
                height: "100%",
              }}
            >
              <Grid container spacing={2}>
                <SelectField
                  label={"Chronic 1 :"}
                  placeholder="Alzheimer’s Diease"
                  required
                  name="chronic1"
                  options={monthlyCallOptions}
                  cols={4}
                  formik={formik}
                />
                <SelectField
                  label={"Chronic 2 :"}
                  placeholder="Alzheimer’s Diease"
                  required
                  name="chronic2"
                  options={monthlyCallOptions}
                  cols={4}
                  formik={formik}
                />
                <SelectField
                  label={"Chronic 3 :"}
                  placeholder="Alzheimer’s Diease"
                  required
                  options={monthlyCallOptions}
                  name="chronic3"
                  cols={4}
                  formik={formik}
                />
                <SelectField
                  label={"Subtype:"}
                  placeholder="Early-Onset  Disease"
                  name="subType1"
                  options={monthlyCallOptions}
                  cols={4}
                  formik={formik}
                />
                <SelectField
                  label={"Subtype :"}
                  placeholder="Early-Onset  Disease"
                  name="subType2"
                  options={monthlyCallOptions}
                  cols={4}
                  formik={formik}
                />
                <SelectField
                  label={"Subtype :"}
                  placeholder="Early-Onset  Disease"
                  name="subType3"
                  options={monthlyCallOptions}
                  cols={4}
                  formik={formik}
                />
              </Grid>

              <Grid container spacing={1}>
                {MonthlyCallsDataArray?.map(
                  (
                    { type, data, tableColumns, cols, heading, ...props },
                    ind
                  ) => {
                    return (
                      <>
                        {type === "table" ? (
                          <Grid item desktop={cols}>
                            <Text my={1} variant="body2">
                              {heading}
                            </Text>
                            <CustomTable
                              {...props}
                              data={data}
                              columns={tableColumns}
                            />
                          </Grid>
                        ) : (
                          <InputField
                            cols={cols}
                            {...props}
                            multiline
                            rows={3}
                            key={ind}
                          />
                        )}
                      </>
                    );
                  }
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
    </>
  );
};
export default MonthlyCall;
