import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Grid, LinearProgress } from "@mui/material";
import request from "api/request";
import { StateSelectOption } from "assets/static/ccmStaticData";
import { genders } from "assets/static/options";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import CustomImageUpload from "jsx/Common/FormComponents/CustomImageUpload";
import InputField from "jsx/Common/FormComponents/InputField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import Loader from "jsx/Common/Loaders/Loader";
import Text from "jsx/Common/Typography/Text";
import { memo, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

const DemographicsForm = ({ formik }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [phoneFocused, setPhoneFocused] = useState(false);
  const [phone, setPhone] = useState("");
  const [calculatedAge, setCalculateAge] = useState(null);

  const { useFetch, useAppMutation } = useReactQuery();
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const currentDate = new Date().toISOString().split("T")[0]; // Get the current date in YYYY-MM-DD

  function calculateAge(dateOfBirth) {
    const today = new Date();
    const dob = new Date(dateOfBirth);
    let ageYears = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();
    let ageMonths = monthDiff;
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      ageYears--;
      ageMonths = 12 - Math.abs(monthDiff);
    }

    return { years: ageYears, months: ageMonths };
  }
  const handleImageUpload = (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("profileImage", file);
      imageUploadAPI(formData); // This triggers the image upload API call
    }
  };

  const handleFileChange = (file) => {
    setSelectedFile(file);
  };

  useEffect(() => {
    if (!formik?.errors?.profileImage && selectedFile) {
      handleImageUpload(selectedFile);
    }
  }, [selectedFile]);

  // api for get states
  const { data: stateData, isLoading: statesLoading } = useFetch("states", () =>
    request({
      method: "get",
      url: "/states",
    })
  );
  //upload-attachments
  const { mutate: imageUploadAPI, isLoading: imageUploadLoading } =
    useAppMutation(
      (data) =>
        request({
          method: "post",
          url: "/patient/profile-image",
          data,
        }),
      {
        onSuccess: (data) => {
          showToast("Image Uploaded successfully", {
            type: "success",
          });

          queryClient.invalidateQueries(["ccm-patients"]);
          formik.setFieldValue("patient_img_id", data?.data?.id);
        },
        onError: (data) => {
          if (data.response.status === 422) {
            showToast(
              "The profile image must be a file of type: jpeg, jpg, png.",
              {
                type: "error",
              }
            );
          }
        },
      }
    );

  useEffect(() => {
    formik.setFieldValue("mobilePhone", phone);
  }, [phone]);

  useEffect(() => {
    setPhone(formik.values.mobilePhone);
  }, []);

  // calculate age from date of birth
  useEffect(() => {
    setCalculateAge(calculateAge(formik.values.birthDate).years);
    formik.setFieldValue("age", calculateAge(formik.values.birthDate).years);
  }, [formik.values.birthDate]);

  return (
    <Box>
      {statesLoading ? (
        <Loader />
      ) : (
        <Grid container spacing={2}>
          <Grid item mobile={12} tablet={8} desktop={9}>
            <Grid container spacing={1} columnSpacing={1}>
              <InputField
                cols={6}
                formik={formik}
                label={"First Name"}
                name="firstName"
                required
              />
              <InputField
                cols={6}
                formik={formik}
                label={"Last Name"}
                name="lastName"
                required
              />

              <InputField
                max={currentDate}
                cols={6}
                label={"Date Of Birth"}
                name="birthDate"
                type={"date"}
                formik={formik}
                required
              />
              <SelectField
                label={"Gender "}
                name="gender"
                options={genders}
                defaultValue={{
                  label: formik.values.gender
                    ? genders.find(
                        (gender) => gender.value === formik.values.gender
                      )?.label
                    : "",
                  value: formik.values.gender
                    ? genders.find(
                        (gender) => gender.value === formik.values.gender
                      )?.value
                    : "",
                }}
                cols={6}
                formik={formik}
                required
              />
              <InputField
                cols={6}
                formik={formik}
                label={"Email"}
                name="email"
              />
              <InputField
                cols={6}
                errorMessage="Phone is required"
                showError={!phone && phoneFocused ? true : false}
                label={"Phone Number"}
                type={"text"}
                isPhone={true}
                value={phone}
                onBlur={(e) => setPhoneFocused(true)}
                onChange={(e) => setPhone(e.target.value)}
                name="mobilePhone"
                required
              />
              <InputField
                cols={12}
                label={"Street Address"}
                name="address1"
                formik={formik}
                required
              />
            </Grid>
            <Grid container mt={"3px"} spacing={1}>
              <InputField
                cols={4}
                formik={formik}
                label={"City"}
                name="city"
                required
              />
              <InputField
                cols={4}
                formik={formik}
                label={"Zip"}
                name="zipcode"
                required
              />
              <SelectField
                label={"State "}
                name="state"
                options={StateSelectOption}
                defaultValue={{
                  label: formik.values.state
                    ? StateSelectOption.find(
                        (option) => option.value === formik.values.state
                      )?.label
                    : "",
                  value: formik.values.state
                    ? StateSelectOption.find(
                        (option) => option.value === formik.values.state
                      )?.value
                    : "",
                }}
                cols={4}
                formik={formik}
                required
              />
            </Grid>
          </Grid>
          <Grid item mobile={12} tablet={4} desktop={3}>
            <Box
              sx={{
                border: "12px solid #DADADA",
                padding: "10px",
                mt: 5,
              }}
            >
              <Flex direction="column">
                <Box
                  sx={{
                    border: "1px solid #ffffff",
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {selectedFile ? (
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      alt="Profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        opacity: imageUploadLoading ? "0.5" : "1",
                      }}
                    />
                  ) : (
                    <CloudUploadIcon
                      sx={{ fontSize: "100px", color: "#0394B4" }}
                    />
                  )}
                </Box>
              </Flex>
              {imageUploadLoading ? <LinearProgress /> : ""}
            </Box>
            <Flex>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <CustomImageUpload
                  onFilesChange={handleFileChange}
                  label="Upload Image"
                  name="profileImage"
                />
              </Box>
            </Flex>
            {!selectedFile ? (
              <Flex>
                <Text
                  variant="caption"
                  fontWeight="500"
                  maxWidth="max-content"
                  my={0}
                >
                  Please ensure your file is either JPG, JPEG, or PNG format and
                  does not exceed 1MB in size.
                </Text>
              </Flex>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default memo(DemographicsForm);
