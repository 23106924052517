import { Grid } from "@mui/material";
import InputField from "jsx/Common/FormComponents/InputField";
import { memo, useEffect, useState } from "react";

const PharmacyFrom = ({ formik }) => {
  const [phone, setPhone] = useState("");

  useEffect(() => {
    setPhone(formik.values.pharmacyPhone);
  }, []);

  useEffect(() => {
    formik.setFieldValue("pharmacyPhone", phone);
  }, [phone]);
  return (
    <Grid container spacing={1} columnSpacing={1}>
      <InputField formik={formik} label={"Name"} name="pharmacyName" cols={4} />

      <InputField
        label="Phone"
        isPhone
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        cols={4}
      />
      <InputField
        formik={formik}
        label={"Address 1"}
        name="pharmacyAddress1"
        cols={4}
      />
    </Grid>
  );
};

export default memo(PharmacyFrom);
