import { Typography, useTheme } from "@mui/material";

const Text = ({
  children,
  variant = "h2",
  center = false,
  color,
  my = 2,
  ...props
}) => {
  const theme = useTheme();

  const colors = {
    blue: theme.palette.primary.main,
    lightBlue: "#0195FF",
    oceanBlue: "#0195FF",
    lightGreen: "#5EC4B2",
    orange: "#FF9314",
    grey: theme.palette.text.primary,
    white: theme.palette.common.white,
    black: theme.palette.common.black,
    red: "#C41313",
    lightGrey: "#64748B",
    redLight: "#F23F3F",
    darkGrey: "#A2A2A2",
  };

  const textColor = colors[color] || "unset";
  return (
    <Typography
      variant={variant}
      align={center ? "center" : undefined}
      sx={() => {
        if (color) {
          return { color: textColor, ...props };
        } else return { ...props };
      }}
      my={my}
    >
      {children}
    </Typography>
  );
};

export default Text;
