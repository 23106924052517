import CloseIcon from "@mui/icons-material/Close";
import { Box, Slide, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { forwardRef } from "react";
import Icon from "../Icons/Icon";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CustomModal = ({
  children,
  title,
  open,
  setOpen,
  width = "auto",
  maxWidth = "laptop",
  fullWidth = false,
  padding = "",
  className = "",
  minHeight = "",
}) => {
  const theme = useTheme();
  const handleClose = () => {
    setOpen && setOpen(false);
  };

  return (
    <Dialog
      className="customDialog"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        sx: {
          minHeight: minHeight,
        },
      }}
      sx={{ width: width }}
    >
      <DialogTitle
        id="scroll-dialog-title"
        sx={{
          textAlign: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {title}
        <Box
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 5,
            right: 10,
            cursor: "pointer",
            backgroundColor: "rgba(85, 121, 180)",
            borderRadius: "100px",
          }}
        >
          <Icon icon={<CloseIcon />} color={theme.palette.common.white} />
        </Box>
      </DialogTitle>
      <DialogContent
        className={className}
        sx={{
          minWidth: 500,
          padding: padding,
        }}
      >
        <Box p={2}>{children}</Box>
      </DialogContent>
    </Dialog>
  );
};

export default CustomModal;
