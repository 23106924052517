import { Box, Grid } from '@mui/material';
import { makeStyles } from "@mui/styles";
import forgotPassword from "assets/images/forgot_pass_bg.png";
import updatedPassword from "assets/images/pass_updated.svg";
import { appRoutes } from 'assets/static/appRoutes';
import Button from "jsx/Common/Button/Button";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import Logo from "jsx/Common/Logo/Logo";
import Text from "jsx/Common/Typography/Text";
import { useNavigate } from 'react-router-dom';
import backArrow from "../../../../../assets/images/Icon awesome-long-arrow-alt-down.svg";
const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: "5px",
    },
    logo: { width: "400px", height: "40px", marginBottom: "30px" },
    loginLeftside: {
        padding: "40px 110px 30px 110px",
        boxShadow: "0px 8px 12px #00000029",
    },
    loginRightside: {
        backgroundImage: `url(${forgotPassword})`,
        backgroundSize: "cover",
        backgroundPosition: "10% 10%",
        height: "100%",
        display: "flex",
        flexDirection: "column !important",
        alignItems: "center",
        justifyContent: "center",
    },
    icon: { width: 50, height: 50 },
    colors: { color: "#666666" },
    signupColors: { color: theme.palette.primary.main, textDecoration: "none" },
    forgotPasswordImg: {
        width: "134px",
        height: "134px",
        margin: "15px 0px"
    },
    BackBtnClass: {
        backgroundColor: 'transparent !important',
        color: "#1C4588 !important",
        boxShadow: "none !important",
        width: "145px",
        paddingLeft: "30px !important",
        fontSize: "14px !important"
    },
    buttonBox: {
        position: "relative",
    },
    buttonIcon: {
        position: "absolute",
        bottom: "15px",
        left: "7px",
        color: "#666666",
        width: "20px !important",
        height: "9px !important"
    },
    headingClass: {
        fontSize: "28px"
    },
    continueButton: {
        marginTop: "25px !important",
        width: "150px !important",
        height: "40px !important",
        fontSize: "14px !important"
    },
    InpParent: {
        '& .MuiOutlinedInput-root': {
            borderRadius: "0px !important",
            borderBottom: '1px solid #ccc !important',
            borderTop: 'none !important',
            borderLeft: 'none !important',
            borderRight: 'none !important',
            marginBottom: "10px"
        },
    },
}));
const PasswordUpdated = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    return (
        <Box height="100vh" width="100%" sx={{ display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden" }}>
            <Grid container spacing={2} maxWidth="1440px" >
                <Grid item mobile={12}>
                    <Grid container className={classes.root}>
                        <Grid item mobile={1} />
                        <Grid
                            item
                            laptop={5}
                            desktop={4}
                            sx={{
                                display: {
                                    mobile: "none",
                                    tablet: "none",
                                    laptop: "flex",
                                    desktop: "flex",
                                },
                            }}
                        >
                            <Grid item mobile={12} className={classes.loginRightside}>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            mobile={10}
                            sm={10}
                            md={10}
                            laptop={5}
                            desktop={6}
                            className={classes.loginLeftside}
                        >
                            <Grid item mobile={12} sx={{ textAlign: "center" }} >
                                <Flex justifyContent='center'>
                                    <Logo />
                                </Flex>
                                <Flex >
                                    <img src={updatedPassword} className={classes.forgotPasswordImg} alt="Forgot Password Icon" />
                                </Flex>
                                <Text className={classes.headingClass} marginTop="0px" marginBottom="4px">Password Updated! </Text>
                                <Text variant="body2" marginTop="20px" marginBottom="5px">Your password has been changed successfully.</Text>
                                <Text variant="body2" marginTop="0px" marginBottom="0px">Click below to log in magically.</Text>
                            </Grid>
                            <Grid item mobile={12} marginTop="5px">
                                <Flex >
                                    <Box className={classes.buttonBox}>
                                        <img src={backArrow} className={classes.buttonIcon} alt="" />
                                        <Button className={classes.BackBtnClass}
                                            variant="contained"
                                            type="submit"
                                            text={"Back to log in"}
                                            onClick={() => { navigate(appRoutes.loginRedirect) }}
                                        />
                                    </Box>
                                </Flex>
                            </Grid>
                        </Grid>
                        <Grid item mobile={1} />
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    )
}
export default PasswordUpdated