import { Box, Grid } from "@mui/material";
import {
  LabTestModalTableColumns,
  LabTestModalTableData,
} from "assets/static/ccmStaticData";
import Button from "jsx/Common/Button/Button";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import InputField from "jsx/Common/FormComponents/InputField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import CustomTable from "jsx/Common/Table/CustomTable";

const LabTestModal = ({ onClickCancel }) => {
  const customHeaderStyle = {
    color: "#1C4588",
    fontSize: "16px",
    fontWeight: 600,
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          spacing={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <SelectField cols={5} label={"Lab Test Name"} />
          <InputField cols={5} label={"Value"} />
          <Grid item desktop={2}>
            <Button text={"Add"} width="100%" />
          </Grid>
        </Grid>
        <Box sx={{ my: 1 }}>
          <CustomTable
            thStyle={customHeaderStyle}
            data={LabTestModalTableData}
            columns={LabTestModalTableColumns}
          />
        </Box>
        <FormButtons onClickCancel={onClickCancel} />
      </Box>
    </>
  );
};

export default LabTestModal;
