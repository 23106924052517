import * as yup from "yup";

const DesignCarePlanSchema = yup.object().shape({
  chronic1: yup.string().required("Chronic1 is required"),
  chronic2: yup.string().required("Chronic2 is required"),
  chronic3: yup.string().required("Chronic3 is required"),
});
export { DesignCarePlanSchema };


