import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CreateIcon from "@mui/icons-material/Create";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, IconButton } from "@mui/material";
import request from "api/request";
import useReactQuery from "hooks/useReactQuery";
import Icon from "jsx/Common/Icons/Icon";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import CustomModal from "jsx/Common/Modal/CustomModal";
import GenericTable from "jsx/Common/Table/GenericTable";
import Text from "jsx/Common/Typography/Text";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import EstablishComplexityForm from "../NewDischargePatient/Form/EstablishComplexityForm";
import PatientDetailForm from "../NewDischargePatient/Form/PatientDetailForm";
import useFilteredPermissions from "hooks/useFilteredPermissions";

const AwaitingEstablishComplexity = ({ dischageSummaryDetail }) => {
  const { id } = useParams();
  const { hasPermission } = useFilteredPermissions();
  const [validateCount, setValidateCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [patientDetailModal, setPatientDetailModal] = useState(false);
  const [dischargeIdAvailable, setDischargeIdAvailable] = useState(false);

  const [dischargeID, setDischargeID] = useState(null);

  const [openFile, setOpenFile] = useState(false);
  const [selectedEstablish, setSelectedEstablish] = useState({});
  const { useFetch } = useReactQuery();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  const { data, isLoading } = useFetch(
    `dischargesummeries${id ? `/${id}` : ""}`,
    () =>
      request({
        method: "get",
        url: `/dischargesummeries${id ? `/${id}` : ""}`,
      })
  );

  const { data: dischargeSummaryDetailData } = useFetch(
    [`/dischargeSummaryDetail/${dischargeID}`, validateCount],
    () =>
      request({
        method: "get",
        url: `/dischargeSummaryDetail/${dischargeID}`,
      }),
    {
      enabled: dischageSummaryDetail && dischargeID !== null ? true : false,
    }
  );

  useEffect(() => {
    if (dischargeID !== null) {
      setDischargeIdAvailable(true);
    }
    return () => {
      queryClient.removeQueries(`/dischargeSummaryDetail/${dischargeID}`);
    };
  }, [dischargeID]);

  const dischargeOverviewColumn = [
    {
      Header: "Patient Name",
      accessor: (row) => {
        return `${row?.patient?.firstName} ${row?.patient?.initialName} ${row?.patient?.lastName}`;
      },
    },
    {
      Header: "Admission  Date",
      accessor: (row) => {
        const admissionDate = moment(row?.admitDate).format("MM-DD-YYYY");
        return <>{admissionDate}</>;
      },
    },

    {
      Header: "Date Of Birth",
      accessor: (row) => {
        const dateOfBirth = moment(row?.patient?.birthDate).format(
          "MM-DD-YYYY"
        );
        return <span>{dateOfBirth}</span>;
      },
    },

    {
      Header: "Discharge Provider",
      accessor: (row) => {
        return `${row?.discharge_provider?.firstName} ${row?.discharge_provider?.lastName}`;
      },
    },
    {
      Header: "Assign To",
      accessor: (row) => {
        return `${row?.assign_to?.firstName} ${row?.assign_to?.lastName}`;
      },
    },
    {
      Header: "Assign Date & Time",
      accessor: (row) => {
        const assignDate = moment(row?.assignmentDateTime).format(
          "MM-DD-YYYY hh:mm:ss"
        );
        return <span>{assignDate}</span>;
      },
    },

    ...((dischageSummaryDetail && hasPermission("patient_view")) ||
      hasPermission("dischargeSummary_view")
      ? [
        {
          Header: "Discharge Summary",
          Cell: ({ row }) => {
            return (
              <Flex>
                <Text variant="h4" color="blue">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectedEstablish(row?.original);
                      setOpenFile(true);
                    }}
                  >
                    Preview
                  </span>
                </Text>
              </Flex>
            );
          },
        },
      ]
      : []),

    ...(dischageSummaryDetail && hasPermission("patientReport_view")
      ? [
        {
          Header: "Report ",
          accessor: "report",
          Cell: ({ row }) => {
            return (
              <Flex>
                <IconButton onClick={() => { }}>
                  <a
                    href={`${process.env.REACT_APP_REPORT_URL}/api/generatePDF/${row?.original?.id
                      }?token=${localStorage.getItem("token")}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <VisibilityIcon sx={{ color: "#20498A" }} />
                  </a>
                </IconButton>
              </Flex>
            );
          },
        },
      ]
      : []),

    ...(dischageSummaryDetail && hasPermission("patient_view_create_edit")
      ? [
        {
          Header: "Actions",
          accessor: "actions",
          Cell: ({ row }) => {
            return (
              <Flex>
                <Icon
                  icon={<CreateIcon />}
                  onClick={() => {
                    setDischargeID(row?.original?.id);
                    setPatientDetailModal(true);
                  }}
                  color="#1C4588"
                />
              </Flex>
            );
          },
        },
      ]
      : []),

    ...(!dischageSummaryDetail &&
      hasPermission("dischargeSummary_view_create_edit")
      ? [
        {
          Header: "Actions",
          accessor: "actions",
          Cell: ({ row }) => {
            return (
              <Flex>
                <Icon
                  icon={<ControlPointIcon />}
                  onClick={() => {
                    setSelectedEstablish(row?.original);
                    setOpen(true);
                  }}
                  color="#1C4588"
                />
              </Flex>
            );
          },
        },
      ]
      : []),
  ];

  const openPdf = (url) => {
    window.open(url, "_blank", "height=600,width=800");
  };

  return (
    <Box>
      <CustomModal
        setOpen={setOpen}
        open={open}
        title="Add Establish Complexity"
        width="auto"
        maxWidth="laptop"
        fullWidth={false}
      >
        <EstablishComplexityForm
          setOpen={setOpen}
          selectedEstablish={selectedEstablish}
          setSelectedEstablish={setSelectedEstablish}
        />
      </CustomModal>
      <CustomModal
        setOpen={setPatientDetailModal}
        open={patientDetailModal}
        title="Patient Detail"
        width="auto"
        maxWidth="laptop"
        fullWidth={false}
      >
        <PatientDetailForm
          setValidateCount={setValidateCount}
          validateCount={validateCount}
          setOpenModal={setPatientDetailModal}
          dischargeData={dischargeSummaryDetailData?.data}
        />
      </CustomModal>
      <CustomModal
        setOpen={setOpenFile}
        open={openFile}
        title="Preview Files"
        width="auto"
        maxWidth="tablet"
        fullWidth={false}
      >
        <div className="preview_file">
          <Text variant="h3">Preview Uploaded Files</Text>
          {selectedEstablish?.attachments?.length
            ? selectedEstablish?.attachments?.map(({ name, url }, index) => {
              return (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => openPdf(url)}
                >
                  {index + 1}- {name}
                </div>
              );
            })
            : ""}
          {!selectedEstablish?.attachments?.length ? (
            <Text variant="body2">No Files</Text>
          ) : (
            ""
          )}
        </div>
      </CustomModal>

      {dischageSummaryDetail && (
        <Flex justifyContent="flex-start">
          <Text variant="h3" color={"blue"}>
            Encounters
          </Text>
        </Flex>
      )}
      <GenericTable
        isLoading={isLoading}
        search={dischageSummaryDetail ? false : true}
        columns={dischargeOverviewColumn}
        data={data?.dischargeSummeries}
      />
    </Box>
  );
};

export default AwaitingEstablishComplexity;
