import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from "@mui/material";
import request from "api/request";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import CustomRadioGroup from "jsx/Common/FormComponents/CustomRadioGroup";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import InputField from "jsx/Common/FormComponents/InputField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import CustomModal from "jsx/Common/Modal/CustomModal";
import PatientDetail from "jsx/Common/PatientDetail/PatientDetail";
import Text from "jsx/Common/Typography/Text";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import elipses from "utils/elipses";
import { InitialContacSchema } from "validations/DischargePatient/initialContact";
import NurseComment from "../../Listing/NurseComment/NurseComment";
import AppointmentForm from "./AppointmentForm";
import QuestionAireComponent from "./QuestionAireComponent";
const InitialContactForm = ({
  validateCount = 0,
  setValidateCount,
  setOpen,
  selectedComplexity,
  setSelectedComplexity,
  isAppointment = false,
  pateintDetailSummary = false,
  dischargeData = {},
  saveID = "",
  cancelID = "",
}) => {
  const { id } = useParams();
  const [openFile, setOpenFile] = useState(false);
  const [initialContactId, setInitialContactId] = useState("");
  const [firstTime, setFirstTime] = useState(true);
  const [isAppointmentFormSubmit, setIsAppointmentFormSubmit] = useState(false);
  const [isInitialSucc, setIsInitialSucc] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const { useAppMutation, useFetch } = useReactQuery();
  const { showToast } = useToast();
  const [summaryDisacharge, setSummaryDisacharge] = useState();
  const [showReminder, setShowReminder] = useState(false);
  const queryClient = useQueryClient();

  const handleCancel = () => {
    setOpen && setOpen(false);
  };

  const { data: patientData, isLoading: patientDataLoading } = useFetch(
    `/patient/${id ?? selectedComplexity?.patient?.id}`,
    () =>
      request({
        method: "get",
        url: `/patient/${id ?? selectedComplexity?.patient?.id}`,
      })
  );

  const { data: dataSummary } = useFetch(
    `dischargesummeriesdata${
      id ? `/${id}` : `/${selectedComplexity?.patient?.id}`
    }`,
    () =>
      request({
        method: "get",
        url: `/dischargesummeries${
          id ? `/${id}` : `/${selectedComplexity?.patient?.id}`
        }`,
      })
  );

  const { mutate: updateInitialMutate, isLoading: isUpdateInitialLoading } =
    useAppMutation(
      (data) =>
        request({
          method: "put",
          url: `/initialContacts/${dischargeData?.successfull_initial_contacts[0]?.id}`,
          data,
        }),
      {
        onSuccess: (data) => {
          setInitialContactId(data?.InitialContact?.id);
          showToast("Initial Contact Edited Successfully", {
            type: "success",
          });
          setValidateCount && setValidateCount(validateCount + 1);
        },
      }
    );

  const { mutate, isLoading } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: "/initialContacts",
        data,
      }),
    {
      onSuccess: (res) => {
        setInitialContactId(res?.InitialContact?.id);
        setIsInitialSucc(false);
        setIsAppointmentFormSubmit(true);
        showToast("Initial Contact Record Added", {
          type: "success",
        });
        queryClient.invalidateQueries([
          `/patient/${id ?? selectedComplexity?.patient?.id}`,
        ]);
        queryClient.invalidateQueries(["initialContacts"]);
        queryClient.invalidateQueries([
          `initialContacts/${id ?? selectedComplexity?.patient?.id}`,
        ]);
        queryClient.invalidateQueries([`establishComplexities`]);

        queryClient.invalidateQueries(["counts"]);
        formik.values.callOutCome !== "success" && formik.resetForm();
        formik.values.callOutCome !== "success" && handleCancel();
        formik.values.callOutCome !== "success" &&
          setSelectedComplexity &&
          setSelectedComplexity({});
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      discharge_id: dischargeData?.id
        ? dischargeData?.id
        : selectedComplexity?.discharge_id ??
          patientData?.patient?.discharge_patient_last?.id,
      establish_complexitie_id: dischargeData?.id
        ? dischargeData?.establish_complexities?.id
        : patientData?.patient?.establish_complexity_last?.id ??
          selectedComplexity?.id,
      patient_id: dischargeData?.id
        ? dischargeData?.patient?.id
        : selectedComplexity?.patient?.id ?? parseInt(id),
      contactMadeBy: dischargeData?.successfull_initial_contacts?.[0]?.id
        ? dischargeData?.successfull_initial_contacts?.[0]?.contactMadeBy
        : "",
      relationWithPatient: dischargeData?.successfull_initial_contacts?.[0]?.id
        ? dischargeData?.successfull_initial_contacts?.[0]?.relationWithPatient
        : "",
      contactMadeTo: dischargeData?.successfull_initial_contacts?.[0]?.id
        ? dischargeData?.successfull_initial_contacts?.[0]?.contactMadeTo
        : "",
      callOutCome: dischargeData?.successfull_initial_contacts?.[0]?.id
        ? dischargeData?.successfull_initial_contacts?.[0]?.callOutCome
        : "",
      consentRecorded: dischargeData?.successfull_initial_contacts?.[0]?.id
        ? dischargeData?.successfull_initial_contacts?.[0]?.consentRecorded
        : "",
      numberOfAttemps: "",
      assignDateTime: "",
      additionalNotes: dischargeData?.successfull_initial_contacts?.[0]?.id
        ? dischargeData?.successfull_initial_contacts?.[0]?.additionalNotes
        : "",
      remindertime: dischargeData?.id
        ? dischargeData?.successfull_initial_contacts?.[0]?.reminder_date_time?.split(
            " "
          )[1]
        : "",
      reminderdate: dischargeData?.id
        ? dischargeData?.successfull_initial_contacts?.[0]?.reminder_date_time?.split(
            " "
          )[0]
        : "",
      assignTo: dischargeData?.id
        ? dischargeData?.assignTo
        : selectedComplexity?.assign_to?.id,
    },
    validationSchema: InitialContacSchema,

    onSubmit: async (values) => {
      if (values.callOutCome === "success") {
        setIsAppointmentFormSubmit(true);
      } else {
        dischargeData?.id
          ? updateInitialMutate({
              ...values,
              consentRecorded: values.consentRecorded === "Yes" ? "Yes" : "No",
              contactMadeTo: values.contactMadeTo,
            })
          : mutate({
              ...values,
              consentRecorded: values.consentRecorded === "Yes" ? "Yes" : "No",
              contactMadeTo: values.contactMadeTo,
            });
      }
    },
  });

  useEffect(() => {
    isInitialSucc &&
      !initialContactId &&
      (dischargeData?.id
        ? updateInitialMutate({
            ...formik.values,
            consentRecorded:
              formik.values.consentRecorded === "Yes" ? "Yes" : "No",
            contactMadeTo: formik.values.contactMadeTo,
          })
        : mutate({
            ...formik.values,
            consentRecorded:
              formik.values.consentRecorded === "Yes" ? "Yes" : "No",
            contactMadeTo: formik.values.contactMadeTo,
          }));
  }, [isInitialSucc]);

  const openPdf = (url) => {
    window.open(url, "_blank", "height=600,width=800");
  };

  const { data: assignToData, isLoading: isAssignData } = useFetch(
    "assigntoNurse",
    () =>
      request({
        method: "post",
        data: { name: "call_agent" },
        url: "/assignto",
      })
  );

  useEffect(() => {
    setSummaryDisacharge(
      dataSummary?.dischargeSummeries?.find(
        ({ patient: disPatient }) =>
          disPatient?.id === (id ? id : selectedComplexity?.patient?.id)
      )
    );
  }, [dataSummary]);

  useEffect(() => {
    if (!firstTime) {
      if (formik.values.contactMadeTo == "Patient") {
        formik.setFieldValue(
          "contactMadeBy",
          `${
            dischargeData?.id
              ? dischargeData?.successfull_initial_contacts?.[0]?.patient
                  ?.firstName
              : selectedComplexity?.patient?.firstName
          } ${
            dischargeData?.id
              ? dischargeData?.successfull_initial_contacts[0]?.patient
                  ?.initialName
              : selectedComplexity?.patient?.initialName
          } ${
            dischargeData?.id
              ? dischargeData?.successfull_initial_contacts[0]?.patient
                  ?.lastName
              : selectedComplexity?.patient?.lastName
          }`
        );
      } else if (formik.values.contactMadeTo == "Caregiver") {
        formik.setFieldValue("contactMadeBy", "");
      } else if (formik.values.contactMadeTo == "Other") {
        formik.setFieldValue("contactMadeBy", "");
      }
    }
  }, [formik.values.contactMadeTo]);
  useEffect(() => {
    if (formik.values.callOutCome === "no-response") {
      formik.setValues({
        ...formik.values,
        contactMadeBy: "",
        additionalNotes: "",
        assignTo: "",
      });
    }
  }, [formik.values.callOutCome]);

  useEffect(() => {
    setFirstTime(false);
  }, []);

  const options = [
    { label: "Success", value: "success" },
    { label: "Pending", value: "pending" },
    { label: "Rejected", value: "rejected" },
    { label: "No Response", value: "no-response" },
  ];

  return (
    <>
      {(selectedComplexity || patientData?.patient) && !pateintDetailSummary ? (
        <PatientDetail
          selectedRecord={selectedComplexity}
          patientRecord={patientData}
        />
      ) : (
        ""
      )}

      {(summaryDisacharge || patientData?.patient) && !pateintDetailSummary ? (
        <Grid
          style={{
            border: "1px solid rgba(145,144,144,0.3)",
            borderRadius: "10px",
            padding: "0px 12px",
            marginTop: "10px",
          }}
          container
          spacing={2}
        >
          <Flex justifyContent="flex-start" paddingLeft="16px">
            <Text variant="h3" color="gray">
              Discharge Summary Details
            </Text>
          </Flex>
          {/* <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h3" color="blue">
                Patient Name: &nbsp;
              </Text>
              <Text variant="body2" color="grey">
                {`${
                  patientData?.patient?.firstName ??
                  summaryDisacharge?.patient?.firstName
                } ${
                  patientData?.patient?.lastName ??
                  summaryDisacharge?.patient?.lastName
                }`}
              </Text>
            </Flex>
          </Grid> */}
          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Created By: &nbsp;
              </Text>
              <Text variant="body2" color="grey">
                {`${
                  patientData?.patient?.created_by?.firstName ??
                  selectedComplexity?.created_by?.firstName
                } ${
                  patientData?.patient?.created_by?.lastName ??
                  selectedComplexity?.created_by?.lastName
                }`}
              </Text>
            </Flex>
          </Grid>
          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Discharge Provider: &nbsp;
              </Text>
              <Text variant="body2" color="grey">
                {`${
                  patientData?.patient?.discharge_patient_last
                    ?.discharge_provider?.firstName ??
                  summaryDisacharge?.discharge_provider?.firstName
                }`}
                &nbsp;
                {`${
                  patientData?.patient?.discharge_patient_last
                    ?.discharge_provider?.lastName ??
                  summaryDisacharge?.discharge_provider?.lastName
                }`}
              </Text>
            </Flex>
          </Grid>

          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                <span
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() => setOpenFile(true)}
                >
                  View Discharge
                </span>
              </Text>
            </Flex>
          </Grid>
        </Grid>
      ) : (
        ""
      )}

      {(selectedComplexity || patientData?.patient) && !pateintDetailSummary ? (
        <Grid
          style={{
            border: "1px solid rgba(145,144,144,0.3)",
            borderRadius: "10px",
            padding: "0px 12px",
            marginTop: "10px",
          }}
          container
          spacing={2}
        >
          <Flex justifyContent="flex-start" paddingLeft="16px">
            <Text variant="h3" color="gray">
              Complexity Details
            </Text>
          </Flex>

          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Assign By: &nbsp;
              </Text>
              <Text variant="body2" color="grey">
                {`${
                  patientData?.patient?.discharge_patient_last?.assign_to
                    ?.firstName ?? summaryDisacharge?.assign_to?.firstName
                } ${
                  patientData?.patient?.discharge_patient_last?.assign_to
                    ?.lastName ?? summaryDisacharge?.assign_to?.lastName
                }`}
              </Text>
            </Flex>
          </Grid>
          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                CPT Code: &nbsp;
              </Text>
              <Text variant="body2" color="grey">
                {`${
                  patientData?.patient?.establish_complexity_last?.cptCode ??
                  selectedComplexity?.cptCode
                }`}
              </Text>
            </Flex>
          </Grid>
          <Grid item mobile={12} tablet={4} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Patient Complexity: &nbsp;
              </Text>
              <Text variant="body2" color="grey">
                {`${
                  patientData?.patient?.establish_complexity_last
                    ?.patientComplexity ?? selectedComplexity?.patientComplexity
                }`}
              </Text>
            </Flex>
          </Grid>
          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Visit Due Date: &nbsp;
              </Text>
              <Text variant="body2" color="grey">
                {moment(
                  `${
                    patientData?.patient?.establish_complexity_last?.dueDateToCheckPatient?.split(
                      " "
                    )[0] ??
                    selectedComplexity?.dueDateToCheckPatient?.split(" ")[0]
                  }`
                ).format("MM-DD-YYYY")}
              </Text>
            </Flex>
          </Grid>
          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Nurse Comment: &nbsp;
              </Text>
              <Text variant="body2" color="grey">
                {elipses(
                  patientData?.patient?.establish_complexity_last
                    ?.nurseComments ?? selectedComplexity?.nurseComments,
                  20
                )}
                {patientData?.patient?.establish_complexity_last?.nurseComments
                  ?.length > 20 ??
                selectedComplexity?.nurseComments?.length > 20 ? (
                  <Box
                    sx={{ color: "#1C4588", cursor: "pointer" }}
                    onClick={() => {
                      setCommentModal(true);
                    }}
                  >
                    See more
                  </Box>
                ) : (
                  ""
                )}
              </Text>
            </Flex>
          </Grid>
          <CustomModal
            setOpen={setCommentModal}
            open={commentModal}
            title="Nurse Comment"
            width="auto"
            maxWidth="tablet"
          >
            <NurseComment
              selectedComplexity={selectedComplexity}
              patientData={patientData}
            />
          </CustomModal>
        </Grid>
      ) : (
        ""
      )}

      <CustomModal
        setOpen={setOpenFile}
        open={openFile}
        title="Preview Files"
        width="auto"
        maxWidth="laptop"
        fullWidth={false}
      >
        <div>
          <Text variant="h3">Preview Uploaded Files</Text>
          {selectedComplexity?.discharge_patient?.attachments?.length
            ? selectedComplexity?.discharge_patient?.attachments?.map(
                ({ name, url }, index) => {
                  return (
                    <div
                      className="preview_file"
                      style={{ cursor: "pointer" }}
                      onClick={() => openPdf(url)}
                    >
                      {index + 1}- {name}
                    </div>
                  );
                }
              )
            : ""}
          {!selectedComplexity?.discharge_patient?.attachments?.length ? (
            <Text variant="body2">No Files</Text>
          ) : (
            ""
          )}
        </div>
      </CustomModal>
      <Grid container my={2} className="initialContactAccordion">
        {Boolean(selectedComplexity?.initial_contacts?.length) && (
          <Grid item mobile={12} className="contactHistory">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Text variant="h3">Contact History</Text>
              </AccordionSummary>
              <AccordionDetails>
                {selectedComplexity?.initial_contacts?.map(
                  ({
                    reminder_date_time,
                    created_at,
                    contactMadeBy,
                    contactMadeTo,
                    callOutCome,
                    additionalNotes,
                    consentRecorded,
                  }) => {
                    let dateTime = reminder_date_time?.split(" ");
                    let formatDate = dateTime?.[0]
                      ? moment(dateTime?.[0]).format("MM-DD-YYYY")
                      : "";
                    return (
                      <>
                        <Grid
                          style={{
                            border: "1px solid rgba(145,144,144,0.3)",
                            borderRadius: "10px",
                            padding: "0px 12px",
                            marginTop: "10px",
                          }}
                          container
                        >
                          <Grid item mobile={12} tablet={6} laptop={4}>
                            <Flex
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Text variant="h4" color="blue">
                                Contact Made To: &nbsp;
                              </Text>
                              <Text variant="body2" color="grey">
                                {contactMadeTo}
                              </Text>
                            </Flex>
                          </Grid>
                          <Grid item mobile={12} tablet={6} laptop={4}>
                            <Flex
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Text variant="h3" color="blue">
                                Call Reciever Name: &nbsp;
                              </Text>
                              <Text variant="body2" color="grey">
                                {contactMadeBy}
                              </Text>
                            </Flex>
                          </Grid>
                          <Grid item mobile={12} tablet={6} laptop={4}>
                            <Flex
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Text variant="h4" color="blue">
                                Consent Recorded: &nbsp;
                              </Text>
                              <Text variant="body2" color="grey">
                                {consentRecorded}
                              </Text>
                            </Flex>
                          </Grid>
                          <Grid item mobile={12} tablet={6} laptop={4}>
                            <Flex
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Text variant="h4" color="blue">
                                Call Outcome: &nbsp;
                              </Text>
                              <Text variant="body2" color="grey">
                                {callOutCome}
                              </Text>
                            </Flex>
                          </Grid>

                          <Grid item mobile={12} tablet={4} laptop={4}>
                            <Flex
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Text variant="h4" color="blue">
                                Date: &nbsp;
                              </Text>
                              <Text variant="body2" color="grey">
                                {formatDate}
                              </Text>
                            </Flex>
                          </Grid>
                          <Grid item mobile={12} tablet={6} laptop={4}>
                            <Flex
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Text variant="h4" color="blue">
                                Time: &nbsp;
                              </Text>
                              <Text variant="body2" color="grey">
                                {dateTime?.[1]}
                              </Text>
                            </Flex>
                          </Grid>
                          <Grid item mobile={12} tablet={12} laptop={12}>
                            <Flex
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Text variant="h4" color="blue">
                                Additional Notes: &nbsp;
                              </Text>
                              <Text variant="body2" color="grey">
                                {additionalNotes}
                              </Text>
                            </Flex>
                          </Grid>
                        </Grid>
                      </>
                    );
                  }
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </Grid>
      <Grid
        style={{
          border: pateintDetailSummary ? "" : "1px solid rgba(145,144,144,0.3)",
          borderRadius: pateintDetailSummary ? "" : "10px",
          padding: "10px",
          marginTop: pateintDetailSummary ? "-90px" : "10px",
        }}
        container
        spacing={2}
      >
        {!pateintDetailSummary ? (
          <Flex justifyContent="flex-start" paddingLeft="16px">
            <Text variant="h3" color="gray">
              Add Initial Contact
            </Text>
          </Flex>
        ) : (
          ""
        )}
        <CustomRadioGroup
          label={"Call Recieved By"}
          disabled={formik.values.callOutCome === "no-response" ? true : false}
          name="contactMadeTo"
          formik={formik}
          defaultValue={
            dischargeData?.id
              ? dischargeData?.successfull_initial_contacts?.[0]?.contactMadeTo
              : ""
          }
          required
          children={["Patient", "Caregiver", "Other"]}
        />

        <CustomRadioGroup
          disabled={formik.values.callOutCome === "no-response" ? true : false}
          label={"Consent Recorded"}
          name="consentRecorded"
          formik={formik}
          defaultValue={
            dischargeData?.id
              ? dischargeData?.successfull_initial_contacts?.[0]?.consentRecorded
                  ?.charAt(0)
                  ?.toUpperCase() +
                dischargeData?.successfull_initial_contacts?.[0]?.consentRecorded?.slice(
                  1
                )
              : ""
          }
          required
          children={["Yes", "No"]}
        />
        {formik.values.contactMadeTo == "Other" && (
          <InputField
            name="relationWithPatient"
            formik={formik}
            label={"Relation With Patient"}
          />
        )}
        <InputField
          name="contactMadeBy"
          formik={formik}
          label={"Call Reciever Name"}
          required
          disabled={
            formik.values.contactMadeTo == "Patient" ||
            formik.values.callOutCome === "no-response"
              ? true
              : false
          }
        />
        <SelectField
          options={
            dischargeData?.id && formik.values.callOutCome === "pending"
              ? options.filter((outcome) => outcome?.label !== "Success")
              : options
          }
          defaultValue={
            dischargeData?.id
              ? options?.find(
                  ({ value }) =>
                    value ==
                    dischargeData?.successfull_initial_contacts?.[0]
                      ?.callOutCome
                )
              : ""
          }
          name="callOutCome"
          formik={formik}
          label={"Call Status"}
          required
        />
        {formik.values.callOutCome == "pending" && (
          <SelectField
            options={assignToData?.assignTo?.map(
              ({ id, firstName, lastName }) => {
                return { label: `${firstName} ${lastName}`, value: id };
              }
            )}
            defaultValue={
              dischargeData?.id &&
              dischargeData?.successfull_initial_contacts?.[0]?.assign_to !=
                null
                ? {
                    label: `${dischargeData?.successfull_initial_contacts?.[0]?.assign_to?.firstName} ${dischargeData?.successfull_initial_contacts?.[0]?.assign_to?.firstName}`,
                    value:
                      dischargeData?.successfull_initial_contacts?.[0]
                        ?.assign_to?.id,
                  }
                : //for checking whether that assign to is coming or not
                selectedComplexity?.assign_to?.id
                ? {
                    label: `${selectedComplexity?.assign_to?.firstName} ${selectedComplexity?.assign_to?.lastName}`,
                    value: selectedComplexity?.assign_to?.id,
                  }
                : ""
            }
            name="assignTo"
            formik={formik}
            label={"Assign To"}
          />
        )}
        {formik.values.callOutCome === "pending" && (
          <>
            <Grid container marginLeft={2}>
              <Text variant="body1" mb={0}>
                Set Reminder
              </Text>
            </Grid>

            <InputField
              name="reminderdate"
              type={"date"}
              formik={formik}
              label={"Date"}
              min={new Date().toISOString().split("T")[0]}
            />
            <InputField
              name="remindertime"
              type={"time"}
              formik={formik}
              label={"Time"}
            />
          </>
        )}
        {formik.values.callOutCome === "no-response" ? (
          " "
        ) : (
          <Grid item mobile={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Text variant="h3">Clinical Risk Assessment</Text>
              </AccordionSummary>
              <AccordionDetails>
                <QuestionAireComponent
                  dischargeData={dischargeData}
                  selectedData={selectedComplexity}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}

        {formik.values.callOutCome === "success" && (
          <Grid item mobile={12} my={1} className="addAppointmentAccordion">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Text variant="h3">Appointment Details</Text>
              </AccordionSummary>
              <AccordionDetails>
                <AppointmentForm
                  setInitialContactId={setInitialContactId}
                  initialID={initialContactId}
                  setValidateCount={setValidateCount}
                  validateCount={validateCount}
                  setIsInitialSucc={setIsInitialSucc}
                  isInitialSucc={isInitialSucc}
                  isAppointmentFormSubmit={isAppointmentFormSubmit}
                  setIsAppointmentFormSubmit={setIsAppointmentFormSubmit}
                  selectedAppointment={selectedComplexity}
                  isIncluded={true}
                  setOpen={setOpen}
                  dischargeData={dischargeData}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}

        <InputField
          name="additionalNotes"
          formik={formik}
          label={"Addition Notes"}
          multiline
          rows={4}
          cols={12}
          required
        />

        <Flex>
          <FormButtons
            saveID={saveID}
            cancelID={cancelID}
            isCancel={pateintDetailSummary ? false : true}
            saveLoading={isLoading || isUpdateInitialLoading}
            text={"Save"}
            onClickSave={formik.submitForm}
            onClickCancel={() => {
              handleCancel();
            }}
          />
        </Flex>
      </Grid>
    </>
  );
};

export default InitialContactForm;
