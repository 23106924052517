import { Box, Card, CardContent, CardMedia, Grid } from "@mui/material";
import { chooseModuleData } from "assets/static/dashboardStatic";
import Button from "jsx/Common/Button/Button";
import React from "react";
import BgImage from "../../../../../assets/images/Ccmimages/modulebg.png";
import circleimage from "../../../../../assets/images/Ccmimages/circleimage.png";
import dischargeLogo from "../../../../../assets/images/Ccmimages/dischargeLogo.png";
import { makeStyles } from "@mui/styles";
import Text from "jsx/Common/Typography/Text";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#1C4588",
    minHeight: "60vh",
    padding: "20px",
    color: "#fff",
    position: "relative",
  },
  overlay: {
    background: "rgba(255, 255, 255, 0.2)",
    borderRadius: "16px",
    boxShadow: " 0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(5px)",
    WebkitBackdropFilter: "blur(5px)",
    border: "1px solid rgba(255, 255, 255, 0.3)",
    minHeight: "420px",
    width: "85%",
    padding: "20px",
  },
  cardClass: {
    padding: "10px 50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
const SelectModule = () => {
  const classes = useStyles();
  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item desktop={10} tablet={12} laptop={11} mobile={12}>
            <Grid
              container
              sx={{ height: "100%", boxShadow: "0px 8px 12px #00000029" }}
            >
              <Grid
                item
                desktop={6}
                tablet={6}
                mobile={12}
                className={classes.root}
                sx={{
                  display: {
                    mobile: "none",
                    tablet: "flex",
                    laptop: "flex",
                    desktop: "flex",
                  },
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "300px",
                    position: "absolute",
                    left: "0",
                    bottom: -2,
                    zIndex: "1",
                  }}
                >
                  <img src={circleimage} style={{ width: "100%" }} alt="" />
                </Box>
                <Box className={classes.overlay}>
                  <Box>
                    <img
                      src={dischargeLogo}
                      style={{ width: "150px" }}
                      alt="dischargeLogoImage"
                    />
                  </Box>
                  <Box
                    sx={{
                      mt: "40px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={BgImage}
                      style={{
                        width: "100%",
                        maxHeight: "290px",
                        objectFit: "contain",
                      }}
                      alt="circleImage"
                    />
                  </Box>
                  <Box sx={{ textAlign: "center", mt: "40px" }}>
                    <Text
                      variant="h3"
                      marginBottom="5px"
                      fontSize="17px"
                      color=" #fff"
                    >
                      Customizable Multipurpose Dashboard
                    </Text>
                    <Text
                      fontSize="11px"
                      marginTop="0px"
                      variant="body2"
                      color=" #fff"
                    >
                      Everything you need is an easily customizable dashboard
                    </Text>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                desktop={6}
                tablet={6}
                className={classes.cardClass}
                mobile={12}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <Text fontSize="22px" marginTop="20px" marginBottom="10px">
                    Click on 1 Card & Go To Next Module
                  </Text>
                  {chooseModuleData?.map(({ text, icon, bgImage }, ind) => {
                    return (
                      <Card
                        key={ind}
                        sx={{ my: 1.5, minWidth: "70%", height: "210px" }}
                      >
                        <CardMedia
                          sx={{
                            width: "100%",
                            height: "120px",
                            objectFit: "contain",
                            padding: "6px",
                            backgroundColor: "#1C4588 !important",
                          }}
                          component="img"
                          image={bgImage}
                          alt="Paella dish"
                        />
                        <CardContent sx={{ position: "relative" }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "45px",
                              height: "45px",
                              background: "#fff",
                              borderRadius: "50%",
                              position: "absolute",
                              top: "0",
                              left: "50%",
                              transform: "translate(-50%,-50%)",
                              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                            }}
                          >
                            <img src={icon} style={{ width: "23px" }} alt="" />
                          </Box>
                          <Box sx={{ textAlign: "center", mt: 1, px: 2 }}>
                            <Button text={text} fontSize="14px" px={2} />
                          </Box>
                        </CardContent>
                      </Card>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SelectModule;
