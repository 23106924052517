import { Box, Grid } from "@mui/material";
import Button from "jsx/Common/Button/Button";
import CustomModal from "jsx/Common/Modal/CustomModal";
import GenericTable from "jsx/Common/Table/GenericTable";
import { memo, useState } from "react";
import InsuranceForm from "../../../Components/InsuranceData/InsuranceForm";
import SelectField from "jsx/Common/FormComponents/SelectField";

const Insurance = () => {
  const [open, setOpen] = useState(false);
  const columns = [
    { Header: "Insurance", accessor: "insurance" },
    { Header: "Type", accessor: "type" },
    { Header: "Policy #", accessor: "policy" },
    { Header: "Options", accessor: "options" },
  ];
  const TableData = [
    {
      insurance: "Medicare",
      type: "Primary",
      policy: "0184527",
      options: "Update/Delete",
    },
    {
      insurance: "Med Care",
      type: "Secondary",
      policy: "52398438",
      options: "Update/Delete",
    },
    {
      insurance: "Chronic Care",
      type: "Teritary",
      policy: "43779698",
      options: "Update/Delete",
    },
  ];
  return (
    <>
      <Box sx={{ position: "relative", top: "60px" }}>
        <CustomModal
          maxWidth="tablet"
          title={"Add Patient Insurance"}
          setOpen={setOpen}
          open={open}
        >
          <InsuranceForm />
        </CustomModal>
        <Box sx={{ position: "absolute", right: "0px", top: "-75px" }}>
          <Button
            text={"Add Insurance"}
            onClick={() => {
              setOpen(true);
            }}
          />
        </Box>
        <Grid container columnSpacing={1}>
          <SelectField label="Insurance" cols={3.4} />
          <SelectField label="Type" cols={3.3} />
          <SelectField label="Policy #" cols={3.3} />
          <Grid
            item
            mobile={6}
            tablet={3}
            laptop={2}
            sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}
          >
            <Button text={"Add"} />
          </Grid>
        </Grid>
        <GenericTable columns={columns} data={TableData} />
      </Box>
    </>
  );
};

export default memo(Insurance);
