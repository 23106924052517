import { Box, Grid } from '@mui/material'
import Text from 'jsx/Common/Typography/Text'
import React from 'react'
import { useEffect } from 'react';
import logoImg from "../../../../../assets/images/logo.png"
import TopBar from './TopBar';
const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
    }, []);
    return (
        <>
            <TopBar />
            <Grid container sx={{ my: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Grid item laptop={11} desktop={9} tablet={12} mobile={12}>
                    <Box sx={{
                        padding: '30px 40px', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
                    }}>

                        <Text variant='h1' textAlign="center" marginBottom="40px">
                            Privacy Policy for Discharge Health
                        </Text>
                        <Text variant='body2'>
                            Effective Date: July 01, 2022
                        </Text>
                        <Text variant='body2'>
                            Discharge Health, Inc a DE corporation ("we," "us," or "our") is fully committed to protecting
                            your privacy and complying with the Texas Data Privacy and Security Act ("TDPSA"), the
                            Health Insurance Portability and Accountability Act ("HIPAA"), and other relevant data
                            protection laws. This Privacy Policy outlines how we collect, use, and disclose your
                            information when you access or use our website located at www.dischargehealth.com and
                            applications we use (the "Website") and our transitional care management services
                            (collectively, the "Services"). By using our Services, you consent to the practices described
                            in this Privacy Policy
                        </Text>
                        <Text variant='body2'>
                            1. Information We Collect
                        </Text>
                        <Text variant='body2'>
                            1.1. Personal Information: We may collect personal information, such as your name, contact
                            information, date of birth, and other details you provide when using our Services or
                            communicating with us
                        </Text>
                        <Text variant='body2'>
                            1.2. Health Information (PHI): To provide our Services in compliance with HIPAA and
                            TDPSA, we may collect and safeguard protected health information (PHI), including but not
                            limited to medical history, treatment plans, and other health-related data.
                        </Text>
                        <Text variant='body2'>
                            1.3. Usage Information: We gather information about your use of the Website and Services,
                            including your IP address, device information, browser type, and other usage data, to
                            improve our Services and ensure compliance with applicable laws.                    </Text>
                        <Text variant='body2'>
                            2. How We Use Your Information
                        </Text>
                        <Text variant='body2'>
                            2.1. Service Delivery: We use your information to provide, maintain, and enhance our
                            Services, including the coordination of transitional care management, while adhering to
                            HIPAA and TDPSA requirements.
                        </Text>
                        <Text variant='body2'>
                            2.2. Communication: We may use your information to communicate with you, respond to
                            your inquiries, and deliver essential notices and updates, all in accordance with HIPAA and
                            TDPSA regulations.
                        </Text>
                        <Text variant='body2'>
                            2.3. Research and Analysis: In compliance with applicable laws, we may employ deidentified and aggregated data for research and analysis to enhance the quality of care and
                            the effectiveness of our Services.
                        </Text>
                        <Text variant='body2'>
                            3. Disclosure of Your Information
                        </Text>
                        <Text variant='body2'>
                            3.1. Consent: We will share your information only with your explicit consent, such as when
                            you authorise us to communicate with your healthcare providers
                        </Text>
                        <Text variant='body2'>
                            3.2. Service Providers: To assist us in delivering our Services, we may share your
                            information with trusted third-party service providers who are bound by contractual obligations to safeguard your data and use it solely for the purposes outlined by us,
                            consistent with HIPAA, and TDPSA requirements.
                        </Text>
                        <Text variant='body2'>
                            3.3. Legal Compliance: In adherence to applicable laws, including HIPAA and TDPSA, we
                            may disclose your information when required by legal processes, regulations, or government
                            requests.
                        </Text>
                        <Text variant='body2'>
                            4. Security
                        </Text>
                        <Text variant='body2'>
                            We employ reasonable security measures to protect your information from unauthorised
                            access, disclosure, alteration, or destruction, as mandated by HIPAA, TDPSA, and other
                            relevant laws. Nonetheless, it's important to note that no method of data transmission or
                            storage is completely immune to security risks
                        </Text>
                        <Text variant='body2'>
                            5. Your Rights
                        </Text>
                        <Text variant='body2'>
                            You have certain rights over your information as granted by HIPAA, TDPSA, and other
                            relevant laws but not limited to these laws based on the law in your territory:
                        </Text>
                        <Text variant='body2'>
                            5.1. Access: You can request access to the personal and health information we hold about
                            you, consistent with HIPAA and TDPSA.
                        </Text>
                        <Text variant='body2'>
                            5.2. Correction: You can request corrections to inaccuracies in your information in
                            accordance with HIPAA and TDPSA.
                        </Text>
                        <Text variant='body2'>
                            5.3. Deletion: Subject to legal requirements under HIPAA, TDPSA, and other applicable
                            laws, you can request the deletion of your information.
                        </Text>
                        <Text variant='body2'>
                            5.4. Objection: You can object to the processing of your information for certain purposes, as
                            allowed by HIPAA, TDPSA, and other relevant regulations.
                        </Text>
                        <Text variant='body2'>
                            5.5. Withdraw Consent: You can withdraw your consent where we rely on consent as the
                            legal basis for processing, in accordance with applicable laws.
                        </Text>
                        <Text variant='body2'>
                            5.5. Withdraw Consent: You can withdraw your consent where we rely on consent as the
                            legal basis for processing, in accordance with applicable laws.
                        </Text>
                        <Text variant='body2'>
                            6. Changes to this Privacy Policy
                        </Text>
                        <Text variant='body2'>
                            We may update this Privacy Policy to reflect changes in our practices or for other
                            operational, legal, or regulatory reasons, including updates to comply with HIPAA, TDPSA,
                            and other relevant laws. Any changes will be effective upon posting on the Website, and we
                            encourage you to review this Privacy Policy periodically.
                        </Text>
                        <Text variant='body2'>
                            7. Contact Us
                        </Text>
                        <Text variant='body2'>
                            For questions or concerns regarding this Privacy Policy or our data practices, please contact
                            us at info@dischargehealth.com.
                        </Text>
                        <Text variant='body2'>
                            By utilising Discharge Health's Services, you acknowledge that you have read,
                            comprehended, and consent to this Privacy Policy. This Privacy Policy constitutes a legally binding agreement between you and Discharge Health, ensuring compliance with the Data
                            Privacy and Security Act and HIPAA
                        </Text>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default PrivacyPolicy