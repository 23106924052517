import * as yup from "yup";

const DemographicsSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup.string().email("Invalid email address"), //validation is implemented if user enter email not otherwise
  birthDate: yup.string().required("DOB is required"),
  gender: yup.string().required("Gender is required"),
  mobilePhone: yup.string().required("Phone is required"),
  address1: yup.string().required("Address  is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  zipcode: yup
    .string()
    .required("Zipcode is required")
    .test("only-digits", "Only digits are allowed", (value) =>
      /^\d+$/.test(value)
    )
    .test(
      "exact-length",
      "Zipcode must be exactly 5 digits",
      (value) => value && value?.length === 5
    ),
});

export { DemographicsSchema };
