import * as yup from "yup";

export const insuranceCompanySchema = yup.object().shape({
  insuranceName: yup.string().required("Insurance Name is required"),
  addressOne: yup.string().required("Address is required"),
  addressTwo: yup.string(),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  zip: yup.string().required("Zipcode is required"),
  electronicPlayerId: yup.string(),
  phone: yup.string(),
  fax: yup.string(),
  insurance_type_id: yup.number().required('Insurance Type is required'),
  insuranceClassification: yup.string(),
});
