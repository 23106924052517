import * as yup from "yup";

export const ChooseCompanySchema = yup.object().shape({
  companyId: yup.string().required("Select practice"),
  applyValidation: yup.boolean(),
  selectTcmCcm: yup.string().test({
    name: "tcm-ccm-required",
    test: function (value) {
      // Access the `applyValidation` field from the context
      const applyValidation = this.parent.applyValidation;

      // Check if selectTcmCcm should be validated
      if (applyValidation && !value) {
        throw this.createError({
          path: "selectTcmCcm",
          message: "Select company",
        });
      }

      return true;
    },
  }),
});
