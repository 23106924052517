import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import request from "api/request";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import CustomRadioGroup from "jsx/Common/FormComponents/CustomRadioGroup";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import InputField from "jsx/Common/FormComponents/InputField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import PatientDetail from "jsx/Common/PatientDetail/PatientDetail";
import Text from "jsx/Common/Typography/Text";
import moment from "moment";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { FeedBackSchema } from "validations/DischargePatient/feedbackSchema";

const FeedbackCallForm = ({
  validateCount = 0,
  setValidateCount,
  setOpen,
  setSelectedFeedback,
  selectedFeedback,
  pateintDetailSummary = false,
  dischargeData = {},
  clinicalExamination,
}) => {
  const { id } = useParams();
  const { useAppMutation, useFetch } = useReactQuery();
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const handleCancel = () => {
    setOpen && setOpen(false);
  };
  const { data: patientData, isLoading: patientDataLoading } = useFetch(
    `/patient/${id}`,
    () =>
      request({
        method: "get",
        url: `/patient/${id ?? selectedFeedback?.patient?.id}`,
      }),
    {
      enabled: !selectedFeedback?.id,
    }
  );
  const { mutate: updateFeedbackMutate, isLoading: isUpdateFeedbackLoading } =
    useAppMutation(
      (data) =>
        request({
          method: "put",
          url: `/feedBackCalls/${dischargeData?.feedback_calls?.[0]?.id}`,
          data,
        }),
      {
        onSuccess: (data) => {
          showToast("Feedback Edited Successfully", {
            type: "success",
          });
          setValidateCount && setValidateCount(validateCount + 1);
        },
      }
    );
  const { mutate, isLoading } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: "/feedBackCalls",
        data,
      }),
    {
      onSuccess: (data) => {
        showToast(
          data?.alertMessage === ""
            ? "Feed Back Call Record Added"
            : data?.alertMessage ??
                "Maximum attempts Reached Record Cannot be Added ",
          {
            type: data?.alertMessage ? "error" : "success",
          }
        );

        queryClient.invalidateQueries([`/patient/${id}`]);
        queryClient.invalidateQueries(["feedBackCalls"]);
        queryClient.invalidateQueries([`feedBackCalls/${id}`]);
        queryClient.invalidateQueries(["clinicalExaminations"]);
        queryClient.invalidateQueries(["counts"]);
        formik.resetForm();
        setSelectedFeedback && setSelectedFeedback({});
        handleCancel();
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      clinical_id: dischargeData?.id
        ? dischargeData?.feedback_calls?.[0]?.clinical_examination?.id
        : selectedFeedback?.id ?? patientData?.patient?.clinical_last?.id,
      patient_id: dischargeData?.id
        ? dischargeData?.patient?.id
        : selectedFeedback?.patient?.id ?? parseInt(id),
      discharge_id: dischargeData?.id
        ? dischargeData?.id
        : selectedFeedback?.discharge_id ??
          patientData?.patient?.discharge_patient_last?.id,

      contactMadeBy: dischargeData?.id
        ? dischargeData?.feedback_calls?.[0]?.contactMadeBy
        : "",
      contactMadeTo: dischargeData?.id
        ? dischargeData?.feedback_calls?.[0]?.contactMadeTo
        : "",
      feedback: dischargeData?.id
        ? dischargeData?.feedback_calls?.[0]?.feedback
        : "",
      caregiverName: "",
      additionalSuggesstions: dischargeData?.id
        ? dischargeData?.feedback_calls?.[0]?.additionalSuggesstions
        : "",
    },
    validationSchema: FeedBackSchema,

    onSubmit: async (values) => {
      dischargeData?.id ? updateFeedbackMutate(values) : mutate(values);
    },
  });
  const { data: assignToData, isLoading: isAssignData } = useFetch(
    "assigntoBiller",
    () =>
      request({
        method: "post",
        data: { name: "biller" },
        url: "/assignto",
      })
  );
  return (
    <>
      {(selectedFeedback || patientData?.patient) && !pateintDetailSummary ? (
        <PatientDetail
          selectedRecord={selectedFeedback}
          patientRecord={patientData}
        />
      ) : (
        ""
      )}
      {/* Feedback History */}
      {Array.isArray(selectedFeedback?.feedbackcalls) &&
        selectedFeedback?.feedbackcalls.length > 0 && (
          <Grid container my={2} className="initialContactAccordion">
            <Grid item mobile={12} className="Feedbackhistory">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Text variant="h3">FeedBack History</Text>
                </AccordionSummary>
                <AccordionDetails>
                  {selectedFeedback?.feedbackcalls.map(
                    ({
                      contactMadeBy,
                      contactMadeTo,
                      feedback,
                      additionalSuggesstions,
                      created_at,
                    }) => {
                      let dateTime = created_at?.split(" ");
                      let formatDate = dateTime?.[0]
                        ? moment(dateTime?.[0]).format("MM-DD-YYYY")
                        : "";
                      return (
                        <Grid
                          key={contactMadeTo}
                          style={{
                            border: "1px solid rgba(145,144,144,0.3)",
                            borderRadius: "10px",
                            padding: "0px 12px",
                            marginTop: "10px",
                          }}
                          container
                        >
                          <Grid item mobile={12} tablet={6} laptop={4}>
                            <Flex
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Text variant="h4" color="blue">
                                Call Recieved By: &nbsp;
                              </Text>
                              <Text variant="body2" color="grey">
                                {contactMadeTo ?? ""}
                              </Text>
                            </Flex>
                          </Grid>
                          <Grid item mobile={12} tablet={6} laptop={4}>
                            <Flex
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Text variant="h3" color="blue">
                                Call Reciever Name: &nbsp;
                              </Text>
                              <Text variant="body2" color="grey">
                                {contactMadeBy ?? ""}
                              </Text>
                            </Flex>
                          </Grid>
                          <Grid item mobile={12} tablet={6} laptop={4}>
                            <Flex
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Text variant="h4" color="blue">
                                Feedback Status: &nbsp;
                              </Text>
                              <Text variant="body2" color="grey">
                                {feedback}
                              </Text>
                            </Flex>
                          </Grid>
                          <Grid item mobile={12} tablet={6} laptop={4}>
                            <Flex
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Text variant="h4" color="blue">
                                Additional Suggestions: &nbsp;
                              </Text>
                              <Text variant="body2" color="grey">
                                {additionalSuggesstions ?? ""}
                              </Text>
                            </Flex>
                          </Grid>
                          <Grid item mobile={12} tablet={4} laptop={4}>
                            <Flex
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Text variant="h4" color="blue">
                                Date: &nbsp;
                              </Text>
                              <Text variant="body2" color="grey">
                                {formatDate ?? " "}
                              </Text>
                            </Flex>
                          </Grid>
                          <Grid item mobile={12} tablet={4} laptop={4}>
                            <Flex
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Text variant="h4" color="blue">
                                Time: &nbsp;
                              </Text>
                              <Text variant="body2" color="grey">
                                {dateTime?.[1] ?? ""}
                              </Text>
                            </Flex>
                          </Grid>
                        </Grid>
                      );
                    }
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        )}
      {/* Feedback History */}
      <Grid
        style={{
          border: pateintDetailSummary
            ? "none"
            : "1px solid rgba(145,144,144,0.3)",
          borderRadius: pateintDetailSummary ? "" : "10px",
          padding: "10px 12px",
          marginTop: pateintDetailSummary ? "-40px" : "10px",
        }}
        container
        columnSpacing={2}
      >
        {!pateintDetailSummary ? (
          <Flex justifyContent="flex-start">
            <Text variant="h3" color="gray">
              Add Feedback
            </Text>
          </Flex>
        ) : (
          ""
        )}

        <CustomRadioGroup
          label={"Call Recieved By"}
          name="contactMadeTo"
          formik={formik}
          required
          defaultValue={
            dischargeData?.id
              ? dischargeData?.feedback_calls?.[0]?.contactMadeTo
                  ?.charAt(0)
                  .toUpperCase() +
                dischargeData?.feedback_calls?.[0]?.contactMadeTo?.slice(1)
              : ""
          }
          children={["Caregiver", "Patient", "Other"]}
        />
        <InputField
          formik={formik}
          name={"contactMadeBy"}
          label={"Call Reciever Name"}
          required
        />
        <SelectField
          name={"feedback"}
          defaultValue={
            dischargeData?.id
              ? {
                  label: dischargeData?.feedback_calls?.[0]?.feedback,
                  value: dischargeData?.feedback_calls?.[0]?.feedback,
                }
              : ""
          }
          options={[
            { label: "Success", value: "success" },
            { label: "Pending", value: "pending" },
            { label: "Rejected", value: "rejected" },
          ]}
          formik={formik}
          label={"Feedback"}
          required
        />
        <InputField
          name={"additionalSuggesstions"}
          formik={formik}
          label={"Additional Suggestions"}
          multiline
          rows={4}
          cols={12}
          required
        />
        <Flex>
          <FormButtons
            saveID="saveFeedback"
            cancelID="cancelFeedback"
            isCancel={pateintDetailSummary ? false : true}
            onClickSave={formik.submitForm}
            saveLoading={isLoading || isUpdateFeedbackLoading}
            onClickCancel={() => {
              handleCancel();
            }}
          />
        </Flex>
      </Grid>
    </>
  );
};

export default FeedbackCallForm;
