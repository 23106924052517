import { Grid } from "@mui/material";
import request from "api/request";
import { careGiverOptionsData, genders } from "assets/static/options";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import Button from "jsx/Common/Button/Button";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import InputField from "jsx/Common/FormComponents/InputField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { newPatientSchema } from "validations/NewPatient/newPatientSchema";

const NewPatientForm = ({
  newPatientIndex = false,
  setOpen,
  dischageSummaryDetail = false,
  patientData = {},
  setValidateCount,
  validateCount,
}) => {
  // Add a state variable for the key
  const [componentKey, setComponentKey] = useState(0);
  const { useAppMutation } = useReactQuery();
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [phoneFocused, setPhoneFocused] = useState(false);
  const [secondaryPhoneFocused, setSecondaryPhoneFocused] = useState(false);
  const [phone, setPhone] = useState(patientData?.patient?.mobilePhone ?? "");
  const [secondaryPhone, setSecondaryPhone] = useState(
    patientData?.patient?.otherPhone ?? ""
  );

  const [contact, setContact] = useState(
    patientData?.patient?.caregiver_contact ?? ""
  );
  const { useFetch } = useReactQuery();
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const [isDischarge, setIsDischarge] = useState(false);
  const [calculatedAge, setCalculateAge] = useState(null);
  const currentDate = new Date().toISOString().split("T")[0]; // Get the current date in YYYY-MM-DD

  function calculateAge(dateOfBirth) {
    const today = new Date();
    const dob = new Date(dateOfBirth);
    let ageYears = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();
    let ageMonths = monthDiff;
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      ageYears--;
      ageMonths = 12 - Math.abs(monthDiff);
    }

    return { years: ageYears, months: ageMonths };
  }
  const { mutate, isLoading } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: `/patients`,
        data,
      }),
    {
      onSuccess: (data) => {
        showToast("Patient Added Successfully", {
          type: "success",
        });
        queryClient.invalidateQueries(["patients"]);
        queryClient.invalidateQueries(["counts"]);
        formik.resetForm();
        setPhone("");
        setSecondaryPhone("");
        // Increment the key to force re-render
        setComponentKey((prevKey) => prevKey + 1);
        setPhoneFocused(false);

        if (isDischarge) {
          setIsDischarge(false);
          navigate(`/dischargePatient/${data?.patient?.id}?openDischarge=true`);
        }
        setOpen && setOpen(false);
      },
    }
  );
  const { mutate: editPatientDetail, isLoading: isEditPatientLoading } =
    useAppMutation(
      (data) =>
        request({
          method: "put",
          url: `/patients/${patientData?.patient?.id}`,
          data,
        }),
      {
        onSuccess: (data) => {
          showToast(
            data?.alertMessage
              ? data?.alertMessage
              : "Patient Edit Successfully",
            {
              type: data?.alertMessage ? "error" : "success",
            }
          );
          setValidateCount(validateCount + 1);
          queryClient.invalidateQueries(`dischargesummeries/${id}`);

          if (isDischarge) {
            setIsDischarge(false);
            navigate(
              `/dischargePatient/${patientData?.patient?.id}?openDischarge=true`
            );
          }
          setOpen && setOpen(false);
        },
      }
    );

  const { data: stateData, isLoadingState } = useFetch("states", () =>
    request({
      method: "get",
      url: "/states",
    })
  );

  const formik = useFormik({
    initialValues: {
      firstName: patientData?.patient?.firstName ?? "",
      lastName: patientData?.patient?.lastName ?? "",
      email: patientData?.patient?.email ?? "",
      birthDate: patientData?.patient?.birthDate ?? "",
      age: patientData?.patient?.age ?? calculatedAge?.years,
      gender: patientData?.patient?.gender ?? "",
      mobilePhone: patientData?.patient?.mobilePhone ?? "",
      otherPhone: patientData?.patient?.otherPhone ?? "",
      Address1: patientData?.patient?.Address1 ?? "",
      Address2: patientData?.patient?.Address2 ?? "",
      city: patientData?.patient?.city ?? "",
      zipcode: patientData?.patient?.zipcode ?? "",
      state: patientData?.patient?.state ?? "",
      initialName: patientData?.patient?.initialName ?? "",
      Surfax: patientData?.patient?.Surfax ?? "",
      prefix: patientData?.patient?.prefix ?? "",
      caregiverRelationship:
        patientData?.patient?.caregiver_relationship ??
        careGiverOptionsData[0].value,
      caregiverName: patientData?.patient?.caregiver_name ?? "",
      caregiverContact: patientData?.patient?.caregiver_contact ?? "",
      hospital_id: "1",
    },
    validationSchema: newPatientSchema,

    onSubmit: async (values) => {
      patientData?.patient?.id
        ? editPatientDetail({
            ...values,
            mobilePhone: phone,
            caregiverContact: contact,
            otherPhone: secondaryPhone,
            type: "tcm",
          })
        : mutate({
            ...values,
            mobilePhone: phone,
            caregiverContact: contact,
            otherPhone: secondaryPhone,
            type: "tcm",
          });
    },
  });

  const handleSave = () => {
    !contact && setInputFocused(true);
    !phone && setPhoneFocused(true);
    formik.submitForm();
  };

  useEffect(() => {
    if (
      formik.values.caregiverRelationship === "none" ||
      formik.values.caregiverRelationship === ""
    ) {
      formik.setFieldValue("caregiverName", "");
      formik.setFieldValue("caregiverContact", "");
      setContact("");
    }
  }, [formik.values.caregiverRelationship]);

  useEffect(() => {
    if (calculatedAge?.years) {
      formik.setFieldValue("age", calculatedAge.years);
    }
  }, [formik.values.birthDate]);

  useEffect(() => {
    setCalculateAge(calculateAge(formik.values.birthDate).years);
    formik.setFieldValue("age", calculateAge(formik.values.birthDate).years);
  }, [formik.values.birthDate]);

  useEffect(() => {
    formik.setFieldValue("mobilePhone", phone);
  }, [phone]);

  useEffect(() => {
    formik.setFieldValue("otherPhone", secondaryPhone);
  }, [secondaryPhone]);

  useEffect(() => {
    formik.setFieldValue("caregiverContact", contact);
  }, [contact]);

  useEffect(() => {
    return () => {
      setContact("");
      setPhone("");
      setPhoneFocused(false);
      setInputFocused(false);
    };
  }, []);

  const navigate = useNavigate();
  return (
    <Grid key={componentKey} container spacing={1} columnSpacing={2}>
      <InputField
        label={"First Name"}
        name="firstName"
        formik={formik}
        cols={5}
        required
      />
      <InputField
        label={"Middle initial"}
        cols={2}
        name="initialName"
        formik={formik}
      />
      <InputField
        label={"Last Name"}
        cols={5}
        name="lastName"
        formik={formik}
        required
      />
      <InputField label={"Prefix"} cols={2} name="prefix" formik={formik} />
      <InputField label={"Suffix"} cols={2} name="Surfax" formik={formik} />
      <InputField
        label={`Date Of Birth ${
          calculatedAge && calculatedAge.years && calculatedAge.months
            ? `( ${calculatedAge.years} years ${calculatedAge.months} months )`
            : ""
        }`}
        max={currentDate}
        type={"date"}
        cols={4}
        name="birthDate"
        formik={formik}
        required
      />
      <SelectField
        label={"Gender"}
        cols={4}
        name="gender"
        formik={formik}
        defaultValue={
          patientData?.patient?.id
            ? genders.find(({ value }) => value == patientData?.patient?.gender)
            : ""
        }
        required
        options={genders}
      />
      <InputField
        label={"Email Address"}
        name="email"
        formik={formik}
        cols={4}
      />
      <InputField
        errorMessage="Phone No is required"
        showError={!phone && phoneFocused ? true : false}
        label={"Phone #"}
        type={"text"}
        isPhone={true}
        value={phone}
        onBlur={(e) => setPhoneFocused(true)}
        onChange={(e) => setPhone(e.target.value)}
        name="mobilePhone"
        cols={4}
        required
      />

      <InputField
        label={"Secondary Phone #"}
        type={"text"}
        isPhone={true}
        value={secondaryPhone}
        onBlur={(e) => setSecondaryPhoneFocused(true)}
        onChange={(e) => setSecondaryPhone(e.target.value)}
        name="otherPhone"
        cols={4}
      />
      <InputField
        label={"Address 1"}
        name="Address1"
        formik={formik}
        required
      />
      <InputField label={"Address 2"} name="Address2" formik={formik} />
      <InputField
        label={"City"}
        cols={4}
        name="city"
        formik={formik}
        required
      />
      {stateData && (
        <SelectField
          label={"State"}
          cols={4}
          name="state"
          formik={formik}
          defaultValue={
            patientData?.patient?.id
              ? {
                  label: stateData?.States.find(
                    ({ name, state_id }) =>
                      state_id == patientData?.patient?.state
                  )?.name,
                  value: stateData?.States.find(
                    ({ name, state_id }) =>
                      state_id == patientData?.patient?.state
                  )?.state_id,
                }
              : ""
          }
          options={stateData?.States.map((state) => ({
            label: state.name,
            value: state.state_id,
          }))}
          required
        />
      )}
      <InputField
        label={"Zip Code"}
        cols={4}
        name="zipcode"
        formik={formik}
        required
      />
      <SelectField
        label="Caregiver Relationship"
        cols={4}
        options={careGiverOptionsData}
        name="caregiverRelationship"
        formik={formik}
        required
        defaultValue={
          patientData?.patient?.id
            ? careGiverOptionsData.find(
                ({ value }) => value == formik.values.caregiverRelationship
              )
            : careGiverOptionsData[0]
        }
      />

      {formik.values.caregiverRelationship == "none" ||
      formik.values.caregiverRelationship === "" ? (
        ""
      ) : (
        <>
          <InputField
            label="Caregiver Name"
            cols={4}
            name="caregiverName"
            formik={formik}
            required
            type={"text"}
          />
          <InputField
            errorMessage="Caregiver Contact is required"
            showError={!contact && inputFocused ? true : false}
            label="Caregiver Contact#"
            isPhone
            onBlur={(e) => setInputFocused(true)}
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            cols={4}
            required
          />
        </>
      )}
      <Flex>
        {newPatientIndex ? (
          <Button
            id="newPatient"
            isLoading={isLoading}
            text={"Save"}
            onClick={handleSave}
          />
        ) : (
          <FormButtons
            saveLoading={
              patientData?.patient?.id ? isEditPatientLoading : isLoading
            }
            cancel="Save & Go to Discharge"
            onClickSave={handleSave}
            cancelLoading={isLoading}
            isCancel={patientData?.patient?.id ? false : true}
            onClickCancel={() => {
              setIsDischarge(true);
              formik.submitForm();
            }}
            cancelID="saveAndGoToDischarge"
            saveID="saveNewPatient"
          />
        )}
      </Flex>
    </Grid>
  );
};
export default NewPatientForm;
