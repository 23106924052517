const pointOfServices = [
  { value: 1, label: "Office Visit" },
  { value: 2, label: "Home Visit" },
  { value: 3, label: "Telehealth" },
];
const activeInactive = [
  { label: "Active", value: "0" },
  { label: "Inactive", value: "1" },
];
const reviewQuestionaire = [
  { value: 1, label: "No" },
  { value: 2, label: "yes" },
  { value: 3, label: "unavailable" },
];
const medicineReconciliation = [
  { value: "Reconciled", label: "Reconciled" },
  { value: "Not Required", label: "Not Required" },
];
const postingType = [
  { value: 1, label: "Anticipated Rev" },
  { value: 2, label: "Reimbursement" },
];

const roleWithPermissions = {
  nurse: [
    "establish_complexity",
    "clinical_examination",
    "patient",
    "patient_detail_edit",
  ],
  data_entry_operator: [
    "patient",
    "discharge_summary",
    "add_patient",
    "tcm_billing",
    "tcm_reimbursement",
  ],
  call_agent: ["initial_contact", "appointment_scheduling", "feedback_call"],
  front_office_agent: [],
  provider: ["clinical_examination"],
  biller: ["tcm_billing", "tcm_reimbursement"],
  super_admin_discharge_health: [
    "patient",
    "discharge_summary",
    "initial_contact",
    "appointment_scheduling",
    "feedback_call",
    "establish_complexity",
    "tcm_billing",
    "tcm_reimbursement",
    "clinical_examination",
    "add_users",
    "add_patient",
    "dashboard",
    "select-reports",
    "patient_detail_edit",
    "roles_management",
  ],
  super_admin_provider_office: [
    "patient",
    "discharge_summary",
    "initial_contact",
    "appointment_scheduling",
    "feedback_call",
    "establish_complexity",
    "tcm_billing",
    "tcm_reimbursement",
    "clinical_examination",
    "add_users",
    "add_patient",
    "dashboard",
    "select-reports",
    "patient_detail_edit",
    "roles_management",
  ],
  admin: [
    "patient",
    "discharge_summary",
    "initial_contact",
    "appointment_scheduling",
    "feedback_call",
    "establish_complexity",
    "tcm_billing",
    "tcm_reimbursement",
    "clinical_examination",
    "add_users",
    "add_patient",
    "dashboard",
    "select-reports",
    "patient_detail_edit",
    "roles_management",
  ],
};

const genders = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "other",
  },
  {
    label: "Unknown",
    value: "unknown",
  },
];

const RolesOption = [
  { label: "Add", value: 1 },
  { label: "Edit", value: 2 },
  { label: "Update", value: 3 },
  { label: "Delete", value: 4 },
];
const rolesModalData = [
  {
    title: "User",
  },
  {
    title: "Dashboard",
  },
  {
    title: "Patient",
  },
  {
    title: "Discharge Summary",
  },
  {
    title: "Complexity",
  },
  {
    title: "Initial Contact",
  },
  {
    title: "Clinical Examination",
  },

  {
    title: "Feedback",
  },
  {
    title: "TCM Billing",
  },
  {
    title: "TCM Reiembursment",
  },
];

const careGiverOptionsData = [
  {
    label: "None",
    value: "none",
  },

  {
    label: "Father",
    value: "father",
  },

  {
    label: "Mother",
    value: "mother",
  },

  {
    label: "Brother",
    value: "brother",
  },
  {
    label: "Sister",
    value: "sister",
  },

  {
    label: "Son",
    value: "son",
  },

  {
    label: "Daughter",
    value: "daughter",
  },

  {
    label: "Uncle",
    value: "uncle",
  },

  {
    label: "Aunt",
    value: "aunt",
  },

  {
    label: "Other",
    value: "other",
  },
];

const selectReportsData = [
  {
    label: "Billing and Claim Reports",
    value: "billing and claims reports",
  },
  {
    label: "Patient Call List",
    value: "patient call list",
  },
  {
    label: "Feedback Call List",
    value: "feedback call list",
  },
  {
    label: "Examined Patient List",
    value: "examined patient list",
  },
  {
    label: "Cancel Patient List",
    value: "cancel patient list",
  },
  {
    label: "No Show Patient List",
    value: "no show patient list",
  },
  {
    label: "TCM Compliance Report",
    value: "tcm compliance report",
  },
  {
    label: "Pre Face to Face TCM Report",
    value: "pre face to face tcm report",
  },
  {
    label: "Post Face to TCM Face Report",
    value: "post face to face report",
  },
];

const permissionsData = {
  // "read": [
  //   "read"
  // ],
  user: [
    "user_none",
    "user_view",
    "user_view_create_edit",
    "user_view_create_edit_delete",
  ],
  patient: [
    "patient_none",
    "patient_view",
    "patient_view_create_edit",
    "patient_view_create_edit_delete",
  ],
  dischargeSummary: [
    "dischargeSummary_none",
    "dischargeSummary_view",
    "dischargeSummary_view_create_edit",
    "dischargeSummary_view_create_edit_delete",
  ],
  complexity: [
    "complexity_none",
    "complexity_view",
    "complexity_view_create_edit",
    "complexity_view_create_edit_delete",
  ],
  initialContact: [
    "initialContact_none",
    "initialContact_view",
    "initialContact_view_create_edit",
    "initialContact_view_create_edit_delete",
  ],
  appointment: [
    "appointment_none",
    "appointment_view",
    "appointment_view_create_edit",
    "appointment_view_create_edit_delete",
  ],
  clinical: [
    "clinical_none",
    "clinical_view",
    "clinical_view_create_edit",
    "clinical_view_create_edit_delete",
  ],
  feedback: [
    "feedback_none",
    "feedback_view",
    "feedback_view_create_edit",
    "feedback_view_create_edit_delete",
  ],
  tcmBilling: [
    "tcmBilling_none",
    "tcmBilling_view",
    "tcmBilling_view_create_edit",
    "tcmBilling_view_create_edit_delete",
  ],
  tcmReimbursement: [
    "tcmReimbursement_none",
    "tcmReimbursement_view",
    "tcmReimbursement_view_create_edit",
    "tcmReimbursement_view_create_edit_delete",
  ],
  dashboard: ["dashboard_none", "dashboard_view"],
  report: [
    "report_none",
    "report_view",
    "report_view_create_edit",
    "report_view_create_edit_delete",
  ],
  rolesManagement: [
    "rolesManagement_none",
    "rolesManagement_view",
    "rolesManagement_view_create_edit",
    "rolesManagement_view_create_edit_delete",
  ],
};
//CCM Initial Calls Options

const initialCallsOptions = [
  { label: "Alzheimer’s Disease", value: 1 },
  { label: "Disease A", value: 2 },
  { label: "Disease B", value: 3 },
  { label: "Disease C", value: 4 },
];
const designCarePlanApprovalOptions = [
  { label: "Provider A", value: 1 },
  { label: "Provider B", value: 2 },
  { label: "Provider C", value: 3 },
  { label: "Provider D", value: 4 },
];
const initialCallsSubtypeOptions = [
  { label: "Early-Onset Alzheimer’s Disease", value: 0 },
  { label: "Disease A", value: 1 },
  { label: "Disease B", value: 2 },
  { label: "Disease C", value: 3 },
];
const initialCallsStatus = [
  { label: "Success", value: 1 },
  { label: "Pending", value: 2 },
  { label: "Reject", value: 3 },
];
const designCarePlanOptions = [
  { label: "Options1", value: 1 },
  { label: "Options2", value: 2 },
  { label: "Options3", value: 3 },
];
const designCarePlanApprovalFieldsOptions = [
  { label: "Options1", value: 1 },
  { label: "Options2", value: 2 },
  { label: "Options3", value: 3 },
];
const monthlyCallOptions = [
  { label: "Options1", value: 1 },
  { label: "Options2", value: 2 },
  { label: "Options3", value: 3 },
];

export {
  activeInactive,
  genders,
  medicineReconciliation,
  pointOfServices,
  postingType,
  reviewQuestionaire,
  roleWithPermissions,
  rolesModalData,
  RolesOption,
  careGiverOptionsData,
  selectReportsData,
  permissionsData,
  initialCallsOptions,
  initialCallsSubtypeOptions,
  designCarePlanApprovalOptions,
  initialCallsStatus,
  designCarePlanOptions,
  designCarePlanApprovalFieldsOptions,
  monthlyCallOptions,
};
