import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
const DashboardTooltip = ({ placement, title, children }) => {
  return (
    <>
      <Tooltip title={title} placement={placement} arrow>
        <IconButton sx={{ p: 0, background: "transparent !important" }}>
          {children}
        </IconButton>
      </Tooltip>
    </>
  );
};

export default DashboardTooltip;
