import { Grid } from "@mui/material";
import request from "api/request";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import InputField from "jsx/Common/FormComponents/InputField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import { useQueryClient } from "react-query";
import { dischargeFacilitySchema } from "validations/DischargePatient/dischargeFacilitySchema";

const DischargeFacilityModal = ({ setOpenModal, dichargeProviderData }) => {
  const { useAppMutation, useFetch } = useReactQuery();
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const handleCancel = () => {
    setOpenModal(false);
  };

  const { mutate, isLoading } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: "/create/provider-facility",
        data,
      }),
    {
      onSuccess: (data) => {
        showToast("Discharge Facility Added Successfully", {
          type: "success",
        });

        queryClient.invalidateQueries(["HospitalNameData"]);
        handleCancel();
        formik.resetForm();
      },
    }
  );

  const { data: facilityTypeData, isLoading: isFacilityTypeLoading } = useFetch(
    ["facilityTypeData"],
    () =>
      request({
        method: "get",
        url: "/facility-types",
      })
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      phoneNumber: "",
      email: "",
      user_id: "",
      hospital_type_id: "",
    },
    validationSchema: dischargeFacilitySchema,

    onSubmit: async (values) => {
      mutate(values);
    },
  });
  return (
    <Grid container columnSpacing={2}>
      <InputField label="Name" required name="name" formik={formik} />
      <InputField label="Phone Number" name="phoneNumber" formik={formik} />
      <InputField label="Email" name="email" formik={formik} />
      <InputField label="Address" cols={6} name="address" formik={formik} />

      <SelectField
        required
        options={dichargeProviderData?.Providers?.map(
          ({ id, firstName, lastName }) => {
            return { label: `${firstName} ${lastName}`, value: id };
          }
        )}
        label={"Discharge Provider"}
        name="user_id"
        formik={formik}
      />

      <SelectField
        cols={6}
        required
        label="Facility Type"
        name="hospital_type_id"
        formik={formik}
        options={facilityTypeData?.data?.map((item, index) => {
          return { label: item?.name, value: item?.id };
        })}
      />
      <Flex>
        <FormButtons
          saveID="saveFacility"
          cancelID="cancelFacility"
          saveLoading={isLoading}
          onClickSave={formik.submitForm}
          onClickCancel={() => {
            handleCancel();
          }}
        />
      </Flex>
    </Grid>
  );
};

export default DischargeFacilityModal;
