import { Box, useTheme } from "@mui/material";
import { useFormik } from "formik";
import useToast from "hooks/useToast";
import Button from "jsx/Common/Button/Button";
import CustomTabs from "jsx/Common/CustomTabs/CustomTabs";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import { DemographicsSchema } from "jsx/Screens/Ccm/Validations/AddPatient/DemographicsSchema";
import { useState } from "react";
import ContactForm from "./Components/ContactForm";
import DemographicsForm from "./Components/DemographicsForm";
import DiagnosisData from "./Components/DiagnosisData/DiagnosisData";
import DocumentsData from "./Components/DocumentsData/DocumentsData";
import EmployerForm from "./Components/EmployerForm";
import GuardianForm from "./Components/GuardianForm";
import ImmunizationRegistryFrom from "./Components/ImmunizationRegistryFrom";
import PharmacyFrom from "./Components/PharmacyFrom";
import Insurance from "./Insurance/Components/Insurance/Insurance";

export const AddPatient = () => {
  // state for tabs navigation
  const [tabValue, setTabValue] = useState(0);

  const theme = useTheme();
  const { showToast } = useToast();

  // Formik
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      birthDate: "",
      age: "",
      gender: "",
      email: "",
      mobilePhone: "",
      address1: "",
      city: "",
      zipcode: "",
      state: "",

      type: "ccm", //type is mendatory for add Patient
      patient_img_id: "",
      countryRegion: "US",

      // Guardin form fields
      caregiver_contact: "",
      guardianFullName: "",
      caregiver_relationship: "",

      // Contact form fields
      hpiContactMethod: "",
      referringProvider: "",
      npi: "",
      emergencyContact: "",

      // Employer Form fields
      employerName: "",
      employerContactNo: "",
      employerAddress1: "",
      employerAddress2: "",
      employerCity: "",
      employerZipCode: "",
      employerState: "",

      // PharmacyFrom fields
      pharmacyName: "",
      pharmacyPhone: "",
      pharmacyAddress1: "",

      // ImmunizationRegistry From fields
      immunizationProtection: "",
      publicityCode: "",
    },
    validationSchema: DemographicsSchema,
    onSubmit: async (values) => {
      const message = "Data Added Successfully";
      showToast(message, { type: "success" });
    },
  });

  const tabsHeadind = [
    "Demographics",
    "Guardian",
    "Contact",
    "Employer",
    "Pharmacy",
    "Immunization",
    "Diagnosis",
    "Insurance",
    "Documents",
  ];

  const tabsData = [
    {
      component: <DemographicsForm formik={formik} />,
    },
    {
      component: <GuardianForm formik={formik} />,
    },
    {
      component: <ContactForm formik={formik} />,
    },

    {
      component: <EmployerForm formik={formik} />,
    },
    {
      component: <PharmacyFrom formik={formik} />,
    },
    {
      component: <ImmunizationRegistryFrom formik={formik} />,
    },
    {
      component: <DiagnosisData formik={formik} />,
    },
    {
      component: <Insurance />,
    },
    {
      component: <DocumentsData />,
    },
  ];
  return (
    <>
      <CustomTabs
        tabs={tabsHeadind}
        setTabValue={setTabValue}
        tabValue={tabValue}
      />
      <Box sx={{ px: 1, py: 2 }}>{tabsData?.[tabValue]?.component}</Box>

      {tabValue <= 5 ? (
        <Flex mt={2} gap={2}>
          <Button text={"save"} onClick={formik.submitForm} />
          {tabValue < 5 ? (
            <Button
              onClick={() => {
                setTabValue(tabValue + 1);
              }}
              text={"next"}
              sx={{
                px: 3,
              }}
            />
          ) : (
            ""
          )}

          <Button
            text={"cancel"}
            variant="contained"
            sx={{
              background: theme.palette.common.white,
              color: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.primary.main,
              },
            }}
          />
        </Flex>
      ) : (
        ""
      )}
    </>
  );
};
