import { Grid } from "@mui/material";
import request from "api/request";
import { postingType } from "assets/static/options";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import InputField from "jsx/Common/FormComponents/InputField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import Loader from "jsx/Common/Loaders/Loader";
import PatientDetail from "jsx/Common/PatientDetail/PatientDetail";
import Text from "jsx/Common/Typography/Text";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import ReImbursementSchema from "validations/DischargePatient/ReImbursement";

const AwaitingReadyForReimbursementForm = ({
  validateCount = 0,
  setValidateCount,
  setOpen,
  setSelectedImbursement,
  selectedImbursement,
  pateintDetailSummary = false,
  dischargeData = {},
}) => {
  const { id } = useParams();
  const { useAppMutation, useFetch } = useReactQuery();
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const handleCancel = () => {
    setOpen && setOpen(false);
  };

  const { data: patientData, isLoading: patientDataLoading } = useFetch(
    `/patient/${id ?? selectedImbursement?.patient?.id}`,
    () =>
      request({
        method: "get",
        url: `/patient/${id ?? selectedImbursement?.patient?.id}`,
      })
  );

  const { mutate, isLoading } = useAppMutation(
    (data) =>
      request({
        method: "put",
        url: `/billings/${
          dischargeData?.id
            ? dischargeData?.tcm_billings?.id
            : selectedImbursement?.id ?? patientData?.patient?.billing_last?.id
        }`,
        data,
      }),
    {
      onSuccess: (data) => {
        showToast(
          dischargeData?.id
            ? "Re Imbursement Record Edited"
            : "Re Imbursement Record Added",
          {
            type: "success",
          }
        );

        setValidateCount && setValidateCount(validateCount + 1);
        // queryClient.invalidateQueries(
        //   `/dischargeSummaryDetail/${dischargeData?.id}`
        // );

        queryClient.invalidateQueries([
          `/patient/${id ?? selectedImbursement?.patient?.id}`,
        ]);
        queryClient.invalidateQueries(["clinicalExaminations"]);
        // queryClient.invalidateQueries({ queryKey: [`billings/${id}`] });
        queryClient.invalidateQueries(["billings"]);
        // dischargeData?.id &&

        queryClient.invalidateQueries(["counts"]);
        !dischargeData?.id && formik.resetForm();
        setSelectedImbursement && setSelectedImbursement({});
        handleCancel();
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      amount: dischargeData?.id
        ? dischargeData?.tcm_reimbursements?.receivingAmount
        : "",
      postingType:
        [
          postingType.find(
            (val) =>
              val.value == patientData?.patient?.billing_last?.postingType
          ),
        ] ?? [],
    },
    validationSchema: ReImbursementSchema,

    onSubmit: async (values) => {
      mutate({ amount: values.amount });
    },
  });

  // useEffect(() => {

  //   formik.setValues({
  //     ...formik.values,
  //     amount: dischargeData?.id
  //       ? dischargeData?.tcm_reimbursements[0]?.receivingAmount
  //       : "",
  //     postingType:
  //       [
  //         postingType.find(
  //           (val) =>
  //             val.value == patientData?.patient?.billing_last?.postingType
  //         ),
  //       ] ?? [],
  //   })
  // }, [dischargeData])

  return patientDataLoading ? (
    <Loader />
  ) : (
    <>
      {(selectedImbursement || patientData?.patient) &&
      !pateintDetailSummary ? (
        <PatientDetail
          selectedRecord={selectedImbursement}
          patientRecord={patientData}
        />
      ) : (
        ""
      )}

      <Grid
        style={{
          border: pateintDetailSummary
            ? "none"
            : "1px solid rgba(145,144,144,0.3)",
          borderRadius: pateintDetailSummary ? "" : "10px",
          padding: "10px 12px",
          marginTop: pateintDetailSummary ? "-40px" : "10px",
        }}
        container
        columnSpacing={2}
      >
        {!pateintDetailSummary ? (
          <Flex justifyContent="flex-start">
            <Text variant="h3" color="gray">
              Add ReImbursement
            </Text>
          </Flex>
        ) : (
          ""
        )}
        {/* <InputField cols={12} icon={true} /> */}
        <InputField
          disabled
          value={`${patientData?.patient?.firstName} ${patientData?.patient?.initialName} ${patientData?.patient?.lastName}`}
          label={"Patient Name"}
        />
        <InputField
          disabled
          value={`${patientData?.patient?.uuid}`}
          label={"UUID"}
        />
        <InputField
          disabled
          value={`${patientData?.patient?.discharge_patient_last?.id}`}
          label={"Discharge ID"}
        />
        <InputField
          disabled
          value={`${
            patientData?.patient?.discharge_patient_last?.dischargeDate?.split(
              " "
            )[0]
          }`}
          label={"Discharge Date"}
          type={"date"}
        />
        <InputField
          disabled
          value={`${patientData?.patient?.discharge_patient_last?.insurance?.insuranceName}`}
          label={"Insurance"}
        />
        {/* <InputField
          disabled
          value={`${patientData?.patient?.establish_complexity_last?.cptCode}`}
          label={"CPT Code"}
        /> */}

        {/* <SelectField
          options={postingType}
          defaultValue={postingType.find(
            (val) =>
              val.value == patientData?.patient?.billing_last?.postingType
          )}
          name={"postingType"}
          formik={formik}
          disabled
          label={"Posting Type"}
        /> */}
        <InputField name={"amount"} formik={formik} label={"Amount"} />
        <Flex>
          <FormButtons
            saveID="saveReimbursement"
            cancelID="cancelReimbursement"
            isCancel={pateintDetailSummary ? false : true}
            onClickSave={formik.submitForm}
            saveLoading={isLoading}
            onClickCancel={() => {
              handleCancel();
            }}
          />
        </Flex>
      </Grid>
    </>
  );
};

export default AwaitingReadyForReimbursementForm;
