
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import LogoImg from "../../../../../assets/images/logo.png"
import Button from 'jsx/Common/Button/Button';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from 'assets/static/appRoutes';


export default function TopBar() {
    const token = localStorage.getItem("token") || null;
    const navigate = useNavigate();
    const { homeRedirect, loginRedirect } = appRoutes
    return (
        <Box sx={{ flexGrow: 1, width: "100%", }}>
            <AppBar position="static" sx={{ background: "none", boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', width: "100%" }}>
                <Toolbar sx={{ height: "70px", width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Box>
                        <img src={LogoImg} style={{ width: '160px' }} alt='/' />
                    </Box>
                    { }
                    <Button onClick={() => { navigate(token ? homeRedirect : loginRedirect) }} text={token ? "Dashboard" : "Login"} marginTop="0px" width="90px" height="40px" fontSize="16px" />
                </Toolbar>
            </AppBar>
        </Box>
    );
}