import * as Yup from "yup";

export const awaitingAppointmentSchema = Yup.object().shape({
  patient_id: Yup.number().required("Patient ID is required"),
  // initial_contact_id: Yup.number().required("Initial Contact ID is required"),
  // reviewComplexityIndicator: Yup.string().required(
  //   "Review Complexity Indicator is required"
  // ),
  // reviewComplexityDescription: Yup.string().required(
  //   "Review Complexity Description is required"
  // ),
  // reviewDueDate: Yup.boolean().oneOf([true], "Review Due Date is required"),
  // reviewDueDateDescription: Yup.string().required(
  //   "Review Due Date Description is required"
  // ),
  appointmentDateIndicator: Yup.date().required("Appointment Date is required"),
  pointOfService: Yup.string().required("Point of Service is required"),
  // assignDateTime: Yup.date().required("Assign Date is required"),
  assignTo: Yup.string().required("Assign To is required"),
});
