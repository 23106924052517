import { Grid } from "@mui/material";
import request from "api/request";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import InputField from "jsx/Common/FormComponents/InputField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import { useQueryClient } from "react-query";
import { icdSchema } from "validations/IcdsFormValidation";

const AddIcdForm = ({ setOpenModal }) => {
  const { useFetch, useAppMutation } = useReactQuery();
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const handleCancel = () => {
    setOpenModal(false);
  };

  const { mutate, isLoading } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: "/create/icd-codes",
        data,
      }),
    {
      onSuccess: (data) => {
        showToast("Diagnosis Added Successfully", {
          type: "success",
        });

        queryClient.invalidateQueries(["icd-code"]);
        handleCancel();
        formik.resetForm();

        // setOpenModal && setOpenModal(false);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      icd_code: "",
      description: "",
    },
    validationSchema: icdSchema,

    onSubmit: async (values) => {
      mutate(values);
    },
  });

  return (
    <Grid container columnSpacing={2}>
      <InputField
        label="Description"
        required
        cols={12}
        name="description"
        formik={formik}
      />
      <InputField
        label="Code"
        required
        cols={12}
        name="icd_code"
        formik={formik}
      />
      <Flex>
        <FormButtons
          saveID="saveDiagnosis"
          cancelID="cancelDiagnosis"
          saveLoading={isLoading}
          onClickSave={formik.submitForm}
          onClickCancel={() => {
            handleCancel();
          }}
        />
      </Flex>
    </Grid>
  );
};

export default AddIcdForm;
