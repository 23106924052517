import { Box, Grid } from '@mui/material';
import { makeStyles } from "@mui/styles";
import request from 'api/request';
import forgotPassword from "assets/images/forgot_pass_bg.png";
import forgotPasswordIcon from "assets/images/forgot_pass_icon.png";
import { appRoutes } from 'assets/static/appRoutes';
import { useFormik } from 'formik';
import useReactQuery from 'hooks/useReactQuery';
import useToast from 'hooks/useToast';
import Button from "jsx/Common/Button/Button";
import InputField from "jsx/Common/FormComponents/InputField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import Logo from "jsx/Common/Logo/Logo";
import Text from "jsx/Common/Typography/Text";
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SetNewPasswordValidations } from 'validations/SetNewPasswordValidations/SetNewPasswordValidations';
import backArrow from "../../../../../assets/images/Icon awesome-long-arrow-alt-down.svg";
const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: "5px",
    },
    logo: { width: "400px", height: "40px", marginBottom: "30px" },
    loginLeftside: {
        padding: "40px 110px 30px 110px",
        boxShadow: "0px 8px 12px #00000029",
    },
    loginRightside: {
        backgroundImage: `url(${forgotPassword})`,
        backgroundSize: "cover",
        backgroundPosition: "10% 10%",
        height: "100%",
        display: "flex",
        flexDirection: "column !important",
        alignItems: "center",
        justifyContent: "center",
    },
    icon: { width: 50, height: 50 },
    colors: { color: "#666666" },
    signupColors: { color: theme.palette.primary.main, textDecoration: "none" },
    forgotPasswordImg: {
        width: "63px",
        height: "63px",
        margin: "15px 0px"
    },
    BackBtnClass: {
        backgroundColor: 'transparent !important',
        color: "#1C4588 !important",
        boxShadow: "none !important",
        width: "145px",
        paddingLeft: "30px !important",
        fontSize: "14px !important"
    },
    buttonBox: {
        position: "relative",
    },
    buttonIcon: {
        position: "absolute",
        bottom: "15px",
        left: "7px",
        color: "#666666",
        width: "20px !important",
        height: "9px !important"
    },
    headingClass: {
        fontSize: "28px"
    },
    continueButton: {
        marginTop: "25px !important",
        width: "150px !important",
        height: "40px !important",
        fontSize: "14px !important"
    },
    InpParent: {
        position: "relative",
        '& .MuiOutlinedInput-root': {
            borderRadius: "0px !important",
            borderBottom: '1px solid #ccc !important',
            borderTop: 'none !important',
            borderLeft: 'none !important',
            borderRight: 'none !important',
            marginBottom: "10px",
            paddingRight: "40px"
        },
    },

}));
const SetNewPassword = ({ emaiReset, setPasswordReset, passwordReset }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const { showToast } = useToast();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const optEmail = queryParams.get('email');
    const { useAppMutation } = useReactQuery();
    const { mutate, isLoading } = useAppMutation(
        (data) =>
            request({
                method: 'post',
                url: '/resetPassword',
                data,
            }),
        {
            onSuccess: (res) => {

                setPasswordReset(passwordReset + 1)

                showToast('Password Updated successfully!', {
                    type: 'success',
                });
            },
        }
    );
    const formik = useFormik({
        initialValues: {
            email: emaiReset || '',
            new_password: '',
            password_confirmation: '',
        },
        validationSchema: SetNewPasswordValidations,
        onSubmit: async (values) => {
            if (values.new_password === values.password_confirmation) {
                mutate(values);
            } else {
                showToast('Passwords do not match', {
                    type: 'error',
                });
            }
        },
    });

    return (
        <Box height="100vh" width="100%" sx={{ display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden" }}>
            <Grid container spacing={2} maxWidth="1440px" >
                <Grid item mobile={12}>
                    <Grid container className={classes.root}>
                        <Grid item mobile={1} />
                        <Grid
                            item
                            laptop={5}
                            desktop={4}
                            sx={{
                                display: {
                                    mobile: "none",
                                    tablet: "none",
                                    laptop: "flex",
                                    desktop: "flex",
                                },
                            }}
                        >
                            <Grid item mobile={12} className={classes.loginRightside}>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            mobile={10}
                            sm={10}
                            md={10}
                            laptop={5}
                            desktop={6}
                            className={classes.loginLeftside}
                        >
                            <Grid item mobile={12} >
                                <Flex justifyContent='start'>
                                    <Logo />
                                </Flex>
                                <Box sx={{ marginTop: "30" }}>
                                    <img src={forgotPasswordIcon} className={classes.forgotPasswordImg} alt="Forgot Password Icon" />
                                </Box>
                                <Text className={classes.headingClass} marginTop="0px" marginBottom="4px">Set new password </Text>
                            </Grid>
                            <Grid item mobile={12} marginTop="30px" className={classes.InpParent}>
                                <form >

                                    <InputField
                                        name="new_password"
                                        cols={12}
                                        placeholder="Enter Password"
                                        value={formik.values.new_password}
                                        type={passwordVisible ? "text" : "password"}
                                        icon
                                        onChange={formik.handleChange}
                                        formik={formik}
                                        passwordVisible={passwordVisible}
                                        setPasswordVisible={setPasswordVisible}
                                    />
                                    <InputField
                                        name="password_confirmation"
                                        type={confirmPasswordVisible ? "text" : "password"}
                                        cols={12}
                                        icon
                                        placeholder="Confirm Password"
                                        value={formik.values.password_confirmation}
                                        passwordVisible={confirmPasswordVisible}
                                        setPasswordVisible={setConfirmPasswordVisible}
                                        onChange={formik.handleChange}
                                        formik={formik}
                                    />
                                    <Button className={classes.continueButton}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        sx={{ marginBottom: '0px' }}
                                        text="Confirm"
                                        onClick={formik.handleSubmit}
                                        isLoading={isLoading}
                                    />
                                </form>
                            </Grid>
                            <Grid item mobile={12} marginTop="5px">
                                <Flex justifyContent='space-between' alignItems='center'>
                                    <Box className={classes.buttonBox}>
                                        <img src={backArrow} className={classes.buttonIcon} alt="" />
                                        <Button className={classes.BackBtnClass}
                                            variant="contained"
                                            type="submit"
                                            text={"Back to log in"}
                                            onClick={() => { navigate(appRoutes.loginRedirect) }}
                                            isLoading={isLoading}

                                        />
                                    </Box>
                                </Flex>
                            </Grid>
                        </Grid>
                        <Grid item mobile={1} />
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    )
}

export default SetNewPassword