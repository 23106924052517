import { Box, Grid } from "@mui/material";
import { designCarePlanApprovalOptions } from "assets/static/options";
import GlobalSearch from "jsx/Common/FormComponents/GlobalSearch";
import SelectField from "jsx/Common/FormComponents/SelectField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import GenericTable from "jsx/Common/Table/GenericTable";
import TableActionButtons from "jsx/Common/Table/TableActionButtons";
import Text from "jsx/Common/Typography/Text";
import Wrapper from "jsx/Common/Wrapper";

const DesignCarePlanApprovalList = ({ customProp }) => {
  const AdminPaneltHeaderData = [
    { Header: "UUID", accessor: "uuid" },
    { Header: "Name", accessor: "name" },
    { Header: "Admission Date", accessor: "admission_date" },
    { Header: "Phone#", accessor: "phone" },
    { Header: "Status", accessor: "status" },
    {
      Header: "Options",
      Cell: ({ row }) => {
        return <TableActionButtons />;
      },
    },
  ];
  const TableData = [
    {
      uuid: "HFIX-HCIX",
      name: "Patient 1",
      admission_date: "11/22/2023",
      phone: "123-456-7890",
      status: "Pending for Approval",
      options: "Edit",
    },
  ];

  return (
    <Box>
      <Text>Care Plan Approval List </Text>
      <Wrapper>
        <Text variant="h2" color={"blue"}>
          Select Patient
        </Text>
        <Box>
          <Grid container>
            <Flex justifyContent="space-between">
              <SelectField
                cols={3}
                label={"Provider :"}
                options={designCarePlanApprovalOptions}
                defaultValue={designCarePlanApprovalOptions[0]}
              />
              <Grid item desktop={3} mt="35px">
                <GlobalSearch placeholder="Search" />
              </Grid>
            </Flex>
          </Grid>
        </Box>
        <GenericTable columns={AdminPaneltHeaderData} data={TableData} />
      </Wrapper>
    </Box>
  );
};

export default DesignCarePlanApprovalList;
