import { Box, Grid } from '@mui/material';
import { makeStyles } from "@mui/styles";
import forgotPassword from "assets/images/forgot_pass_bg.png";
import forgotPasswordIcon from "assets/images/forgot_pass_icon.png";
import Button from "jsx/Common/Button/Button";
import InputField from "jsx/Common/FormComponents/InputField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import Logo from "jsx/Common/Logo/Logo";
import Text from "jsx/Common/Typography/Text";
import backArrow from "../../../../../assets/images/Icon awesome-long-arrow-alt-down.svg";
import { appRoutes } from 'assets/static/appRoutes';
import { useNavigate } from "react-router-dom";
import useReactQuery from 'hooks/useReactQuery';
import request from 'api/request';
import { useFormik } from 'formik';
import { ForgotPasswordValidations } from 'validations/ForgotPasswordValidations/ForgotPasswordValidations';
import useToast from 'hooks/useToast';
import { useQuery } from 'react-query';
import { useState } from 'react';
import VerifyEmail from './VerifyEmail';
import SetNewPassword from './SetNewPassword';
import PasswordUpdated from './PasswordUpdated';

const ForgotPasswordScreen = () => {
    const [passwordReset, setPasswordReset] = useState(0)
    const [emaiReset, setEmailReset] = useState("")
    const { showToast } = useToast();
    // styles
    const useStyles = makeStyles((theme) => ({
        root: {
            borderRadius: "5px",
        },
        logo: { width: "400px", height: "40px", marginBottom: "30px" },
        loginLeftside: {
            padding: "60px 90px 20px 90px",
            boxShadow: "0px 8px 12px #00000029",
        },
        loginRightside: {
            backgroundImage: `url(${forgotPassword})`,
            backgroundSize: "cover",
            backgroundPosition: "10% 10%",
            height: "100%",
            display: "flex",
            flexDirection: "column !important",
            alignItems: "center",
            justifyContent: "center",
        },
        icon: { width: 50, height: 50 },
        colors: { color: "#666666" },
        signupColors: { color: theme.palette.primary.main, textDecoration: "none" },
        forgotPasswordImg: {
            width: "63px",
            height: "63px",
            margin: "20px 0px"
        },
        BackBtnClass: {
            backgroundColor: 'transparent !important',
            color: "#1C4588 !important",
            boxShadow: "none !important",
            width: "145px",
            paddingLeft: "30px !important",
            fontSize: "14px !important"
        },
        buttonBox: {
            position: "relative",
        },
        buttonIcon: {
            position: "absolute",
            bottom: "15px",
            left: "7px",
            color: "#666666",
            width: "20px !important",
            height: "9px !important"
        },
        headingClass: {
            fontSize: "28px"
        },
        continueButton: {
            marginTop: "25px !important",
            width: "130px !important",
            height: "40px !important",
            fontSize: "14px !important"
        },
        InpParent: {
            '& .MuiOutlinedInput-root': {
                borderRadius: "0px !important",
                borderBottom: '1px solid #ccc !important',
                borderTop: 'none !important',
                borderLeft: 'none !important',
                borderRight: 'none !important',
            },
        }
    }));

    // API Implementation
    const navigate = useNavigate();

    const { useAppMutation } = useReactQuery();
    const { mutate, isLoading } = useAppMutation(
        (data) =>
            request({
                method: "post",
                url: "/sendOtp",
                data,
            }), {
        onSuccess: (res) => {

            setPasswordReset(passwordReset + 1)
            setEmailReset(formik.values.email)

            showToast("OTP sent successfully!", {
                type: "success",
            });
        }
    }
    );
    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: ForgotPasswordValidations,
        onSubmit: async (values) => {
            mutate({
                email: values.email,
            });
        },
    });
    // Now, 'responseData' contains the data from the API response
    const classes = useStyles();
    return (
        <>
            <Box>
                {passwordReset === 0 ? <Box height="100vh" width="100%" sx={{
                    display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden"
                }}>
                    <Grid container spacing={2} p={3} maxWidth="1440px" >
                        <Grid item mobile={12}>
                            <Grid container className={classes.root}>
                                <Grid item mobile={1} />
                                <Grid
                                    item
                                    laptop={5}
                                    desktop={4}
                                    sx={{
                                        display: {
                                            mobile: "none",
                                            tablet: "none",
                                            laptop: "flex",
                                            desktop: "flex",
                                        },
                                    }}
                                >
                                    <Grid item mobile={12} className={classes.loginRightside}>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    mobile={10}
                                    sm={10}
                                    md={10}
                                    laptop={5}
                                    desktop={6}
                                    className={classes.loginLeftside}
                                >
                                    <Grid item mobile={12} >
                                        <Flex justifyContent='start'>
                                            <Logo />
                                        </Flex>
                                        <Box sx={{ marginTop: "5px" }}>
                                            <img src={forgotPasswordIcon} className={classes.forgotPasswordImg} alt="Forgot Password Icon" />
                                        </Box>
                                        <Text className={classes.headingClass} marginTop="0px" marginBottom="4px">Forgot Password?</Text>
                                        <Text variant="body2" marginTop="0px" marginBottom="0px">No worries, we'll send you reset instructions.</Text>
                                    </Grid>
                                    <Grid item mobile={12} marginTop="30px" className={classes.InpParent}>
                                        <form >
                                            <InputField
                                                name="email"
                                                type="email"
                                                cols={12}
                                                Placeholder={"Enter your Email"}
                                                formik={formik}
                                            />
                                            <Button className={classes.continueButton}
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                sx={{ marginBottom: "0px" }}
                                                text={"Send OTP"}
                                                isLoading={isLoading}
                                                onClick={formik.handleSubmit}
                                            />
                                        </form>
                                    </Grid>
                                    <Grid item mobile={12}>
                                        <Box className={classes.buttonBox}>
                                            <img src={backArrow} className={classes.buttonIcon} alt="" />
                                            <Button className={classes.BackBtnClass}
                                                onClick={() => { navigate(appRoutes.loginRedirect) }}
                                                variant="contained"
                                                type="submit"
                                                text={"Back to log in"}
                                                marginTop="20px"
                                                isLoading={isLoading}

                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item mobile={1} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box > : passwordReset === 1 ? <VerifyEmail setPasswordReset={setPasswordReset} passwordReset={passwordReset} emaiReset={emaiReset} /> : passwordReset === 2 ? <SetNewPassword setPasswordReset={setPasswordReset} passwordReset={passwordReset} emaiReset={emaiReset} /> : passwordReset === 3 ? <PasswordUpdated /> : ""}
            </Box>

        </>
    )
}

export default ForgotPasswordScreen