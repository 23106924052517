import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { FormControl, Grid, InputAdornment, TextField } from "@mui/material";
import Text from "../Typography/Text";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  inputBg: (props) => ({
    "& .MuiInputBase-root": {
      backgroundColor: props.backgroundColor ? "#FAFAFA" : "#FFFFFF",
    }
  }),
}));
const InputField = ({
  label,
  name,
  min,
  max,
  icon = false,
  type,
  onChange,
  cols = 6,
  formik,
  showError = false,
  errorMessage,
  defaultValue = "",
  required = false,
  isPhone = false,
  middle = false,
  Placeholder,
  multiline,
  searchIcon,
  passwordVisible,
  backgroundColor = "#FAFAFA",
  setPasswordVisible,
  rows,
  ...props
}) => {
  const togglePaswordIcon = () => {
    setPasswordVisible((prevPasswordVisible) => !prevPasswordVisible);
  };
  const classes = useStyles(props);
  const fieldError = formik?.errors[name] || errorMessage;
  const fieldTouched = formik?.touched[name] || showError;
  const handleChange = (event) => {
    let { value } = event.target;
    if (middle && value.length > 1) {
      value = value.charAt(0);
      event.target.value = value;
    }
    if (isPhone === true) {
      value = value.replace(/\D/g, "");
      const match = value.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
      if (match) {
        const formatted = [match[1], match[2], match[3]]
          .filter((group) => !!group)
          .join(" ");
        event.target.value = formatted;
      }
    }
    if (formik) {
      formik.handleChange(event);
    } else {
      onChange && onChange(event);
    }
    if (props.maxLength && value.length > props.maxLength) {
      return;
    }
  };
  const handleKeyPress = (event) => {
    if (isPhone) {
      // Restrict input to numeric characters only
      // const pattern = /^[0-9-]*$/;
      const pattern = /^\d{0,3}(-\d{0,3}(-\d{0,4})?)?$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
  };
  // const inputProps = middle ? { maxLength: 1 } : {};
  return (
    <Grid item mobile={12} tablet={cols} laptop={cols} desktop={cols}>
      {label && (
        <Text variant="body2" my={1}>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </Text>
      )}
      <FormControl
        className={classes.inputBg}
        fullWidth
        variant="outlined"
        error={fieldTouched && fieldError}
      >
        <TextField
          id={`${name}`}
          autoComplete="off"
          name={name}
          type={type}
          multiline={multiline}
          rows={rows}
          defaultValue={defaultValue}
          InputProps={{
            startAdornment: icon && (
              <InputAdornment
                position="end"
                sx={{
                  cursor: "pointer",
                  color: "#ccc",
                  "& svg": {
                    position: "absolute",
                    right: "10px",
                  },
                }}
                onClick={togglePaswordIcon}
              >
                {searchIcon === true ? (
                  <SearchIcon />
                ) : passwordVisible ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </InputAdornment>
            ),
          }}
          placeholder={Placeholder}
          error={fieldTouched && fieldError}
          helperText={fieldTouched && fieldError}
          onBlur={formik?.handleBlur}
          inputProps={{
            maxLength: isPhone ? 12 : middle ? 1 : props.maxLength,
            minLength: props.minLength,
            defaultValue: defaultValue,
            min: min,
            max: max,
            autoComplete: "new-password",
          }}
          InputLabelProps={{
            shrink: true,
            ...(label && { style: { display: "none" } }),
          }}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          {...props}
          {...formik?.getFieldProps(name)}
        />
      </FormControl>
    </Grid>
  );
};
export default InputField;