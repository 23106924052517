import * as yup from "yup";

export const newPatientSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup.string().email("Invalid Email Address"),
  birthDate: yup.string().required("DOB is required"),
  age: yup.number().required("Age is required"),
  gender: yup.string().required("Sex is required"),
  mobilePhone: yup.string().required("Phone no is required"),
  secondaryPhone: yup.string(),
  Address1: yup.string().required("Address 1 is required"),
  Address2: yup.string(),
  city: yup.string().required("City is required"),
  zipcode: yup.string().required("Zipcode is required"),
  state: yup.string().required("State is required"),
  caregiverRelationship: yup
    .string()
    .required("Caregiver Relationship is required"),
  caregiverName: yup.string().test({
    name: "required-if-not-none",
    test: function (value) {
      const { caregiverRelationship } = this.parent;
      if (caregiverRelationship !== "none" && !value) {
        throw new yup.ValidationError(
          "Caregiver Name is required",
          null,
          this.path
        );
      }
      return true;
    },
  }),
  caregiverContact: yup.string().test({
    name: "required-if-not-none",
    test: function (value) {
      const { caregiverRelationship } = this.parent;
      if (caregiverRelationship !== "none" && !value) {
        throw new yup.ValidationError(
          "Caregiver Contact is required",
          null,
          this.path
        );
      }
      return true;
    },
  }),
  hospital_id: yup.string(),
});
