import { useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from "assets/images/logo.png";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: "40px",
    objectFit: "contain",
  },
}));

const Logo = ({ width = "auto" }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("mobile"));
  return isMobile ? null : (
    <img src={logo} alt="logo" className={classes.logo} width={width} />
  );
};

export default Logo;
