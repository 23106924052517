import { Box } from "@mui/material";

const NurseComment = ({ selectedComplexity = {}, patientData = {} }) => {
  return (
    <>
      <Box
        sx={{
          border: "1px solid #ccc",
          borderRadius: "5px",
          padding: "10px",
          maxHeight: "400px",
          height: "200px",
          overflowY: "auto",
        }}
      >
        {patientData?.patient?.establish_complexity_last?.nurseComments ??
          selectedComplexity?.nurseComments}
      </Box>
    </>
  );
};

export default NurseComment;
