import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
} from "@mui/material";
import Text from "../Typography/Text";

const CustomRadioCheckbox = ({
  label,
  checkRadioLabel,
  value,
  name,
  type = "checkbox",
  checked = false,
  onChange,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  labelPlacement = "end",
  color = "primary",
  cols = "6",
  required = false,
  topRequired = false,
  showError = false,
  errorMessage,
  formik,
  defaultChecked = false,
  marginY = 2,
  ...rest
}) => {
  const formikTouch = formik?.touched[name] || showError;
  const formikError = formik?.errors[name] || errorMessage;

  const handleChange = (event) => {
    let value;
    if (onChange) {
      onChange(event);
    } else {
      value = event.target.checked;
      formik && formik.setFieldValue(name, value);
    }
  };

  return (
    <Grid item mobile={12} tablet={cols}>
      <Box my={marginY}>
        {label && (
          <Text variant="body2" my={1}>
            {label} {topRequired && <span style={{ color: "red" }}>*</span>}
          </Text>
        )}
        <FormControlLabel
          error={formikTouch && formikError}
          control={
            type === "checkbox" ? (
              <Checkbox
                id={name}
                name={name}
                defaultChecked={defaultChecked}
                checked={checked ?? undefined}
                color={color}
                value={checked ?? formik?.values[name] ?? false}
                error={formikTouch && formikError}
                onChange={handleChange}
                {...rest}
              />
            ) : (
              <Radio
                id={name}
                name={name}
                color={color}
                error={formikTouch && formikError}
                value={checked ?? formik?.values[name] ?? false}
                onChange={handleChange}
                {...rest}
              />
            )
          }
          label={
            checkRadioLabel ? `${checkRadioLabel} ${required ? "*" : ""}` : ""
          }
          labelPlacement={labelPlacement}
        />
        <Typography variant="body2" color="error">
          {formikTouch && formikError}
        </Typography>
      </Box>
    </Grid>
  );
};

export default CustomRadioCheckbox;
