import { Box, Grid } from '@mui/material';
import { makeStyles } from "@mui/styles";
import request from 'api/request';
import forgotPassword from "assets/images/forgot_pass_bg.png";
import forgotPasswordIcon from "assets/images/forgot_pass_icon.png";
import { appRoutes } from 'assets/static/appRoutes';
import { useFormik } from 'formik';
import useReactQuery from 'hooks/useReactQuery';
import useToast from 'hooks/useToast';
import Button from "jsx/Common/Button/Button";
import InputField from "jsx/Common/FormComponents/InputField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import Logo from "jsx/Common/Logo/Logo";
import Text from "jsx/Common/Typography/Text";
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import backArrow from "../../../../../assets/images/Icon awesome-long-arrow-alt-down.svg";
const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: "5px",
    },
    logo: { width: "400px", height: "40px", marginBottom: "30px" },
    loginLeftside: {
        padding: "60px 90px 20px 90px",
        boxShadow: "0px 8px 12px #00000029",
    },
    loginRightside: {
        backgroundImage: `url(${forgotPassword})`,
        backgroundSize: "cover",
        backgroundPosition: "10% 10%",
        height: "100%",
        display: "flex",
        flexDirection: "column !important",
        alignItems: "center",
        justifyContent: "center",
    },
    icon: { width: 50, height: 50 },
    colors: { color: "#666666" },
    signupColors: { color: theme.palette.primary.main, textDecoration: "none" },
    forgotPasswordImg: {
        width: "63px",
        height: "63px",
        margin: "20px 0px"
    },
    BackBtnClass: {
        backgroundColor: 'transparent !important',
        color: "#1C4588 !important",
        boxShadow: "none !important",
        width: "145px",
        paddingLeft: "30px !important",
        fontSize: "14px !important"
    },
    buttonBox: {
        position: "relative",
    },
    buttonIcon: {
        position: "absolute",
        bottom: "15px",
        left: "7px",
        color: "#666666",
        width: "20px !important",
        height: "9px !important"
    },
    headingClass: {
        fontSize: "28px"
    },
    continueButton: {
        marginTop: "25px !important",
        width: "150px !important",
        height: "40px !important",
        fontSize: "14px !important"
    },
    InpParent: {
        '& .MuiOutlinedInput-root': {
            border: "1px solid rgba(145, 144, 144, 0.3) !important",
            height: "60px",
            marginRight: "0px",
            width: "75%",
            fontSize: "22px",
            paddingLeft: "8px"
        },
    },

}));

const VerifyEmail = ({ emaiReset, passwordReset, setPasswordReset }) => {
    const { showToast } = useToast();
    const [inputValues, setInputValues] = useState(Array(6).fill(''))
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const optEmail = queryParams.get('email');
    const { useAppMutation } = useReactQuery();
    const { mutate, isLoading } = useAppMutation(
        (data) =>
            request({
                method: "post",
                url: "/verifyOTP",
                data,
            }),
        {
            onSuccess: (res) => {
                setPasswordReset(passwordReset + 1)
                showToast("OTP verified successfully!", {
                    type: "success",
                });

            }
        }
    );
    const formik = useFormik({
        initialValues: {
            email: emaiReset,
            verification_code: '',
        },
        // validationSchema: OtpVerificationValditions,
        onSubmit: async (values) => {
            values.verification_code = inputValues.join('');
            mutate(values);
        },
    });
    const handleFocus = (index, next) => {
        const nextIndex = index + 1;
        const prevIndex = index - 1;
        const nextInput = document.querySelector(`#digit-${nextIndex}`);
        if (nextInput && !next) {
            nextInput.focus();
            return;
        }
        const prevInput = document.querySelector(`#digit-${prevIndex}`);
        if (prevInput) {
            prevInput.focus();
        } else {
            const currentInput = document.querySelector(`#digit-${index}`);
            if (currentInput) {
                currentInput.value = '';
            }
        }
    };


    const handleChange = (index, e) => {
        const value = e.target.value;
        const newInputValues = [...inputValues];

        if (value.match(/[0-9]/) && index < 6) {
            newInputValues[index] = value;

            if (index < 5 && value !== '') {
                // Move focus to the next input
                handleFocus(index, false);
            }

            setInputValues(newInputValues);

            if (newInputValues.every(digit => digit !== '')) {
                // If all digits are filled, submit the form
                formik.handleSubmit();
            }
        } else if (value === '' && index > 0) {
            // Handle backspace/delete
            newInputValues[index] = '';
            setInputValues(newInputValues);
            handleFocus(index, true);
        } else if (value === '' && index === 0) {
            // Handle backspace/delete when index is 0
            newInputValues[index] = '';
            setInputValues(newInputValues);
        } else {
            // Reset the input field if the entered value is not a number
            newInputValues[index] = '';
            setInputValues(newInputValues);
        }
    };
    const modifyEmailFormat = (emaiReset) => {
        const atIndex = emaiReset.indexOf('@');
        const username = emaiReset.substring(0, atIndex);
        const modifiedUsername = username.substring(0, 4) + '*'.repeat(username.length - 1);
        const modifiedEmail = modifiedUsername + emaiReset.substring(atIndex);
        return modifiedEmail;
    };

    const modifiedEmail = modifyEmailFormat(emaiReset);


    return (
        <Box height="100vh" width="100%" sx={{ display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden" }}>
            <Grid container spacing={2} p={3} maxWidth="1440px" >
                <Grid item mobile={12}>
                    <Grid container className={classes.root}>
                        <Grid item mobile={1} />
                        <Grid
                            item
                            laptop={5}
                            desktop={4}
                            sx={{
                                display: {
                                    mobile: "none",
                                    tablet: "none",
                                    laptop: "flex",
                                    desktop: "flex",
                                },
                            }}
                        >
                            <Grid item mobile={12} className={classes.loginRightside}>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            mobile={10}
                            sm={10}
                            md={10}
                            laptop={5}
                            desktop={6}
                            className={classes.loginLeftside}
                        >
                            <Grid item mobile={12} >
                                <Flex justifyContent='start'>
                                    <Logo />
                                </Flex>
                                <Box sx={{ marginTop: "0px" }}>
                                    <img src={forgotPasswordIcon} className={classes.forgotPasswordImg} alt="Forgot Password Icon" />
                                </Box>
                                <Text className={classes.headingClass} marginTop="0px" marginBottom="4px">Verify OTP Code</Text>
                                <Text variant="body2" marginTop="0px" marginBottom="0px">A verification code has been sent to your email at {modifiedEmail}.Kindly check your email spam/junk folder if needed.
                                </Text>
                            </Grid>
                            <Grid item mobile={12} marginTop="10px" className={classes.InpParent}>
                                <Grid container spacing={0}>
                                    {inputValues.map((digit, index) => (
                                        <InputField
                                            className={classes.inpBorderClass}
                                            key={index}
                                            id={`digit-${index}`}
                                            type="text"
                                            cols={2}
                                            inputProps={{
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*',
                                                maxLength: 1,
                                            }}
                                            value={digit}
                                            onChange={(e) => handleChange(index, e)}
                                        />
                                    ))}
                                    <Button className={classes.continueButton}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        sx={{ marginBottom: "0px" }}
                                        text={"Verify OTP "}
                                        onClick={formik.handleSubmit}
                                        isLoading={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item mobile={12} marginTop="0px">
                                <Flex justifyContent='space-between' alignItems='center'>
                                    <Box className={classes.buttonBox}>
                                        <img src={backArrow} className={classes.buttonIcon} alt="" />
                                        <Button className={classes.BackBtnClass}
                                            variant="contained"
                                            type="submit"
                                            text={"Back to log in"}
                                            onClick={() => { navigate(appRoutes.loginRedirect) }}
                                            isLoading={isLoading}
                                        />
                                    </Box>
                                </Flex>
                            </Grid>
                        </Grid>
                        <Grid item mobile={1} />
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    )
}

export default VerifyEmail