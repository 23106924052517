import { Grid } from "@mui/material";
import request from "api/request";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import InputField from "jsx/Common/FormComponents/InputField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import Text from "jsx/Common/Typography/Text";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { awaitingAppointmentSchema } from "validations/DischargePatient/awaitingAppointmentSchema";

const AppointmentForm = ({
  initialID,
  setInitialContactId,
  validateCount = 0,
  setValidateCount,
  setIsAppointmentFormSubmit = false,
  setIsInitialSucc,
  isInitialSucc = false,
  isAppointmentFormSubmit = false,
  setOpen,
  setSelectedAppointment,
  selectedAppointment,
  isIncluded = false,
  dischargeData = {},
}) => {
  const { id } = useParams();
  const handleCancel = () => {
    setOpen(false);
  };

  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const { useAppMutation, useFetch } = useReactQuery();

  const { data: patientData, isLoading: patientDataLoading } = useFetch(
    `/patient/${id ?? selectedAppointment?.patient?.id}`,
    () =>
      request({
        method: "get",
        url: `/patient/${id ?? selectedAppointment?.patient?.id}`,
      }),
    { enabled: !selectedAppointment?.id }
  );

  const { mutate: updateInitialMutate, isLoading: isUpdateInitialLoading } =
    useAppMutation(
      (data) =>
        request({
          method: "put",
          url: `/update-appointments/${dischargeData?.appointments[0]?.id}`,
          data,
        }),
      {
        onSuccess: (data) => {
          setInitialContactId(null)
          showToast("Appointment Edited Successfully", {
            type: "success",
          });
          setValidateCount && setValidateCount(validateCount + 1)
        },
      }
    );

  const { mutate, isLoading } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: "/appointments",
        data,
      }),

    {
      onSuccess: (data) => {
        setInitialContactId(null)
        showToast("Appointment Added Successfully", {
          type: "success",
        });

        queryClient.invalidateQueries([
          `/patient/${id ?? selectedAppointment?.patient?.id}`,
        ]);
        queryClient.invalidateQueries(["appointments"]);
        queryClient.invalidateQueries([`appointments/${id}`]);
        queryClient.invalidateQueries(["initialContacts"]);

        queryClient.invalidateQueries(["counts"]);

        formik.resetForm();
        setSelectedAppointment && setSelectedAppointment({});
        setOpen && setOpen(false);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      patient_id: dischargeData?.id
        ? dischargeData?.patient?.id
        : selectedAppointment?.patient?.id ?? parseInt(id),
      discharge_id: dischargeData?.id
        ? dischargeData?.id
        : selectedAppointment?.discharge_id ??
        patientData?.patient?.discharge_patient_last?.id,
      initial_contact_id: dischargeData?.id
        ? dischargeData?.appointments[0]?.initial_contact?.id
        : initialID ??
        patientData?.patient?.initial_contact_last?.id,
      // reviewComplexityIndicator: false,
      // reviewComplexityDescription: "",
      // reviewDueDate: false,
      // reviewDueDateDescription: "",
      appointmentDateIndicator: dischargeData?.id
        ? dischargeData?.appointments[0]?.appointmentDateIndicator?.split(
          " "
        )[0]
        : "",
      pointOfService: dischargeData?.id
        ? dischargeData?.appointments[0]?.pointOfService
        : "",
      // assignDateTime: "",
      assignTo: dischargeData?.id
        ? dischargeData?.appointments[0]?.assignTo
        : "",
    },
    validationSchema: awaitingAppointmentSchema,

    onSubmit: async (values) => {
      setIsInitialSucc(true)
      if (dischargeData?.id) {
        updateInitialMutate({
          ...values,
          reviewComplexityIndicator:
            values.reviewComplexityIndicator === false ? 0 : 1,
          reviewDueDate: values.reviewDueDate === false ? 0 : 1,
        })
      } else {
        if (initialID) {
          mutate({
            ...values,
            initial_contact_id: initialID,
            reviewComplexityIndicator:
              values.reviewComplexityIndicator === false ? 0 : 1,
            reviewDueDate: values.reviewDueDate === false ? 0 : 1,
          });
        }

      }
    },
  });

  const { data: tcmProviderData, isLoading: tcmProviderLoading } = useFetch(
    "tcmProvider",
    () =>
      request({
        method: "post",
        data: { name: "tcm provider" },
        url: "/provider",
      })
  );

  const { data: HospitalNameData, isLoading: isHospitalNameData } = useFetch(
    ["HospitalNameData", formik.values.assignTo],
    () =>
      request({
        method: "post",
        data: { id: formik.values.assignTo },
        url: "/providerHospitals",
      }),
    {
      enabled: formik.values.assignTo ? true : false,
    }
  );

  useEffect(() => {
    if (isAppointmentFormSubmit && !initialID) {
      formik.submitForm();
    }
  }, [isAppointmentFormSubmit]);

  useEffect(() => {
    if (!isInitialSucc && typeof initialID === 'number') {
      formik.setFieldValue("initial_contact_id", initialID)
      formik.submitForm();
    }

  }, [isInitialSucc, initialID]);

  useEffect(() => {
    if (formik.errors && setIsAppointmentFormSubmit) {
      setIsAppointmentFormSubmit(false);
    }
  }, [formik.errors]);

  return (
    <>
      <Grid
        style={{
          border: isIncluded ? "none" : "1px solid rgba(145,144,144,0.3)",
          borderRadius: "10px",
          padding: "10px 12px",
          marginTop: "10px",
        }}
        container
        columnSpacing={2}
      >
        {!isIncluded && (
          <Flex justifyContent="flex-start">
            <Text variant="h3" color="gray" paddingLeft="16px">
              Add Appointment
            </Text>
          </Flex>
        )}
        <InputField
          formik={formik}
          name="appointmentDateIndicator"
          label={"Appoinment Date"}
          type={"date"}
          min={new Date().toISOString().split("T")[0]}
          required
        />
        <SelectField
          label={"Point of Service"}
          formik={formik}
          options={[
            { label: "Office", value: "1" },
            { label: "Visit/Home", value: "2" },
            { label: "Visit/Telehealth", value: "3" },
          ]}
          defaultValue={
            dischargeData?.id
              ? [
                { label: "Office", value: "1" },
                { label: "Visit/Home", value: "2" },
                { label: "Visit/Telehealth", value: "3" },
              ]?.find(
                ({ value }) =>
                  value == dischargeData?.appointments[0]?.pointOfService
              )
              : ""
          }
          name="pointOfService"
          required
        />
        <SelectField
          label={"Select Provider"}
          formik={formik}
          name="assignTo"
          required
          defaultValue={
            dischargeData?.id
              ? {
                label: `${dischargeData?.appointments[0]?.assign_to?.firstName} ${dischargeData?.appointments[0]?.assign_to?.lastName}`,
                value: dischargeData?.appointments[0]?.assignTo?.id,
              }
              : ""
          }
          options={tcmProviderData?.Providers?.map(
            ({ id, lastName, firstName }) => {
              return {
                label: `${firstName ?? ""} ${lastName ?? ""}`,
                value: id,
              };
            }
          )}
        />
        <SelectField
          required
          label={"Provider Facility"}
          name="hospital_id"
          options={HospitalNameData?.providerHospitals?.[0]?.provider_hospital?.map(
            ({ hospital }) => {
              return { label: hospital?.name, value: hospital?.id };
            }
          )}
          defaultValue={
            dischargeData?.id
              ? {
                label: `${dischargeData?.appointments[0]?.hospital?.name}`,
                value: dischargeData?.appointments[0]?.hospital?.id,
              }
              : ""
          }
          formik={formik}
          disabled={formik.values.assignTo === "" ? true : false}
        />
      </Grid>
    </>
  );
};

export default AppointmentForm;
