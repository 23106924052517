import { Box, Grid } from "@mui/material";
import request from "api/request";
import { roleWithPermissions } from "assets/static/options";
import useReactQuery from "hooks/useReactQuery";
import useTitle from "hooks/useTitle";
import Button from "jsx/Common/Button/Button";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import Loader from "jsx/Common/Loaders/Loader";
import CustomModal from "jsx/Common/Modal/CustomModal";
import Text from "jsx/Common/Typography/Text";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { checkNullValue } from "utils/nullCheck";
import NewPatientForm from "../Form/NewPatientForm";
import AwaitingEstablishComplexity from "../Listing/AwaitingEstablishComplexity";
import NewDischargePatientForm from "./Form/NewDischargePatientForm";
import useFilteredPermissions from "hooks/useFilteredPermissions";

const DischargePatient = () => {
  const [validateCount, setValidateCount] = useState(0);
  const { hasPermission } = useFilteredPermissions();
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const { useFetch } = useReactQuery();
  const [cardTitle, setCardTitle] = useState("");
  const [component, setComponent] = useState("");
  const [pateintDetailEdit, setPateintDetailEdit] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const openDischarge = queryParams.get("openDischarge");
  const navigate = useNavigate();

  useTitle({ title: "New Discharge Summary" });

  const { data, isLoading } = useFetch(
    `dischargesummeries${id ? `/${id}` : ""}`,
    () =>
      request({
        method: "get",
        url: `/dischargesummeries${id ? `/${id}` : ""}`,
      })
  );

  const { data: patientData, isLoading: patientDataLoading } = useFetch(
    [`/patient/${id}`, validateCount],
    () =>
      request({
        method: "get",
        url: `/patient/${id}`,
      })
  );

  useEffect(() => {
    if (openDischarge) {
      setCardTitle("New Discharge Summary");
      setComponent(<NewDischargePatientForm setOpen={setOpen} />);
      setOpen(true);
    }
  }, []);

  const infoArray = [
    {
      name1: "Name:",
      name: `${patientData?.patient?.firstName} ${checkNullValue(
        patientData?.patient?.initialName
      )} ${patientData?.patient?.lastName}`,
      mr_no1: "MRN #:",
      mr_no: patientData?.patient?.discharge_patient_last?.mrn,
      tcm_id1: "UUID:",
      uuid: patientData?.patient?.uuid,
      mobile1: "Mobile #:",
      mobile: patientData?.patient?.mobilePhone,
      mobile2: "Secondary Phone #:",
      secondaryPhone: patientData?.patient?.otherPhone,
      home1: "Home #:",
      home: patientData?.patient?.homePhone,
      office1: "Office #:",
      office: patientData?.patient?.otherPhone,
      address1: "Address #:",
      address: patientData?.patient?.Address1,
      email1: "Email:",
      email: patientData?.patient?.email,
    },
  ];
  const queryClient = useQueryClient();

  const userRole = localStorage.getItem("role");
  const isAuthorized = roleWithPermissions?.[userRole]?.includes("patient");

  return (
    <>
      <CustomModal
        setOpen={setPateintDetailEdit}
        open={pateintDetailEdit}
        title="Edit Patient Details"
        width="auto"
        maxWidth="laptop"
        fullWidth={false}
      >
        <NewPatientForm
          dischageSummaryDetail
          setOpen={setPateintDetailEdit}
          patientData={patientData}
          setValidateCount={setValidateCount}
          validateCount={validateCount}
        />
      </CustomModal>
      {patientDataLoading ? (
        <Loader />
      ) : (
        <Box>
          {hasPermission("patient_view_create_edit") && (
            <Flex justifyContent="flex-end">
              <Button
                text="Edit Patient Details"
                onClick={() => {
                  setPateintDetailEdit(true);
                }}
              />
            </Flex>
          )}
          {infoArray.map((item, index) => {
            return (
              <Grid container spacing={0} key={index}>
                <Grid item mobile={12} tablet={6} laptop={4}>
                  <Flex alignItems="flex-start" justifyContent="flex-start">
                    <Text variant="h3" color="blue">
                      {item.name1}&nbsp;
                    </Text>
                    <Text variant="body1" color="grey">
                      {item.name}
                    </Text>
                  </Flex>
                </Grid>
                <Grid item mobile={12} tablet={6} laptop={4}>
                  <Flex alignItems="flex-start" justifyContent="flex-start">
                    <Text variant="h3" color="blue">
                      {item.mr_no1}&nbsp;
                    </Text>
                    <Text variant="body1" color="grey">
                      {item.mr_no}
                    </Text>
                  </Flex>
                </Grid>
                <Grid item mobile={12} tablet={6} laptop={4}>
                  <Flex alignItems="flex-start" justifyContent="flex-start">
                    <Text variant="h3" color="blue">
                      {item.tcm_id1}&nbsp;
                    </Text>
                    <Text variant="body1" color="grey">
                      {item.uuid}
                    </Text>
                  </Flex>
                </Grid>
                <Grid item mobile={12} tablet={6} laptop={4}>
                  <Flex alignItems="flex-start" justifyContent="flex-start">
                    <Text variant="h3" color="blue">
                      {item.mobile1}&nbsp;
                    </Text>
                    <Text variant="body1" color="grey">
                      {item.mobile}
                    </Text>
                  </Flex>
                </Grid>

                {item.secondaryPhone && (
                  <Grid item mobile={12} tablet={6} laptop={4}>
                    <Flex alignItems="flex-start" justifyContent="flex-start">
                      <Text variant="h3" color="blue">
                        {item.mobile2}&nbsp;
                      </Text>
                      <Text variant="body1" color="grey">
                        {item.secondaryPhone}
                      </Text>
                    </Flex>
                  </Grid>
                )}

                <Grid item mobile={12} tablet={6} laptop={4}>
                  <Flex alignItems="flex-start" justifyContent="flex-start">
                    <Text variant="h3" color="blue">
                      {item.email1}&nbsp;
                    </Text>
                    <Text variant="body1" color="grey">
                      {item.email}
                    </Text>
                  </Flex>
                </Grid>
                <Grid item mobile={12} tablet={6} laptop={4}>
                  <Flex alignItems="flex-start" justifyContent="flex-start">
                    <Text variant="h3" color="blue">
                      {item.address1}&nbsp;
                    </Text>
                    <Text variant="body1" color="grey">
                      {item.address}
                    </Text>
                  </Flex>
                </Grid>
              </Grid>
            );
          })}
        </Box>
      )}

      <Box sx={{ marginTop: "20px" }}>
        <AwaitingEstablishComplexity dischageSummaryDetail />
      </Box>
    </>
  );
};

export default DischargePatient;
