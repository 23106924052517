import { Box, Grid } from "@mui/material";
import request from "api/request";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import InputField from "jsx/Common/FormComponents/InputField";
import CustomRadioCheckbox from "jsx/Common/FormComponents/RadioCheckField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import CustomModal from "jsx/Common/Modal/CustomModal";
import PatientDetail from "jsx/Common/PatientDetail/PatientDetail";
import Text from "jsx/Common/Typography/Text";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import elipses from "utils/elipses";
import { establishComplexitySchema } from "validations/DischargePatient/establishComplexitySchema";
import IcdCodeDesc from "./IcdCodeDesc/IcdCodeDesc";
import NurseTemplate from "../../Listing/NurseComment/NurseTemplate/NurseTemplate";
import Button from "jsx/Common/Button/Button";
import Icon from "jsx/Common/Icons/Icon";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import TableActionButtons from "jsx/Common/Table/TableActionButtons";
import DeleteNurseTemplate from "../../Listing/NurseComment/NurseTemplate/DeleteNurseTemplate";

const EstablishComplexityForm = ({
  validateCount = 0,
  setValidateCount,
  setOpen,
  setSelectedEstablish,
  selectedEstablish,
  pateintDetailSummary,
  dischargeData = {},
}) => {
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [templateModal, setTemplateModal] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [getNurseCommentID, setGetNurseCommentID] = useState("");
  const [updateTitle, setUpdateTitle] = useState("");
  const [updateDescription, setUpdateDescription] = useState("");
  const [countVariable, setCountVariable] = useState(0);
  const [deleteTemplate, setDeleteTemplate] = useState(false);
  const { useAppMutation, useFetch } = useReactQuery();
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const { data: patientData, isLoading: patientDataLoading } = useFetch(
    `/patient/${id}`,
    () =>
      request({
        method: "get",
        url: `/patient/${id ?? selectedEstablish?.patient?.id}`,
      }),
    {
      enabled: !selectedEstablish?.id,
    }
  );

  const { data: nurseTemplateData, isLoading: nurseTemplateDataisLoading } =
    useFetch(["nurseTemplateGet", countVariable], () =>
      request({
        method: "get",
        url: `/nurse-comments-template`,
      })
    );
  const handleCancel = () => {
    setOpen && setOpen(false);
  };

  const { mutate: updateEstablishMutate, isLoading: isUpdateEstablishLoading } =
    useAppMutation(
      (data) =>
        request({
          method: "put",
          url: `/establishComplexities/${dischargeData?.establish_complexities?.id}`,
          data,
        }),
      {
        onSuccess: (data) => {
          // queryClient.invalidateQueries([
          //   `/dischargeSummaryDetail/${dischargeData?.id}`,
          // ]);
          setValidateCount && setValidateCount(validateCount + 1);
          showToast("Establish Complexity Edited Successfully", {
            type: "success",
          });
          // showToast(
          //   data?.alertMessage === ""
          //     ? "Establish Complexity Edited Successfully"
          //     : `${data?.alertMessage ?? 'Establish Complexity cannot be updated!'}`,
          //   {
          //     type: data?.alertMessage === "" ? "success" : "error",
          //   }
          // );
        },
      }
    );

  const { mutate, isLoading } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: "/establishComplexities",
        data,
      }),
    {
      onSuccess: (data) => {
        showToast(
          `Establish Complexity Record Added\n\n${data.alert.hours_24}`,
          {
            type: "success",
          }
        );
        queryClient.invalidateQueries({ queryKey: [`/patient/${id}`] });
        queryClient.invalidateQueries([`establishComplexities/${id}`]);
        queryClient.invalidateQueries(["dischargesummeries"]);
        queryClient.invalidateQueries(["counts"]);
        queryClient.invalidateQueries(["nurseTemplateGet"]);
        formik.resetForm();
        setSelectedEstablish && setSelectedEstablish({});
        handleCancel();
      },
    }
  );
  // let title = nurseTemplateData?.data.find((comment) => comment.id == dischargeData?.establish_complexities?.template_id)?.title

  const formik = useFormik({
    initialValues: {
      discharge_id: dischargeData?.id
        ? dischargeData?.id
        : selectedEstablish?.id ??
          patientData?.patient?.discharge_patient_last?.id,
      discharge_patient_id: dischargeData?.id
        ? dischargeData?.id
        : selectedEstablish?.id ??
          patientData?.patient?.discharge_patient_last?.id,
      patient_id: dischargeData?.id
        ? dischargeData?.patient?.id
        : selectedEstablish?.patient?.id ?? parseInt(id),
      nurseComments: dischargeData?.establish_complexities?.id
        ? // ? "dischargeData?.establish_complexities?.nurseComments"
          dischargeData?.establish_complexities?.nurseComments
        : "",
      assignDateTime: "",
      assignTo: dischargeData?.id
        ? dischargeData?.establish_complexities?.assign_to?.id
        : "",
      dueDateToCheckPatient: dischargeData?.establish_complexities?.id
        ? dischargeData?.establish_complexities?.dueDateToCheckPatient?.split(
            " "
          )[0]
        : "",
      cptCode: dischargeData?.establish_complexities?.id
        ? dischargeData?.establish_complexities?.cptCode
        : "",
      reviewDischargeSummary: dischargeData?.establish_complexities?.id
        ? dischargeData?.establish_complexities?.reviewDischargeSummary === 0
          ? false
          : true
        : false,
      patientComplexity: dischargeData?.establish_complexities?.id
        ? dischargeData?.establish_complexities?.patientComplexity
        : "",
    },
    validationSchema: establishComplexitySchema,
    onSubmit: async (values) => {
      dischargeData?.id
        ? updateEstablishMutate({
            ...values,
            reviewDischargeSummary:
              values.reviewDischargeSummary === true ? 1 : 0,
          })
        : mutate({
            ...values,
            reviewDischargeSummary:
              values.reviewDischargeSummary === true ? 1 : 0,
          });
    },
  });
  const { data: assignToData, isLoading: isAssignData } = useFetch(
    "assigntoCallAgent",
    () =>
      request({
        method: "post",
        data: { name: "call_agent" },
        url: "/assignto",
      })
  );
  const options = [
    { value: "high", label: "High" },
    { value: "moderate", label: "Moderate" },
    { value: "low", label: "Low" },
  ];

  useEffect(() => {
    if (formik.values.patientComplexity == "high") {
      formik.setFieldValue("cptCode", "99496");
    } else if (formik.values.patientComplexity == "moderate") {
      formik.setFieldValue("cptCode", "99495");
    } else if (formik.values.patientComplexity == "low") {
      formik.setFieldValue("cptCode", "");
      formik.setFieldValue("dueDateToCheckPatient", "");

      showToast(
        "The TCM visit will not require for the low complexity patient.",
        {
          icon: "⚠️",
        }
      );
    }
  }, [formik.values.patientComplexity]);

  const openPdf = (url) => {
    window.open(url, "_blank", "height=600,width=800");
  };

  useEffect(() => {
    if (!firstTime && formik.values.patientComplexity == "low") {
      const clearButton = document.querySelector(
        ".Assign_To .MuiAutocomplete-clearIndicator"
      );
      clearButton && clearButton.click();
      formik.setFieldValue("assignTo", "");
    }
  }, [formik.values.patientComplexity]);

  useEffect(() => {
    setFirstTime(false);
  }, []);
  //set the fontSize of the AddNurse Template Icon
  const iconSize = 28;

  const resetNurseTemplate = () => {
    const clearButton = document.querySelector(
      ".nurse_comment .MuiAutocomplete-clearIndicator"
    );
    clearButton && clearButton.click();
    formik.setFieldValue("nurseComments", "");
  };

  return (
    <>
      <CustomModal
        setOpen={setTemplateModal}
        open={templateModal}
        title={"Template Nurse Comments"}
        width="100%"
        maxWidth="tablet"
        fullWidth={true}
      >
        <NurseTemplate
          resetNurseTemplate={resetNurseTemplate}
          setCountVariable={setCountVariable}
          setUpdateTitle={setUpdateTitle}
          setUpdateDescription={setUpdateDescription}
          getNurseCommentID={isEdit ? getNurseCommentID : ""}
          setOpen={setTemplateModal}
        />
      </CustomModal>
      <CustomModal
        setOpen={setDeleteTemplate}
        open={deleteTemplate}
        title={"Delete Template"}
        maxWidth="tablet"
      >
        <DeleteNurseTemplate
          setIsEdit={setIsEdit}
          resetNurseTemplate={resetNurseTemplate}
          setCountVariable={setCountVariable}
          getNurseCommentID={getNurseCommentID}
          setOpen={setDeleteTemplate}
          nurseTemplateData={nurseTemplateData}
        />
      </CustomModal>
      {(selectedEstablish || patientData?.patient) && !pateintDetailSummary ? (
        <PatientDetail
          selectedRecord={selectedEstablish}
          patientRecord={patientData}
        />
      ) : (
        ""
      )}

      {(selectedEstablish || patientData?.patient) && !pateintDetailSummary ? (
        <Grid
          style={{
            border: "1px solid rgba(145,144,144,0.3)",
            borderRadius: "10px",
            padding: "0px 12px",
            marginTop: "10px",
          }}
          container
          spacing={2}
        >
          <Flex justifyContent="flex-start">
            <Text variant="h3" color="gray">
              Discharge Summary Details
            </Text>
          </Flex>
          <Grid item mobile={12} tablet={6} laptop={6}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Assign By: &nbsp;
              </Text>
              <Text variant="body2" color="grey">
                {`${
                  patientData?.patient?.created_by?.firstName ??
                  selectedEstablish?.created_by?.firstName
                } ${
                  patientData?.patient?.created_by?.lastName ??
                  selectedEstablish?.created_by?.lastName
                }`}
              </Text>
            </Flex>
          </Grid>
          <Grid item mobile={12} tablet={6} laptop={6}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                ICD Codes: &nbsp;
              </Text>
              <Text variant="body2" color="grey">
                {selectedEstablish?.discharge_icd_codes?.[0]?.icd_code == null
                  ? "--"
                  : elipses(
                      `${selectedEstablish?.discharge_icd_codes?.map(
                        ({ icd_code }, index) => {
                          return `${icd_code?.icd_code} ${icd_code?.icd_code_description}`;
                        }
                      )}`,
                      20
                    )}
                {selectedEstablish?.discharge_icd_codes?.map(
                  ({ icd_code }, index) => {
                    return (
                      <Text>
                        `${icd_code?.icd_code} ${icd_code?.icd_code_description}
                        `;
                      </Text>
                    );
                  }
                ).length > 2 ? (
                  <Box
                    sx={{ color: "#1C4588", cursor: "pointer" }}
                    onClick={() => {
                      setCommentModal(true);
                    }}
                  >
                    See more
                  </Box>
                ) : (
                  ""
                )}
                <CustomModal
                  setOpen={setCommentModal}
                  open={commentModal}
                  title="ICD Codes"
                  width="auto"
                  maxWidth="tablet"
                >
                  <IcdCodeDesc
                    selectedEstablish={selectedEstablish}
                    patientData={patientData}
                  />
                </CustomModal>
              </Text>
            </Flex>
          </Grid>
          <Grid item mobile={12} tablet={6} laptop={6}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Discharge Provider: &nbsp;
              </Text>
              <Text variant="body2" color="grey">
                {`${
                  patientData?.patient?.discharge_patient_last
                    ?.discharge_provider?.firstName ??
                  selectedEstablish?.discharge_provider?.firstName
                }`}
                &nbsp;
                {`${
                  patientData?.patient?.discharge_patient_last
                    ?.discharge_provider?.lastName ??
                  selectedEstablish?.discharge_provider?.lastName
                }`}
              </Text>
            </Flex>
          </Grid>
          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                <span
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() => setOpenFile(true)}
                >
                  View Discharge
                </span>
              </Text>
            </Flex>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      <CustomModal
        setOpen={setOpenFile}
        open={openFile}
        title="Preview Files"
        width="auto"
        maxWidth="laptop"
        fullWidth={false}
      >
        <div>
          <Text variant="h3">Preview Uploaded Files</Text>
          {selectedEstablish?.attachments?.length
            ? selectedEstablish?.attachments?.map(({ name, url }, index) => {
                return (
                  <div
                    className="preview_file"
                    style={{ cursor: "pointer" }}
                    onClick={() => openPdf(url)}
                  >
                    {index + 1}- {name}
                  </div>
                );
              })
            : ""}
          {!selectedEstablish?.attachments?.length ? (
            <Text variant="body2">No Files</Text>
          ) : (
            ""
          )}
        </div>
      </CustomModal>
      <Grid
        style={{
          border: pateintDetailSummary
            ? "none"
            : "1px solid rgba(145,144,144,0.3)",
          borderRadius: pateintDetailSummary ? "" : "10px",
          padding: "10px 12px",
          marginTop: pateintDetailSummary ? "-50px" : "10px",
        }}
        container
        columnSpacing={2}
      >
        {!pateintDetailSummary ? (
          <Flex justifyContent="flex-start">
            <Text variant="h3" color="gray">
              Add Complexity
            </Text>
          </Flex>
        ) : (
          ""
        )}
        <CustomRadioCheckbox
          checkRadioLabel="I have Reviewed Discharge Summary"
          type="checkbox"
          checked={formik.values.reviewDischargeSummary}
          labelPlacement="end"
          cols="12"
          name="reviewDischargeSummary"
          formik={formik}
        />
        <SelectField
          required
          name="patientComplexity"
          defaultValue={
            dischargeData?.establish_complexities?.id
              ? {
                  label: `${dischargeData?.establish_complexities?.patientComplexity}`,
                  value:
                    dischargeData?.establish_complexities?.patientComplexity,
                }
              : ""
          }
          formik={formik}
          options={options}
          label={"Patient Complexity"}
        />

        <InputField
          name="cptCode"
          type={"number"}
          min={0}
          formik={formik}
          label={"CPT Code"}
          disabled={
            formik.values.patientComplexity == "high"
              ? true
              : formik.values.patientComplexity == "moderate"
              ? true
              : formik.values.patientComplexity == "low"
              ? true
              : false
          }
        />
        <InputField
          name="dueDateToCheckPatient"
          formik={formik}
          label={"Visit Due Date"}
          type={"date"}
          min={
            selectedEstablish?.dischargeDate
              ? new Date(selectedEstablish?.dischargeDate?.split(" ")[0])
                  .toISOString()
                  .split("T")[0]
              : ""
          }
          required={formik.values.patientComplexity == "low" ? false : true}
          disabled={formik.values.patientComplexity == "low" ? true : false}
        />
        {/* <InputField name="assignTo" formik={formik} label={"Assign To"} /> */}
        {/* <InputField label={"Assign To"} /> */}

        <SelectField
          classes="Assign_To"
          options={assignToData?.assignTo?.map(
            ({ id, lastName, firstName }) => {
              return { label: `${firstName} ${lastName}`, value: id };
            }
          )}
          defaultValue={
            dischargeData?.establish_complexities?.id
              ? {
                  label: `${dischargeData?.establish_complexities?.assign_to?.firstName} ${dischargeData?.establish_complexities?.assign_to?.lastName}`,
                  value: dischargeData?.establish_complexities?.assign_to?.id,
                }
              : ""
          }
          name="assignTo"
          formik={formik}
          label={"Select Caller Agent"}
          required={formik.values.patientComplexity == "low" ? false : true}
          disabled={
            formik.values.patientComplexity == "low" && !formik.values.assignTo
              ? true
              : false
          }
        />
        <Box sx={{ width: "100%" }}>
          <Flex justifyContent="flexStart" alignItems="center">
            <Grid
              container
              sx={{
                height: "80px",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Grid item mx={2}>
                <Text variant="body2" marginTop="0px" marginBottom="0px">
                  Nurse Comments <span style={{ color: "red" }}>*</span>
                </Text>
              </Grid>
              <SelectField
                classes="nurse_comment"
                name="template_id"
                cols={4}
                formik={formik}
                defaultValue={
                  dischargeData?.establish_complexities?.nurse_comments_template
                    ? {
                        label: `${dischargeData?.establish_complexities?.nurse_comments_template?.title}`,
                        value:
                          dischargeData?.establish_complexities
                            ?.nurse_comments_template?.description,
                      }
                    : ""
                }
                options={nurseTemplateData?.data?.map(
                  ({ id, title, description }) => ({
                    label: title,
                    description,
                    value: id,
                  })
                )}
                onChange={(e, selectedTemplate) => {
                  formik.setFieldValue(
                    "nurseComments",
                    selectedTemplate?.description || ""
                  );
                  setGetNurseCommentID(selectedTemplate);
                }}
              />
              <Grid item mx={2}>
                <Icon
                  icon={<ControlPointIcon style={{ fontSize: iconSize }} />}
                  color="#1C4588"
                  onClick={() => {
                    setTemplateModal(true);
                    setIsEdit(false);
                  }}
                />
              </Grid>
              <Grid item>
                {formik.values.template_id && (
                  <TableActionButtons
                    showEdit={true}
                    onDelete={() => {
                      setDeleteTemplate(true);
                    }}
                    onEdit={() => {
                      setIsEdit(true);
                      setTemplateModal(true);
                    }}
                    iconWidth="28px"
                    iconHeight="28px"
                  />
                )}
              </Grid>
            </Grid>
          </Flex>
          <Box sx={{ paddingLeft: "16px" }}>
            <InputField
              name="nurseComments"
              formik={formik}
              required={
                formik.values.patientComplexity === "low" ? false : true
              }
              multiline
              rows={4}
              cols={12}
            />
          </Box>
        </Box>
        <Flex>
          <FormButtons
            saveID="saveComplexity"
            cancelID="cancelComplexity"
            isCancel={pateintDetailSummary ? false : true}
            saveLoading={isLoading || isUpdateEstablishLoading}
            text={"Save"}
            onClickSave={formik.submitForm}
            onClickCancel={() => {
              handleCancel();
            }}
          />
        </Flex>
      </Grid>
    </>
  );
};

export default EstablishComplexityForm;
