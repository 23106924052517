import * as Yup from "yup";

const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

const SetNewPasswordValidations = Yup.object().shape({
    new_password: Yup.string()
        .matches(
            passwordRegex,
            "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one digit, and one special character."
        )
        .required("Password is required"),
    password_confirmation: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("new_password")], "Passwords does not match"),
});

export { SetNewPasswordValidations };
