import * as yup from "yup";

export const InitialContacSchema = yup.object().shape({
  establish_complexitie_id: yup.number().required("Complexity ID is required"),
  patient_id: yup.number().required("Patient ID is required"),
  callOutCome: yup.string().required("Call outcome is required"),
  contactMadeTo: yup.string().required("Contact made to is required"),
  additionalNotes: yup.string().required("AdditionalNotes are required"),
  // consentRecorded: yup.string().required("Consent recorded is required"),
  contactMadeTo: yup.string().test({
    name: "Call Reciever Name",
    test: function (value) {
      const { callOutCome } = this.parent;
      if (callOutCome !== "no-response" && !value) {
        throw new yup.ValidationError(
          "consent Made By is required",
          null,
          this.path
        );
      }
      return true;
    },
  }),
  consentRecorded: yup.string().test({
    name: "Call Reciever Name",
    test: function (value) {
      const { callOutCome } = this.parent;
      if (callOutCome !== "no-response" && !value) {
        throw new yup.ValidationError(
          "consent Record is required",
          null,
          this.path
        );
      }
      return true;
    },
  }),

  contactMadeBy: yup.string().test({
    name: "Call Reciever Name",
    test: function (value) {
      const { callOutCome } = this.parent;
      if (callOutCome !== "no-response" && !value) {
        throw new yup.ValidationError(
          "Call Reciever Name is required",
          null,
          this.path
        );
      }
      return true;
    },
  }),
  // additionalNotes: yup.string().test({
  //   name: "additionalNotes",
  //   test: function (value) {
  //     const { callOutCome } = this.parent;
  //     if (callOutCome === "no-response") {
  //       return true;
  //     }

  //     if (callOutCome !== "no-response" && !value) {
  //       throw new yup.ValidationError(
  //         "Additional Notes  is required",
  //         null,
  //         this.path
  //       );
  //     }
  //     return true;
  //   },
  // }),
});
