import * as yup from "yup";
const mrnRegex = /^[a-zA-Z0-9\-\. ]{0,20}$/;
// const insIDRegex = /^[a-zA-Z0-9\-\.]*$/;
const insIDRegex = /^[a-zA-Z0-9\-\. ]*$/;

const dischargeSummarySchema = yup.object().shape({
  mrn: yup
    .string()
    .matches(mrnRegex, "Only use - and . as special chracters")
    .required("Mrn Number is Required"),
  admitDate: yup.date().required("Admit Date is required"),
  dischargeDate: yup.date().required("Discharge Date is required"),
  icd10: yup
    .array()
    .of(yup.number())
    .min(1, "Diagnosis are required")
    .required("Diagnosis are required"),
  // tcmProvider: yup.string().required("TCM Provider is required"),
  // providerFacility: yup.string().required("Provider Facility is required"),
  dischargeProvider: yup.string().required("Discharge Provider is required"),
  hospital_type_id: yup.string().required("Hospital Type ID is required"),
  patient_id: yup.string().required("Patient ID is required"),
  assignTo: yup.string().required("Assign To is required"),
  // assignmentDateTime: yup
  //   .date()
  //   .required("Assignment Date and Time is required"),
  attachments: yup.array().required("Discharge Files are required"),
  hospital_id: yup.string().required("Hospital Name is required"),
  insurance_id: yup.string().required("Insurance Name is required"),
  insuranceNumber: yup
    .string()
    .matches(insIDRegex, "Insurance Id contain - and . only")
    .required("Insurance Name is required"),
});
export default dischargeSummarySchema;
