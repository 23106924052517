import * as yup from "yup";

// export const establishComplexitySchema = yup.object().shape({
//   nurseComments: yup.string().required("Nurse Comments is required"),
//   // assignDateTime: yup.string().required("Assign Date Time is required"),
//   assignTo: yup.number().required("Assign To is required"),
//   dueDateToCheckPatient: yup
//     .string()
//     .required("Due Date To Check Patient is required"),
//   cptCode: yup
//     .number()
//     .min(0, "CPT Code must be greater than or equal to 0")
//     .required("CPT Code is required"),
//   reviewDischargeSummary: yup
//     .boolean()
//     .required("Review Discharge Summary is required")
//     .oneOf([true], "Review Discharge Summary must be mandatory"),
//   patientComplexity: yup
//     .string()
//     .required("Review Discharge Summary is required"),
// });
export const establishComplexitySchema = yup.object().shape({
  nurseComments: yup.string().test({
    name: "required-if-not-low",
    test: function (value) {
      const { patientComplexity } = this.parent;
      if (patientComplexity !== "low" && !value) {
        throw new yup.ValidationError(
          "Nurse Comments is required",
          null,
          this.path
        );
      }
      return true;
    },
  }),
  assignTo: yup.string().test({
    name: "required-if-not-low",
    test: function (value) {
      const { patientComplexity } = this.parent;
      if (patientComplexity !== "low" && !value) {
        throw new yup.ValidationError(
          "Assign To is required",
          null,
          this.path
        );
      }
      return true;
    },
  }),
  dueDateToCheckPatient: yup.string().test({
    name: "required-if-not-low",
    test: function (value) {
      const { patientComplexity } = this.parent;
      if (patientComplexity !== "low" && !value) {
        throw new yup.ValidationError(
          "Due Date To Check Patient is required",
          null,
          this.path
        );
      }
      return true;
    },
  }),
  cptCode: yup.number().test({
    name: "required-if-not-low",
    test: function (value) {
      const { patientComplexity } = this.parent;
      if (patientComplexity !== "low" && !value) {
        throw new yup.ValidationError("CPT Code is required", null, this.path);
      }
      return true;
    },
    min: 0,
    message: "CPT Code must be greater than or equal to 0",
  }),
  reviewDischargeSummary: yup
    .boolean()
    .required("Review Discharge Summary is required")
    .oneOf([true], "Review Discharge Summary must be mandatory"),
  patientComplexity: yup.string().required("Patient Complexity is required"),
});
