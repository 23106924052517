import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/styles";

const Button = ({
  onClick,
  text,
  isLoading = false,
  disabled,
  children,
  color,
  ...props
}) => {
  const theme = useTheme();

  // Check if the color prop is "secondary" and apply the style if true
  const containedSecondaryStyle =
    color === "containedSecondary"
      ? theme.components.MuiLoadingButton.styleOverrides.containedSecondary
      : theme.components.MuiLoadingButton.styleOverrides.root;
  return (
    <LoadingButton
      sx={{
        ...containedSecondaryStyle,
        ...props,
      }}
      onClick={onClick}
      loading={isLoading}
      disabled={disabled || isLoading}
      {...props}
    >
      {text}
    </LoadingButton>
  );
};

export default Button;
