import { Grid } from '@mui/material'
import FormButtons from 'jsx/Common/FormComponents/FormButtons'
import Flex from 'jsx/Common/Layouts/Flex/Flex'
import Text from 'jsx/Common/Typography/Text'
import React from 'react'
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import { useQueryClient } from "react-query";
import { useFormik } from 'formik'
import request from 'api/request'

const DeleteNurseTemplate = ({
    resetNurseTemplate,
    setOpen,
    setCountVariable,
    getNurseCommentID,

}) => {
    const { useAppMutation, useFetch } = useReactQuery();
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const handleCancel = () => {
        setOpen(false)
    }
    const handleDelete = () => {
        const idToDelete = getNurseCommentID;
        mutate(idToDelete);
    };

    const formik = useFormik({
        onSubmit: async (values) => { },
    });

    const { mutate, isLoading } = useAppMutation(
        (getNurseCommentID) => request({
            method: "delete",
            url: `/nurse-comments-template/${getNurseCommentID?.value}`,
        }),
        {
            onSuccess: () => {
                showToast("Template Deleted Successfully", {
                    type: "success",
                });
                setCountVariable((prevCount) => prevCount + 1);
                resetNurseTemplate()
                handleCancel();
            },
        }
    );

    return (
        <>
            <Grid>
                <Flex>
                    <Text variant='h5' >
                        Are you sure you want to delete This Template?
                    </Text>
                </Flex>
                <Flex>
                    <FormButtons
                        save="Delete"
                        cancel="No"
                        saveLoading={isLoading}
                        onClickSave={handleDelete}
                        onClickCancel={handleCancel}
                    />
                </Flex>
            </Grid>
        </>
    )
}

export default DeleteNurseTemplate