import { Grid } from "@mui/material";
import request from "api/request";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import InputField from "jsx/Common/FormComponents/InputField";
import { useQuery, useQueryClient } from "react-query";
import useToast from "hooks/useToast";
import AddNurseTemplateSchema from "validations/NurseTemplate/NurseTemplateSchemas";
import { useState } from "react";

const NurseTemplate = ({
  getNurseCommentID,
  resetNurseTemplate,
  setCountVariable,
  setOpen,
}) => {
  const { useAppMutation, useFetch } = useReactQuery();
  const queryClient = useQueryClient();
  const { showToast } = useToast();
  const { data: nurseTemplateData, isLoading: nurseTemplateDataisLoading } =
    useFetch("nurseTemplateGet", () =>
      request({
        method: "get",
        url: `/nurse-comments-template`,
      })
    );
  const {
    mutate: nurseTemplateMutate,
    isLoading: nurseTemplateMutateIsLoading,
  } = useAppMutation(
    (formData) =>
      request({
        method: "post",
        url: "/nurse-comments-template",
        data: formData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["nurseTemplateGet"]);
      },
    }
  );

  const { mutate: editTemplate, isLoading: editTemplateIsLoading } =
    useAppMutation(
      (formData) =>
        request({
          method: "put",
          url: `/nurse-comments-template/${getNurseCommentID?.value || ""}`,
          data: formData,
        }),
      {
        onSuccess: () => {
          resetNurseTemplate();
          setCountVariable((prevCount) => prevCount + 1);
        },
      }
    );
  const formik = useFormik({
    initialValues: {
      title: getNurseCommentID?.label || "",
      description: getNurseCommentID?.description || "",
      tempate_id: getNurseCommentID?.value || "",
    },
    validationSchema: AddNurseTemplateSchema,
    onSubmit: async (values) => {
      try {
        if (getNurseCommentID?.value) {
          await editTemplate(values);
        } else {
          await nurseTemplateMutate(values);
        }
        formik.resetForm();
        showToast(
          getNurseCommentID?.value
            ? "Template Updated Successfully"
            : "Template Added Successfully",
          {
            type: "success",
          }
        );
        setOpen(false);
      } catch (error) {
        showToast("An error occurred while processing the request", {
          type: "error",
        });
      }
    },
  });

  const handleCancel = () => {
    setOpen && setOpen(false);
  };

  return (
    <Grid>
      <InputField
        label={"Title"}
        cols={12}
        required
        name="title"
        formik={formik}
        onChange={formik.handleChange}
      />
      <InputField
        name="description"
        label={"Description"}
        multiline
        rows={4}
        cols={12}
        required
        formik={formik}
        onChange={formik.handleChange}
      />
      <FormButtons
        saveID="saveComments"
        cancelID="cancelComments"
        onClickSave={formik.handleSubmit}
        onClickCancel={() => {
          handleCancel();
        }}
      />
    </Grid>
  );
};

export default NurseTemplate;
