import React, { useState } from 'react';
import useReactQuery from 'hooks/useReactQuery';
import request from 'api/request';
import GenericTable from 'jsx/Common/Table/GenericTable';
import { checkNullValue } from 'utils/nullCheck';
import moment from 'moment';
import { Box } from '@mui/material';
import { useEffect } from 'react';

const SelectReportTable = () => {
  const [page, setPage] = useState(1)
  const [prevPageIndex, setPrevPageIndex] = useState(null)
  const [data, setData] = useState([])

  const { useFetch, } = useReactQuery();
  const companyId = localStorage.getItem("company_id")

  const { data: reportSummary, isLoading: reportisLoading, } = useFetch(
    [`/dischargePatientDetails/${companyId}`, page],
    () =>
      request({
        method: "get",
        url: `/dischargePatientDetails/${companyId}?page=${page}&limit=20`,
      })
    , { enabled: !!companyId, }

  );

  useEffect(() => {
    if (Array.isArray(data) && Array.isArray(reportSummary?.data)) {
      setData([...data, ...reportSummary?.data]);
    }
  }, [reportSummary?.data]);
  const AdminPanelHeaderData = [
    {
      Header: "Discharge ID",
      accessor: "id"
    },
    {
      Header: "UUID", accessor: (row) => {
        return `${row?.patient?.uuid}`
      },
    },
    {
      Header: "Discharge Provider", accessor: (row) => {
        return `${checkNullValue(row?.discharge_provider?.firstName)} ${checkNullValue(row?.discharge_provider?.lastName)}`
      },
    },
    {
      Header: "Patient Name",
      accessor: (row) => {
        return `${row?.patient?.firstName} ${checkNullValue(row?.patient?.initialName)} ${row?.patient?.lastName}`;
      },
    },
    {
      Header: "Contact",
      accessor: (row) => {
        return `${checkNullValue(row?.patient?.mobilePhone)}`
      },
    },
    {
      Header: "Discharge Date",
      accessor: (row) => {
        const [date, time] = row?.dischargeDate?.split(" ");
        return moment(date).format("MM-DD-YYYY");
      },
    },
    {
      Header: "Initial Contact Status",
      accessor: (row) => {
        const successContacts = row?.successfull_initial_contacts;
        const firstContact = successContacts && successContacts[0];
        return (
          firstContact
            ? firstContact.callOutCome === "success"
              ? moment(firstContact.created_at.split(" ")[0]).format("MM-DD-YYYY")
              : firstContact.callOutCome
            : "N/A"
        );
      },
    },
    {
      Header: "Clinical Assessment Status",
      accessor: (row) => {
        const clinicalExaminations = row?.clinical_examinations;
        const firstStatus = clinicalExaminations && clinicalExaminations[0];
        return (
          firstStatus ? moment(firstStatus.created_at.split("T")[0]).format("MM-DD-YYYY") : "N/A"
        );
      },
    },
    {
      Header: "Feedback Call Status",
      accessor: (row) => {
        const successContacts = row?.feedback_calls;
        const firstContact = successContacts && successContacts[successContacts?.length - 1];
        return (
          firstContact
            ? firstContact.feedback === "success"
              ? moment(firstContact.created_at.split(" ")[0]).format("MM-DD-YYYY")
              : firstContact?.feedback
            : "N/A"
        );
      },
    },
    {
      Header: "Bill Status", accessor: (row) => {
        return row?.tcm_billings?.id ? moment(row?.tcm_billings?.created_at.split("T")[0]).format("MM-DD-YYYY") : "N/A"
      },
    },
    {
      Header: "Reimbursement Status",
      accessor: (row) => {
        return (
          row?.tcm_reimbursements?.id
            ? moment(row?.tcm_reimbursements.updated_at.split("T")[0]).format("MM-DD-YYYY")
            : "N/A"
        );
      },
      className: "last-column",
    },
  ];
  return (
    <Box className="DischargeReport-parent">
      <GenericTable
        recordsPerPage={20}
        prevPage={prevPageIndex}
        setPrevPage={setPrevPageIndex}
        columns={AdminPanelHeaderData}
        data={data}
        pages={page}
        isLoading={reportisLoading}
        totalDataLength={reportSummary?.total_result}
        setPage={setPage}
        totalPages={reportSummary?.total_pages}
      />
    </Box>
  );
}

export default SelectReportTable;
