import { Fade } from "@mui/material";
import { useEffect, useState } from "react";

const EntranceAnimationWrapper = ({ children }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Fade in={isMounted} timeout={500}>
      <div>{children}</div>
    </Fade>
  );
};

export default EntranceAnimationWrapper;
