import { createTheme, responsiveFontSizes } from "@mui/material";

export function createCustomTheme(options = {}) {
  const {
    palette = {
      common: {
        black: "#222222",
        white: "#fff",
      },
      mode: "light",
      primary: {
        main: "#1C4588",
        light: "#4C73B2",
        dark: "#183b73",
        contrastText: "#fff",
      },
      secondary: {
        main: "#5ec4b2",
        light: "rgba(94, 196, 178, 0.7)",
        dark: "#469f90",
        contrastText: "#fff",
      },
      error: {
        main: "#d32f2f",
        light: "#ef5350",
        dark: "#c62828",
        contrastText: "#fff",
      },
      warning: {
        main: "#ff9800",
        light: "rgb(255, 200, 117)",
        dark: "rgb(178, 122, 0)",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },
      info: {
        main: "#2196f3",
        light: "rgb(71, 145, 219)",
        dark: "rgb(17, 82, 147)",
        contrastText: "#fff",
      },
      success: {
        main: "#4caf50",
        light: "rgb(111, 207, 151)",
        dark: "rgb(53, 122, 63)",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },
      text: {
        primary: "#666666",
        secondary: "#222222",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
      },
      background: {
        paper: "#fff",
        default: "#F8FAFB",
      },
      action: {
        active: "rgba(0, 0, 0, 0.54)",
        hover: "rgba(0, 0, 0, 0.08)",
        hoverOpacity: 0.08,
        selected: "rgba(0, 0, 0, 0.14)",
        disabled: "rgba(0, 0, 0, 0.26)",
        disabledBackground: "rgba(0, 0, 0, 0.12)",
        disabledOpacity: 0.38,
        focus: "rgba(0, 0, 0, 0.12)",
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
      },
    },
    typography = {
      fontFamily: "Quicksand, sans-serif",
      h1: {
        fontWeight: 800,
        fontSize: "2.5rem",
        lineHeight: 1,
        color: palette.primary.main,
      },
      h2: {
        fontWeight: 700,
        fontSize: "2rem",
        lineHeight: 1,
        color: palette.primary.main,
      },
      h3: {
        fontWeight: 700,
        fontSize: "1.2rem",
        lineHeight: 1,
        color: palette.text.primary,
      },
      h4: {
        fontWeight: 700,
        fontSize: "1.1rem",
        lineHeight: 1,
        color: palette.primary.main,
      },
      h5: {
        fontWeight: 800,
        fontSize: "1.1rem",
        lineHeight: 1.2,
        color: palette.primary.main,
      },
      h6: {
        fontWeight: 500,
        fontSize: "1rem",
        lineHeight: 1.2,
      },
      subtitle1: {
        fontWeight: 700,
        fontSize: "0.875rem",
        lineHeight: 1.5,
      },
      subtitle2: {
        fontWeight: 700,
        fontSize: "0.5rem",
        lineHeight: 1.5,
        marginTop: "10px",
      },
      body1: {
        fontWeight: 500,
        fontSize: "1rem",
        lineHeight: 1,
        color: palette.text.primary,
      },
      body2: {
        fontWeight: 500,
        fontSize: "0.875rem",
        lineHeight: 1.5,
        color: palette.text.primary,
      },

      button: {
        fontWeight: 500,
        fontSize: "1rem",
        lineHeight: 1,
      },
      caption: {
        fontWeight: 400,
        fontSize: "0.75rem",
        lineHeight: 1.5,
      },
      overline: {
        fontWeight: 500,
        fontSize: "0.875rem",
        lineHeight: 1.5,
      },
    },
    shape = {
      borderRadius: 8,
    },

    breakpoints = {
      values: {
        mobile: 500,
        tablet: 640,
        laptop: 1024,
        desktop: 1200,
      },
    },

    ...other
  } = options;

  let theme = createTheme({
    palette,
    typography,
    shape,
    breakpoints,
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
        },
      },
      MuiLoadingButton: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
            borderRadius: "5px",
            boxShadow: "0pc 3px 6px #00000029",
            height: "40px",
            backgroundColor: palette.primary.main,
            color: palette.common.white,
            marginTop: "15px",
            minWidth: "100px",
            lineClamp: 3,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            "&:hover": {
              backgroundColor: palette.primary.dark,
              color: palette.common.white,
            },
          },
          containedSecondary: {
            textTransform: "capitalize",
            borderRadius: "5px",
            boxShadow: "0pc 3px 6px #00000029",
            height: "40px",
            backgroundColor: palette.secondary.main,
            color: palette.common.white,
            minWidth: "100px",
            "&:hover": {
              backgroundColor: palette.secondary.dark,
              color: palette.common.white,
            },
          },
          loadingIndicator: {
            color: "white",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-root input": {
              border: "none",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px",
              background: "#FAFAFA",
              border: "1px solid #E8E8E8",
              minHeight: "40px",
              "&:hover": {
                border: "1px solid #E8E8E8",
              },
              "&.Mui-focused": {
                border: "1px solid #E8E8E8",
              },
              "& fieldset": {
                borderColor: "#E8E8E8",
              },
              "&:hover fieldset": {
                borderColor: "#E8E8E8",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#E8E8E8",
              },
              "& .MuiOutlinedInput-input": {
                height: "0.4375em",
              },
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              padding: "0px 9px",
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            margin: "0px",
            justifyContent: "flex-start",
          },
          labelPlacementTop: {
            alignItems: "flex-start",
          },
          labelPlacementBottom: {
            alignItems: "flex-start",
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: "6px 0px",
            color: palette.primary.main,
            "&:hover": {
              background: "none",
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            padding: "6px 0px",
            color: palette.primary.main,
            width: "16.82px",
            height: "16.82px",
            marginRight: "10px",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            position: "sticky",
            top: -1,
            zIndex: 1,
            backgroundColor: "#f3f5f7",
            borderTop: "0px ",
          },
          "& .MuiTableCell-head": {
            color: "#1C4588 !important",
            fontWeight: "bold",
            minWidth: "100px",
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            "& .MuiBox-root .MuiTableCell-root.MuiTableCell-sizeMedium.MuiTablePagination-root":
              {
                borderRadius: "5px",
              },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            borderRadius: "5px",
          },
        },
      },
      MuiIcon: {
        styleOverrides: {
          root: {
            boxSizing: "content-box",
            padding: 3,
            fontSize: "1.125rem",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            "& .MuiDialogTitle-root": {
              background: palette.primary.main,
              color: palette.common.white,
            },
            "& .MuiDialog-container .dialogCoseButton": {
              background: palette.common.white,
              color: palette.common.black,
              width: "40px !important",
              height: "40px !important",
              minWidth: "45px",
              minHeight: "45px",
              borderRadius: "100px ",
              fontWeight: "700",
              position: "absolute",
              top: "0",
              right: "0",
              "&:hover": {
                background: palette.common.white,
                color: palette.common.black,
              },
            },
          },
        },
      },
    },
    ...other,
  });

  theme = responsiveFontSizes(theme);

  return theme;
}
