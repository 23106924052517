import { Grid } from "@mui/material";
import InputField from "jsx/Common/FormComponents/InputField";
import { memo, useEffect, useState } from "react";

const EmployerForm = ({ formik }) => {
  const [contact, setContact] = useState("");

  useEffect(() => {
    setContact(formik.values.employerContactNo);
  }, []);

  useEffect(() => {
    formik.setFieldValue("employerContactNo", contact);
  }, [contact]);

  return (
    <Grid container spacing={1} columnSpacing={1}>
      <InputField
        formik={formik}
        label={"Employer Name"}
        cols={6}
        name="employerName"
      />

      <InputField
        label={"Contact No. "}
        cols={6}
        isPhone
        value={contact}
        onChange={(e) => setContact(e.target.value)}
      />
      <InputField formik={formik} label={"Address 1"} name="employerAddress1" />
      <InputField formik={formik} label={"Address 2"} name="employerAddress2" />
      <InputField formik={formik} label={"City"} cols={4} name="employerCity" />
      <InputField
        formik={formik}
        label={"Zip"}
        cols={4}
        name="employerZipCode"
      />
      <InputField
        formik={formik}
        label={"State"}
        cols={4}
        name="employerState"
      />
    </Grid>
  );
};
export default memo(EmployerForm);
