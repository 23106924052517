import { Grid } from "@mui/material";
import { HPIContactMethodOptions } from "assets/static/ccmStaticData";
import InputField from "jsx/Common/FormComponents/InputField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import { memo, useEffect, useState } from "react";

const ContactForm = ({ formik }) => {
  const [contact, setContact] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  useEffect(() => {
    formik.setFieldValue("emergencyContact", contact);
  }, [contact]);

  useEffect(() => {
    setContact(formik.values.emergencyContact);
  }, []);
  return (
    <Grid container spacing={1} columnSpacing={1}>
      <SelectField
        formik={formik}
        label={"HPI Contact Method"}
        name="hpiContactMethod"
        cols={4}
        options={HPIContactMethodOptions}
        placeholder="select..."
        defaultValue={{
          label: formik.values.hpiContactMethod
            ? HPIContactMethodOptions.find(
                (method) => method.value === formik.values.hpiContactMethod
              )?.label
            : "",
          value: formik.values.hpiContactMethod
            ? HPIContactMethodOptions.find(
                (method) => method.value === formik.values.hpiContactMethod
              )?.value
            : "",
        }}
      />

      <InputField
        formik={formik}
        label={"Referring Provider"}
        name="referringProvider"
        cols={4}
      />
      <InputField formik={formik} label={"NPI"} name="npi" cols={4} />
      <InputField
        errorMessage="Emergency Contact is required"
        showError={!contact && inputFocused ? true : false}
        label="Emergency Contact"
        isPhone
        onBlur={(e) => setInputFocused(true)}
        value={contact}
        onChange={(e) => setContact(e.target.value)}
        cols={4}
      />
    </Grid>
  );
};

export default memo(ContactForm);
