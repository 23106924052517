import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import useToast from "./useToast";

const useReactQuery = () => {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("company_name");
    localStorage.clear();
    queryClient.clear();
    navigate("/");
    showToast("Logged out due to session expire", {
      type: "error",
    });
  }

  const globalQueryOptions = {
    // staleTime: 300000, // 5 minutes
    refetchOnWindowFocus: false,
    retry: 1,
    onError: (error) => {
      if (error?.response?.status === 401) {
        // Handle 401 Unauthenticated error here, e.g., trigger a logout action
        handleLogout(); // Replace with your actual logout function
      } else {
        showToast(error?.response?.data?.message || "Network Error!", {
          type: "error",
        });
      }
    },
  };

  const useFetch = (queryKey, queryFn, options) => {
    return useQuery(queryKey, queryFn, { ...globalQueryOptions, ...options });
  };

  const useAppMutation = (mutationFn, options) => {
    const mutation = useMutation(mutationFn, {
      ...globalQueryOptions,
      ...options,
    });
    const { mutate } = mutation;

    mutation.mutate = async (variables, ...rest) => {
      try {
        await mutate(variables, ...rest);
      } catch (error) {
        if (error?.response?.status === 401) {
          // Handle 401 Unauthenticated error here, e.g., trigger a logout action
          handleLogout(); // Replace with your actual logout function
        } else {
          showToast(error || "Network Error!", {
            type: "error",
          });
          throw error?.message ?? error;
        }
      }
    };

    return mutation;
  };

  return { useFetch, useAppMutation };
};

export default useReactQuery;
