import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  LinearProgress,
} from "@mui/material";
import Text from "jsx/Common/Typography/Text";
import { useEffect, useState } from "react";
import AwaitingReadyForBillingForm from "./AwaitingReadyForBillingForm";
import AwaitingReadyForReimbursementForm from "./AwaitingReadyForReimbursementForm";
import ClinicalExaminationForm from "./ClinicalExaminationForm";
import EstablishComplexityForm from "./EstablishComplexityForm";
import FeedbackCallForm from "./FeedbackCallForm";
import InitialContactForm from "./InitialContactForm";
import NewDischargePatientForm from "./NewDischargePatientForm";
import useFilteredPermissions from "hooks/useFilteredPermissions";

const PatientDetailForm = ({
  setValidateCount,
  validateCount,
  setOpenModal,
  dischargeData = {},
}) => {
  const { hasPermission } = useFilteredPermissions();

  const [dischargeDetailData, setDischargeDetailData] = useState(
    dischargeData ?? {}
  );

  const handleCancel = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setDischargeDetailData(dischargeData);
  }, [dischargeData]);

  const patientDetailFormArray = [
    {
      accordionheading: "Add Discharge Summary",
      hasPermission: hasPermission("dischargeSummary_view_create_edit"),
      isDisabled: false,
      component: (
        <NewDischargePatientForm
          setValidateCount={setValidateCount}
          validateCount={validateCount}
          isIncluded={true}
          pateintDetailSummary={true}
          dischargeData={dischargeDetailData}
        />
      ),
    },
    {
      accordionheading: "Establish Complexity",
      hasPermission: hasPermission("complexity_view_create_edit"),
      isDisabled: !!dischargeDetailData?.establish_complexities?.id
        ? false
        : true,
      component: (
        <EstablishComplexityForm
          setValidateCount={setValidateCount}
          validateCount={validateCount}
          isIncluded={true}
          pateintDetailSummary={true}
          dischargeData={dischargeDetailData}
        />
      ),
    },
    {
      accordionheading: "Initial Contact And Appointment",
      hasPermission: hasPermission("initialContact_view_create_edit"),
      isDisabled: !!dischargeDetailData?.successfull_initial_contacts?.length
        ? false
        : true,
      component: (
        <InitialContactForm
          setValidateCount={setValidateCount}
          validateCount={validateCount}
          isIncluded={true}
          pateintDetailSummary={true}
          dischargeData={dischargeDetailData}
        />
      ),
    },
    {
      accordionheading: "Clinical Examination",
      hasPermission: hasPermission("clinical_view_create_edit"),
      isDisabled: !!dischargeDetailData?.clinical_examinations?.length
        ? false
        : true,
      component: (
        <ClinicalExaminationForm
          setValidateCount={setValidateCount}
          validateCount={validateCount}
          pateintDetailSummary={true}
          dischargeData={dischargeDetailData}
        />
      ),
    },
    {
      accordionheading: "Feedback Call",
      hasPermission: hasPermission("feedback_view_create_edit"),
      isDisabled: !!dischargeDetailData?.feedback_calls?.length ? false : true,
      component: (
        <FeedbackCallForm
          setValidateCount={setValidateCount}
          validateCount={validateCount}
          pateintDetailSummary={true}
          dischargeData={dischargeDetailData}
        />
      ),
    },
    {
      accordionheading: "TCM Billing",
      hasPermission: hasPermission("tcmBilling_view_create_edit"),
      isDisabled: !!dischargeDetailData?.tcm_billings?.id ? false : true,
      component: (
        <AwaitingReadyForBillingForm
          setValidateCount={setValidateCount}
          validateCount={validateCount}
          pateintDetailSummary={true}
          dischargeData={dischargeDetailData}
        />
      ),
    },
    {
      accordionheading: "TCM Reimbursement",
      hasPermission: hasPermission("tcmReimbursement_view_create_edit"),
      isDisabled: !!dischargeDetailData?.tcm_reimbursements?.id ? false : true,
      component: (
        <AwaitingReadyForReimbursementForm
          setValidateCount={setValidateCount}
          validateCount={validateCount}
          pateintDetailSummary={true}
          dischargeData={dischargeDetailData}
        />
      ),
    },
  ];

  return (
    <Box>
      <Grid item mobile={12} my={1} className="addAppointmentAccordion">
        {dischargeDetailData?.id ? (
          patientDetailFormArray.map((item, index) => {
            return (
              <Box sx={{ marginBottom: "20px" }} key={index}>
                <Accordion
                  disabled={
                    item?.isDisabled || !item?.hasPermission ? true : false
                  }
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ display: "flex !important", alignItems: "center" }}
                  >
                    <Text variant="h3">{item.accordionheading}</Text>
                    {item?.isDisabled && (
                      <span
                        style={{
                          height: "18px",
                          marginTop: "16px",
                          color: "white",
                          marginLeft: "10px",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "12px",
                          padding: "6px 8px",
                          lineHeight: 0.6,
                          background: "red",
                          borderRadius: "14px",
                        }}
                      >
                        not initiated
                      </span>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>{item.component}</AccordionDetails>
                </Accordion>
              </Box>
            );
          })
        ) : (
          <LinearProgress />
        )}
      </Grid>
    </Box>
  );
};

export default PatientDetailForm;
