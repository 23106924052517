import { Box, Container, Grid } from '@mui/material';
import { selectReportsData } from 'assets/static/options';
import SelectField from 'jsx/Common/FormComponents/SelectField';
import Flex from 'jsx/Common/Layouts/Flex/Flex';
import Text from 'jsx/Common/Typography/Text';
import React, { useState } from 'react';
import SelectReportTable from './SelectReportTable';

const ReportList = () => {
    const [title, setTitle] = useState(selectReportsData[0].label);

    const handleChange = (e) => {
        setTitle(e.target.textContent);
    }

    return (
        <>
            <Container>
                <Grid container>
                    <Grid item desktop={12} laptop={12} tablet={12} mobile={12}>
                        <Text variant='h2'>
                            Discharge Summary Report
                        </Text>
                    </Grid>
                </Grid>
                <SelectReportTable />
            </Container>
        </>
    )
}

export default ReportList;
