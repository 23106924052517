import React, { useEffect, useState } from "react";
import Text from "jsx/Common/Typography/Text";
import InputField from "jsx/Common/FormComponents/InputField";
import { Box, Grid } from "@mui/material";
import SelectField from "jsx/Common/FormComponents/SelectField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import Button from "jsx/Common/Button/Button";
import {
  Medication1Table,
  Medication1Tablecolumns,
  Medication2Table,
  Medication2Tablecolumns,
  initialCallsDataArray,
} from "assets/static/ccmStaticData";
import { designCarePlanOptions, genders } from "assets/static/options";
import { useFormik } from "formik";
import { DesignCarePlanSchema } from "../Validations/DesignCarePlan/DesignCarePlanSchema";
import CustomTable from "jsx/Common/Table/CustomTable";
import Wrapper from "jsx/Common/Wrapper";
import CustomModal from "jsx/Common/Modal/CustomModal";
import LabTestModal from "../DesignCarePlanApproval/DesignCarePlanApprovalModal/LabTestModal";
import AddMedicationsModal from "../DesignCarePlanApproval/DesignCarePlanApprovalModal/AddMedicationsModal";

const DesignCarePlan = () => {
  const [open, setOpen] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const formik = useFormik({
    initialValues: {
      patientName: "",
      dateOfDiagnosis: "",
      birthDate: "",
      gender: "",
      contactNumber: "",
      insurance: "",
      insuranceNo: "",
      patientCurrentCondition: "",
      currentTreatmentTherapy: "",
      chronic1: "",
      chronic2: "",
      chronic3: "",
      subType1: "",
      subType2: "",
      subType3: "",
      carePlanGoals: "",
      medicalManagement: "",
      careCoordination: "",
      psychosocialSupport: "",
      educationAndLifestyleModifications: "",
      followUpAndMonitoring: "",
      carePlanEvaluation: "",
    },
    validationSchema: DesignCarePlanSchema,
    onSubmit: async (values) => {
      formik.resetForm();
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  return (
    <>
      <CustomModal
        setOpen={setOpen}
        open={open}
        title={"Add Lab Test"}
        maxWidth="tablet"
        fullWidth={true}
      >
        <LabTestModal />
      </CustomModal>

      <CustomModal
        setOpen={setModalOpen}
        open={ModalOpen}
        title={"Add Medications"}
        maxWidth="tablet"
        fullWidth={true}
      >
        <AddMedicationsModal />
      </CustomModal>
        <Box>
          <Grid container spacing={2}>
            <InputField
              label={"Patient Name :"}
              cols={3}
              name="patientName"
              formik={formik}
            />
            <InputField
              label={"DoB :"}
              type="date"
              cols={3}
              name="birthDate"
              formik={formik}
            />
            <InputField
              label={"Gender :"}
              name="gender"
              options={genders}
              cols={3}
              formik={formik}
            />
            <InputField
              label={"Contact Number :"}
              cols={3}
              name="contactNumber"
              formik={formik}
            />
            <InputField
              label={"Date of Diagnosis :"}
              cols={3}
              type="date"
              name="dateOfDiagnosis"
              formik={formik}
            />
            <InputField
              label={"Insurance # :"}
              cols={3}
              name="insuranceNo"
              formik={formik}
            />
            <InputField
              label={"Insurance :"}
              cols={3}
              name="insurance"
              formik={formik}
            />

            <Grid item desktop={4} laptop={3} tablet={6} mobile={12}>
              <Box
                sx={{
                  backgroundColor: "#F3F3F3",
                  borderRadius: "10px",
                  p: 2,
                }}
              >
                <Grid container>
                  <InputField
                    cols={12}
                    multiline
                    rows={5}
                    label={"Patient Current Condition :"}
                    name="patientCurrentCondition"
                    formik={formik}
                  />
                  <Flex
                    direction="column"
                    justifyContent="start"
                    alignItems="start"
                  >
                    <Text my={1} variant="body2">
                      Medications :
                    </Text>
                    <Box sx={{ width: "100%" }} className="designCareTable">
                      <CustomTable
                        data={Medication1Table}
                        columns={Medication1Tablecolumns}
                      />
                    </Box>
                  </Flex>
                  <InputField
                    cols={12}
                    multiline
                    rows={5}
                    label={"Current Treatment/Therapy :"}
                    name="currentTreatmentTherapy"
                    formik={formik}
                  />
                  <Flex
                    direction="column"
                    justifyContent="start"
                    alignItems="start"
                  >
                    <Text my={1} variant="body2">
                      Medications # :
                    </Text>
                    <Box
                      sx={{ width: "100%" }}
                      className="designCareTable second"
                    >
                      <CustomTable
                        data={Medication2Table}
                        columns={Medication2Tablecolumns}
                      />
                    </Box>
                  </Flex>
                </Grid>
              </Box>
              <Flex justifyContent="space-between">
                <Button
                  onClick={handleOpen}
                  sx={{
                    width: "48%",
                    backgroundColor: "#fff",
                    color: "#42A7C3",
                  }}
                  text={"Lab Test"}
                />
                <Button
                  onClick={handleModalOpen}
                  sx={{
                    width: "48%",
                    backgroundColor: "#fff",
                    color: "#42A7C3",
                  }}
                  text={"Medication"}
                />
              </Flex>

              <Button
                sx={{ width: "100%", backgroundColor: "#4fa571" }}
                text={"Save and Send for Approval"}
                onClick={formik.submitForm}
              />
            </Grid>

            <Grid item desktop={8} laptop={8} tablet={6} mobile={12}>
              <Box
                sx={{
                  backgroundColor: "#F3F3F3",
                  p: 2,
                  borderRadius: "10px",
                }}
              >
                <Grid container spacing={2}>
                  <SelectField
                    label={"Chronic 1 :"}
                    options={designCarePlanOptions}
                    cols={4}
                    name="chronic1"
                    required
                    formik={formik}
                  />
                  <SelectField
                    label={"Chronic 2 :"}
                    options={designCarePlanOptions}
                    cols={4}
                    required
                    name="chronic2"
                    formik={formik}
                  />
                  <SelectField
                    label={"Chronic 3 :"}
                    options={designCarePlanOptions}
                    cols={4}
                    required
                    name="chronic3"
                    formik={formik}
                  />
                  <SelectField
                    label={"Subtype :"}
                    options={designCarePlanOptions}
                    cols={4}
                    name="subType1"
                    formik={formik}
                  />
                  <SelectField
                    label={"Subtype :"}
                    options={designCarePlanOptions}
                    cols={4}
                    name="subType2"
                    formik={formik}
                  />
                  <SelectField
                    label={"Subtype :"}
                    options={designCarePlanOptions}
                    cols={4}
                    name="subType3"
                    formik={formik}
                  />
                </Grid>
                <Grid container spacing={2}>
                  {initialCallsDataArray?.map(({ label, name }) => {
                    return (
                      <InputField
                        cols={6}
                        multiline
                        rows={3}
                        label={label}
                        name={name}
                        formik={formik}
                      />
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
    </>
  );
};
export default DesignCarePlan;
