import GenericTable from "jsx/Common/Table/GenericTable";
import Button from "jsx/Common/Button/Button";
import React, { memo, useState } from "react";
import CustomModal from "jsx/Common/Modal/CustomModal";
import AddDiagnosisForm from "../AddDiagnosisForm";
import { Box } from "@mui/material";
const DiagnosisData = () => {
  const [open, setOpen] = useState(false);
  const columns = [
    { Header: "Description", accessor: "description" },
    { Header: "ICD-10", accessor: "icd" },
    { Header: "Onsite Date", accessor: "onsiteDate" },
  ];
  const TableData = [
    {
      description: "Infant botulism",
      icd: "A4581",
      onsiteDate: "06-10-2023",
    },
    {
      description: "Cancer",
      icd: "36318",
      onsiteDate: "17-07-2021",
    },
  ];
  return (
    <Box sx={{ position: "relative", top: "60px" }}>
      <CustomModal
        maxWidth="tablet"
        title={"Add Patient Diagnosis"}
        setOpen={setOpen}
        open={open}
      >
        <AddDiagnosisForm />
      </CustomModal>
      <Box sx={{ position: "absolute", right: "0px", top: "-75px" }}>
        <Button
          text={"Add Diagnosis"}
          onClick={() => {
            setOpen(true);
          }}
        />
      </Box>
      <GenericTable columns={columns} data={TableData} />
    </Box>
  );
};
export default memo(DiagnosisData);
