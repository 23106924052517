import React from "react";
import { useTable } from "react-table";
import Text from "../Typography/Text";

const CustomTable = ({ data, columns, thStyle }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const defaultThStyle = {
    color: "#666666",
    fontSize: "12px",
    fontWeight: 400,
  };

  return (
    <>
      <div className="designCareTable second">
        <table {...getTableProps()} style={{ width: "100%" }}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      color: thStyle?.color || defaultThStyle.color,
                      fontWeight:
                        thStyle?.fontWeight || defaultThStyle.fontWeight,
                      fontSize: thStyle?.fontSize || defaultThStyle.fontSize,
                    }}
                  >
                    <Text
                      my={0}
                      color={thStyle?.color}
                      fontSize={thStyle?.fontSize}
                      variant="body2"
                      fontWeight={thStyle?.fontWeight}
                    >
                      {column.render("Header")}
                    </Text>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      style={{ textAlign: "center" }}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CustomTable;
