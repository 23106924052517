// import React from "react";
// import request from "api/request";
// import useReactQuery from "./useReactQuery";

import { useEffect, useState } from "react";

const useFilteredPermissions = () => {
  // const role = localStorage.getItem("role");
  // const companyID = localStorage.getItem("company_id");
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  // const { useFetch } = useReactQuery();
  // const { data: permissionsData, isLoading: rolesIsLoading } = useFetch(
  //   `/company/${companyID}/rol-with-per/`,
  //   () =>
  //     request({
  //       method: "get",
  //       url: `company/${companyID}/roles-permissions`,
  //     }),
  //   {
  //     enabled: !!companyID,
  //   }
  // );

  // const hasPermission = (permission1) =>
  //   permissionsData
  //     ?.find((item) => item?.role === role)
  //     ?.permissions?.some((permission) => permission.startsWith(permission1)) ||
  //   false;

  const hasPermission = (permission1) =>
    permissions?.some((permission) => permission.startsWith(permission1)) ||
    false;

  return {
    // rolesIsLoading,
    hasPermission,
  };
};

export default useFilteredPermissions;
