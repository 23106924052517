import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, TextField } from "@mui/material";
import request from "api/request";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import CustomModal from "../Modal/CustomModal";
import Text from "../Typography/Text";
import FormButtons from "./FormButtons";

const GlobalSearch = ({
  setOpenDisharge,
  placeholder,
  isAddPatient = false,
  setSelectedDischarge,
  isNavigate = true,
  width = "100%",
}) => {
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const { useFetch } = useReactQuery();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  const { data, isLoading } = useQuery(
    ["search", inputValue],
    () =>
      request({
        method: "get",
        url: `/search?query=${inputValue}`,
      }),
    {
      onSuccess: (data) => {
        setOptions(
          data?.map(({ id, firstName, initialName, lastName }) => {
            return {
              label: `${firstName} ${initialName} ${lastName}`,
              value: id,
            };
          })
        );
      },
      enabled: Boolean(inputValue),
      debounce: 500,
    }
  );

  const { data: patientData, isLoading: patientDataLoading } = useFetch(
    `/patient/${selectedOption?.value}`,
    () =>
      request({
        method: "get",
        url: `/patient/${selectedOption?.value}`,
      }),
    { enabled: selectedOption?.value !== undefined && showModal }
  );
  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const handleChange = (event, value) => {
    if (isAddPatient) {
      if (value) {
        setShowModal(true);
        setSelectedOption(value);
      }
    } else {
      if (value) {
        if (isNavigate) {
          setSelectedOption(value);
          navigate(`/patient/${value?.value}`);
        }
      } else {
        setSelectedOption(null);
      }
    }
  };

  const handleAddPatient = () => {
    if (setSelectedDischarge && patientData) {
      setSelectedDischarge(patientData?.patient);
      setOpenDisharge && setOpenDisharge(true);
      setShowModal(false);
    }
  };

  return (
    <>
      <CustomModal
        setOpen={setShowModal}
        open={showModal}
        title={"Add Patient To Discharge Summary"}
        width="auto"
        maxWidth="tablet"
      >
        <Text variant="h4" color={"gray"}>
          Do you want to add another dicharge summary -{" "}
          <span style={{ color: "#1C4588" }}>{selectedOption?.label}</span> ?
        </Text>
        <FormButtons
          save="Yes"
          cancel="No"
          isCancel={true}
          onClickSave={() => {
            handleAddPatient();
          }}
          saveLoading={patientDataLoading}
          onClickCancel={() => {
            setShowModal(false);
            // setSelectedOption({})
          }}
        />
      </CustomModal>
      <Autocomplete
        openOnFocus
        onInputChange={handleInputChange}
        options={options}
        loading={isLoading}
        sx={{ width: width }}
        filterOptions={(options, { inputValue }) =>
          options.filter((option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
          )
        }
        value={selectedOption}
        isOptionEqualToValue={(option, value) => option.value === value?.value}
        onChange={(e, v) => handleChange(e, v)}
        getOptionLabel={(option) => option.label || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon />,
            }}
          />
        )}
      />
    </>
  );
};

export default GlobalSearch;
