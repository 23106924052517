import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import request from "api/request";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import IcdsSearch from "jsx/Common/FormComponents/IcdsSearch";
import InputField from "jsx/Common/FormComponents/InputField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import FileUploader from "jsx/Common/FormComponents/fileUpload";
import Icon from "jsx/Common/Icons/Icon";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import CustomModal from "jsx/Common/Modal/CustomModal";
import Text from "jsx/Common/Typography/Text";
import DashboardTooltip from "jsx/Screens/Dashboard/Components/NewDashBoardScreen/TooltipComponent/DashboardTooltip";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import dischargeSummarySchema from "validations/DischargePatient/dischargeSummarySchema";
import AddInsuranceCompanyForm from "../../Form/AddInsuranceCompanyForm";
import DischargeFacilityModal from "./DischargeProviderModal/DischargeFacilityModal";
import DischargeProviderModal from "./DischargeProviderModal/DischargeProviderModal";
import AddIcdForm from "./IcdCodeDesc/addNewIcds";
import { isValueTruthy } from "utils/isValueTruthy";

const useStyles = makeStyles(() => ({
  btnRoot: {
    width: "100%",
    maxWidth: "100% !important",
    border: "1px solid #e5dddd !important",
    height: "40px",
    borderRadius: "5px",
    color: "#808080 !important",
    marginTop: "0px !important",
    boxShadow: "none !important",
    background:
      "transparent linear-gradient(180deg, #FDFDFD 0%, #E4E4E6 100%) 0% 0% no-repeat padding-box",
    "&:hover": {
      boxShadow:
        "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px !important",
    },
  },
}));

const NewDischargePatientForm = ({
  validateCount = 0,
  setValidateCount,
  setOpen,
  setSelectedDischarge,
  selectedDischarge,
  tooltip,
  pateintDetailSummary = false,
  dischargeData = {},
}) => {
  const currentDate = moment(new Date()).format("YYYY-MM-DD"); // Get the current date in YYYY-MM-DD format
  const classes = useStyles();
  const [uploadedFileData, setUploadedFileData] = useState([]);
  const [deletedFileData, setDeletedFileData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openIsuranceModal, setOpenIsuranceModal] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [openDischargeModal, setOpenDischargeModal] = useState(false);
  const [openDischargeFacilityModal, setOpenDischargeFacilityModal] =
    useState(false);
  const [files, setFiles] = useState(
    dischargeData?.id ? dischargeData?.attachments : []
  );
  const [isRequired, setIsRequired] = useState(false);
  const [isImageLoading, setIsImageloading] = useState(false);

  const { id } = useParams();

  const { useFetch } = useReactQuery();

  const handleFiles = (files) => {
    setFiles(files);
  };

  const handleCancel = () => {
    setOpen && setOpen(false);
  };

  const { useAppMutation } = useReactQuery();

  const { showToast } = useToast();

  const queryClient = useQueryClient();

  const { data: patientData, isLoading: patientDataLoading } = useFetch(
    `/patient/${id}`,
    () =>
      request({
        method: "get",
        url: `/patient/${id ?? selectedDischarge?.patient?.id}`,
      }),
    {
      enabled: !selectedDischarge?.id,
    }
  );

  const { data: insurancesData } = useFetch("insurances", () =>
    request({
      method: "get",
      url: "/insurances",
    })
  );

  //upload-attachments
  const { mutate: UploadAPI, isLoading: isUploadAPI } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: "/upload-attachments",
        data,
      }),
    {
      onSuccess: (data) => {
        setUploadedFileData((old) => [...old, ...data?.data]);
        showToast("Files Uploaded Successfully", {
          type: "success",
        });
        dischargeData?.id
          ? updateDischargeMutate({
              ...formik.values,
              deleteAttachments: [...deletedFileData],
              attachments: [
                ...dischargeData?.attachments
                  ?.filter(({ id }) => !deletedFileData?.includes(id))
                  ?.map(({ id }) => id),
                ...data?.data?.map(({ id }) => id),
              ],
              // attachments: [
              //   ...formik.values.attachments,
              //   ...data?.data?.map(({ id }) => id),
              // ],
            })
          : mutate({
              ...formik.values,
              attachments: [
                ...formik.values.attachments,
                ...data?.data?.map(({ id }) => id),
              ],
            });
      },
    }
  );

  const handleImage = () => {
    let formData = new FormData();
    let filteredFiles = files?.filter((item) => !item?.hasOwnProperty("pivot"));
    filteredFiles?.map(({ file }, index) => {
      formData.append(`dischargeSummary[${index}]`, file);
    });
    filteredFiles?.length && UploadAPI(formData);
    !filteredFiles?.length &&
      updateDischargeMutate({
        ...formik.values,
        deleteAttachments: [...deletedFileData],
        attachments: [...dischargeData?.attachments?.map(({ id }) => id)],
      });
  };

  const { mutate, isLoading } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: "/dischargesummeries",
        data,
      }),
    {
      onSuccess: (data) => {
        setIsImageloading(false);
        showToast("Patient Discharge Summary Added Successfully", {
          type: "success",
        });
        queryClient.invalidateQueries([`dischargesummeries${`/${id}` ?? ""}`]);
        queryClient.invalidateQueries([`/patient/${id}`]);
        queryClient.invalidateQueries([`patients`]);
        queryClient.invalidateQueries(["dischargesummeries"]);
        queryClient.invalidateQueries(["counts"]);

        setSelectedDischarge && setSelectedDischarge({});

        handleCancel();

        formik.resetForm();
      },
    }
  );
  const { mutate: updateDischargeMutate, isLoading: isUpdateDischargeLoading } =
    useAppMutation(
      (data) =>
        request({
          method: "put",
          url: `dischargesummeries/${dischargeData?.id}`,
          data,
        }),
      {
        onSuccess: (data) => {
          setIsImageloading(false);
          showToast(
            data?.alertMessage === ""
              ? "Patient Discharge Summary Edited Successfully"
              : `${data?.alertMessage}`,
            {
              type: data?.alertMessage === "" ? "success" : "error",
            }
          );
          setValidateCount && setValidateCount(validateCount + 1);
          // queryClient.invalidateQueries(
          //   `/dischargeSummaryDetail/${dischargeData?.id}`
          // );
        },
      }
    );

  useEffect(() => {
    formik.setFieldValue(
      "attachments",
      uploadedFileData.map(({ id }) => id)
    );
  }, [uploadedFileData]);

  const formik = useFormik({
    initialValues: {
      mrn: dischargeData?.id ? dischargeData?.mrn : "",
      admitDate: dischargeData?.id
        ? dischargeData?.admitDate?.split(" ")[0]
        : "",
      dischargeDate: dischargeData?.id
        ? dischargeData?.dischargeDate?.split(" ")[0]
        : "",
      icd10: dischargeData?.id
        ? dischargeData?.discharge_icd_codes?.map(
            ({ icd_code }) => icd_code?.icd_code_id
          )
        : [],
      tcmProvider: "",
      providerFacility: "",
      dischargeProvider: dischargeData?.id
        ? dischargeData?.dischargeProvider
        : "",
      hospital_type_id: dischargeData?.id
        ? dischargeData?.hospital_type_id
        : "",
      assignTo: dischargeData?.id ? dischargeData?.assignTo : "",
      assignmentDateTime: "",
      attachments: dischargeData?.id
        ? dischargeData?.attachments?.map(({ id }) => id)
        : [],
      patient_id: dischargeData?.id
        ? dischargeData?.patient?.id
        : id ?? selectedDischarge?.id,
      hospital_id: dischargeData?.id ? dischargeData?.hospital_id : "",
      insurance_id: dischargeData?.id ? dischargeData?.insurance?.id : "",
      insuranceNumber: dischargeData?.id ? dischargeData?.insuranceNumber : "",
    },
    validationSchema: dischargeSummarySchema,
    onSubmit: async (values) => {
      if (files?.length) {
        setIsImageloading(true);
        handleImage();
      } else {
        setIsRequired(true);
        // mutate({ ...values });
      }
    },
  });

  const { data: assignToData, isLoading: isAssignData } = useFetch(
    "assignto",
    () =>
      request({
        method: "post",
        data: { name: "nurse" },
        url: "/assignto",
      })
  );

  const { data: hospitalTypesData, isLoading: isHospitalTypesData } = useFetch(
    ["hospitalTypes", formik.values.hospital_id],
    () =>
      request({
        method: "get",
        url: "/hospitalTypes",
      }),
    {
      enabled: formik.values.hospital_id ? true : false,
    }
  );

  const { data: tcmProviderData, isLoading: isTcmProviderData } = useFetch(
    "tcmProvider",
    () =>
      request({
        method: "post",
        data: { name: "tcm provider" },
        url: "/provider",
      })
  );

  const { data: dichargeProviderData, isLoading: isDischargeProviderData } =
    useFetch("dischargeProvider", () =>
      request({
        method: "post",
        data: { name: "discharge provider" },
        url: "/provider",
      })
    );

  const { data: HospitalNameData, isLoading: isHospitalNameData } = useFetch(
    ["HospitalNameData", formik.values.dischargeProvider],
    () =>
      request({
        method: "post",
        data: { id: formik.values.dischargeProvider },
        url: "/providerHospitals",
      }),
    {
      enabled: formik.values.dischargeProvider ? true : false,
    }
  );

  useEffect(() => {
    if (!firstTime) {
      const clearButton = document.querySelector(
        ".hospital_id .MuiAutocomplete-clearIndicator"
      );
      formik.values.hospital_id && clearButton && clearButton.click();
      formik.values.hospital_id && formik.setFieldValue("hospital_id", "");
    }
  }, [formik.values.dischargeProvider]);

  useEffect(() => {
    setFirstTime(false);
  }, []);
  const {
    data: icdData,
    isLoading: icdDataLoading,
    fetchNextPage,
  } = useFetch("icd-code", () => request({ method: "get", url: "/icd-code" }));

  return (
    <>
      {(selectedDischarge || patientData?.patient) && !pateintDetailSummary ? (
        <Grid
          style={{
            border: "1px solid rgba(145,144,144,0.3)",
            borderRadius: "10px",
            padding: "0px 12px",
            marginTop: "10px",
          }}
          container
          spacing={2}
        >
          <Flex justifyContent="space-between">
            <Box>
              <Text variant="h3" color="gray" marginTop="20px">
                Patient Details
              </Text>
            </Box>
            <Box
              sx={{
                width: "max-content",
                padding: "3px",
              }}
            >
              <Flex justifyContent="flex-start">
                <Text
                  variant="h4"
                  color="blue"
                  marginTop="0px"
                  marginBottom="0px"
                >
                  &nbsp;&nbsp;&nbsp;UUID: &nbsp;
                </Text>
                <Text
                  variant="body2"
                  color="grey"
                  marginTop="0px"
                  marginBottom="0px"
                >
                  {`${patientData?.patient?.uuid ?? selectedDischarge?.uuid}`}
                </Text>
              </Flex>
            </Box>
          </Flex>

          <Grid item mobile={12} tablet={6} laptop={5}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Patient Name: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {`${
                  patientData?.patient?.firstName ??
                  selectedDischarge?.firstName
                } ${
                  patientData?.patient?.initialName ??
                  selectedDischarge?.initialName
                } 
                ${
                  patientData?.patient?.lastName ?? selectedDischarge?.lastName
                }`}
              </Text>
            </Flex>
          </Grid>

          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Date Of Birth: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {moment(
                  new Date(
                    `${
                      patientData?.patient?.birthDate ??
                      selectedDischarge?.birthDate
                    } `
                  )
                ).format("MM-DD-YYYY")}
                &nbsp;
                {`(${patientData?.patient?.age ?? selectedDischarge?.age} yrs)`}
              </Text>
            </Flex>
          </Grid>

          <Grid item mobile={12} tablet={6} laptop={3}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Gender: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {`
                  ${patientData?.patient?.gender ?? selectedDischarge?.gender}`}
              </Text>
            </Flex>
          </Grid>

          <Grid item mobile={12} tablet={6} laptop={5}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Address: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {`${
                  patientData?.patient?.Address1 ?? selectedDischarge?.Address1
                }`}
              </Text>
            </Flex>
          </Grid>

          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Email: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {(patientData?.patient?.email ?? selectedDischarge?.email) ||
                  ""}
              </Text>
            </Flex>
          </Grid>

          <Grid item mobile={12} tablet={6} laptop={3}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Phone #: &nbsp;
              </Text>

              <Text variant="body2" color="grey">
                {`${
                  patientData?.patient?.mobilePhone ??
                  selectedDischarge?.mobilePhone
                }`}
              </Text>
            </Flex>
          </Grid>

          {(patientData?.patient?.otherPhone ||
            selectedDischarge?.otherPhone) && (
            <Grid item mobile={12} tablet={6} laptop={4}>
              <Flex alignItems="flex-start" justifyContent="flex-start">
                <Text variant="h4" color="blue">
                  Secondary Phone #: &nbsp;
                </Text>

                <Text variant="body2" color="grey">
                  {`${
                    patientData?.patient?.otherPhone ??
                    selectedDischarge?.otherPhone
                  }`}
                </Text>
              </Flex>
            </Grid>
          )}

          {!isValueTruthy(
            patientData?.patient?.caregiver_relationship ||
              selectedDischarge?.caregiver_relationship
          ) ? (
            ""
          ) : (
            <>
              <Grid item mobile={12} tablet={6} laptop={4}>
                <Flex alignItems="flex-start" justifyContent="flex-start">
                  <Text variant="h4" color="blue">
                    Caregiver Relation: &nbsp;
                  </Text>
                  <Text variant="body2" color="grey">
                    {`${
                      patientData?.patient?.caregiver_relationship ??
                      selectedDischarge?.caregiver_relationship
                    }`}
                  </Text>
                </Flex>
              </Grid>
              <Grid item mobile={12} tablet={6} laptop={4}>
                <Flex alignItems="flex-start" justifyContent="flex-start">
                  <Text variant="h4" color="blue">
                    Caregiver Name: &nbsp;
                  </Text>
                  <Text variant="body2" color="grey">
                    {`${
                      patientData?.patient?.caregiver_name ??
                      selectedDischarge?.caregiver_name
                    }`}
                  </Text>
                </Flex>
              </Grid>
              <Grid item mobile={12} tablet={6} laptop={4}>
                <Flex alignItems="flex-start" justifyContent="flex-start">
                  <Text variant="h4" color="blue">
                    Caregiver Contact: &nbsp;
                  </Text>
                  <Text variant="body2" color="grey">
                    {`${
                      patientData?.patient?.caregiver_contact ??
                      selectedDischarge?.caregiver_contact
                    }`}
                  </Text>
                </Flex>
              </Grid>
            </>
          )}
        </Grid>
      ) : (
        ""
      )}
      <Grid
        sx={{
          border: pateintDetailSummary ? "" : "1px solid rgba(145,144,144,0.3)",
          borderRadius: pateintDetailSummary ? "" : "10px",
          padding: "10px 12px",
          marginTop: pateintDetailSummary ? "-40px" : "10px",
        }}
        container
        columnSpacing={2}
      >
        {!pateintDetailSummary ? (
          <Flex justifyContent="flex-start">
            <Text variant="h3" color="gray">
              Add Discharge Summary
            </Text>
          </Flex>
        ) : (
          ""
        )}
        {/* <InputField label={"Discharge summary ID"} /> */}
        <InputField
          name="mrn"
          formik={formik}
          label={"MRN#"}
          cols={2}
          required
        />
        <InputField
          label="Admission Date"
          name="admitDate"
          formik={formik}
          type="date"
          max={currentDate}
          cols={5}
          required
        />
        <InputField
          label="Discharge Date"
          name="dischargeDate"
          formik={formik}
          disabled={!formik.values.admitDate}
          type="date"
          min={
            formik.values.admitDate
              ? moment(formik.values.admitDate).format("YYYY-MM-DD")
              : ""
          }
          max={currentDate}
          cols={5}
          required
        />
        {/* <SelectField
          isMulti
          options={icdData?.data?.map((item) => {
            return {
              value: item?.icd_code_id,
              label: `${item?.icd_code} - ${item?.icd_code_description}`,
            };
          })}
          label={"Discharge Diagnosis"}
          name="icd10"
          formik={formik}
          required
        /> */}
        <Grid item mobile={11} tablet={6} laptop={6} desktop={6}>
          <Box sx={{ display: "flex" }}>
            <Text variant="body2" my={1}>
              Diagnosis {true && <span style={{ color: "red" }}>*</span>}
            </Text>
            <DashboardTooltip
              title={"Enter Custom entries not in the ICD list"}
              placement={"right"}
            >
              <Icon
                icon={<ControlPointIcon />}
                onClick={() => {
                  setOpenModal(true);
                }}
                color="#1C4588"
                width="10px"
                height="10px"
                marginLeft="10px"
              />
            </DashboardTooltip>
          </Box>
          <IcdsSearch
            icdDataLoading={icdDataLoading}
            placeholder="Search..."
            icdData={icdData}
            selected={dischargeData?.discharge_icd_codes?.map(
              ({ icd_code }) => {
                return {
                  value: icd_code?.icd_code_id,
                  label: icd_code?.icd_code_description,
                };
              }
            )}
            formik={formik}
            name="icd10"
            errorMessage="Diagnosis are required"
          />
        </Grid>
        <CustomModal
          setOpen={setOpenModal}
          open={openModal}
          title={"Add Diagnosis"}
          maxWidth="laptop"
        >
          <AddIcdForm setOpenModal={setOpenModal} />
        </CustomModal>
        <SelectField
          required
          iconLabel
          iconModalTitle={"Add Discharge Provider"}
          defaultValue={
            dischargeData?.id
              ? {
                  label: `${dischargeData?.discharge_provider?.firstName} ${dischargeData?.discharge_provider?.lastName}`,
                  value: dischargeData?.discharge_provider?.id,
                }
              : ""
          }
          setIconLabelModal={setOpenDischargeModal}
          options={dichargeProviderData?.Providers?.map(
            ({ id, firstName, lastName }) => {
              return { label: `${firstName} ${lastName}`, value: id };
            }
          )}
          label={"Discharge Provider"}
          name="dischargeProvider"
          formik={formik}
        />
        <CustomModal
          setOpen={setOpenDischargeModal}
          open={openDischargeModal}
          title={"Add Discharge Provider"}
          maxWidth="laptop"
        >
          <DischargeProviderModal setOpenModal={setOpenDischargeModal} />
        </CustomModal>
        <SelectField
          classes={"hospital_id"}
          required
          iconLabel
          iconModalTitle={"Add Discharge Facility"}
          setIconLabelModal={setOpenDischargeFacilityModal}
          defaultValue={
            dischargeData?.id
              ? {
                  label: `${dischargeData?.hospital?.name}`,
                  value: dischargeData?.hospital?.id,
                }
              : ""
          }
          label={"Discharge Facility"}
          name="hospital_id"
          options={HospitalNameData?.providerHospitals?.[0]?.provider_hospital?.map(
            (item, index) => {
              return { label: item?.hospital?.name, value: item?.hospital?.id };
            }
          )}
          formik={formik}
          disabled={!formik.values.dischargeProvider}
        />
        <CustomModal
          setOpen={setOpenDischargeFacilityModal}
          open={openDischargeFacilityModal}
          title={"Add Discharge Facility"}
          maxWidth="laptop"
        >
          <DischargeFacilityModal
            setOpenModal={setOpenDischargeFacilityModal}
            dichargeProviderData={dichargeProviderData}
          />
        </CustomModal>
        <SelectField
          required
          label={"Facility Type"}
          name="hospital_type_id"
          defaultValue={
            dischargeData?.id
              ? {
                  label: `${dischargeData?.hospital_type?.name}`,
                  value: dischargeData?.hospital_type?.id,
                }
              : ""
          }
          options={hospitalTypesData?.hospitalTypes?.map(({ id, name }) => {
            return { label: name, value: id };
          })}
          formik={formik}
          disabled={formik.values.hospital_id === "" ? true : false}
        />
        <SelectField
          required
          label={"Select Nurse"}
          name="assignTo"
          defaultValue={
            dischargeData?.id
              ? {
                  label: `${dischargeData?.assign_to?.firstName} ${dischargeData?.assign_to?.lastName}`,
                  value: dischargeData?.assign_to?.id,
                }
              : ""
          }
          options={assignToData?.assignTo?.map(
            ({ id, lastName, firstName }) => {
              return { label: `${firstName} ${lastName}`, value: id };
            }
          )}
          formik={formik}
        />
        <CustomModal
          setOpen={setOpenIsuranceModal}
          open={openIsuranceModal}
          title={"Add Insurance"}
          maxWidth="laptop"
        >
          <AddInsuranceCompanyForm setOpenModal={setOpenIsuranceModal} />
        </CustomModal>
        <SelectField
          required
          iconLabel
          iconModalTitle={"Add Insurance"}
          iconLabelMoadl={openIsuranceModal}
          defaultValue={
            dischargeData?.id
              ? {
                  label: `${dischargeData?.insurance?.insuranceName}`,
                  value: dischargeData?.insurance?.id,
                }
              : ""
          }
          setIconLabelModal={setOpenIsuranceModal}
          label={"Current Insurance"}
          name="insurance_id"
          formik={formik}
          options={insurancesData?.insurances?.map(({ id, insuranceName }) => {
            return { label: `${insuranceName}`, value: id };
          })}
        />
        <InputField
          label={"Insurance ID"}
          name="insuranceNumber"
          formik={formik}
          required
        />
        <Grid item mobile={12} tablet={6}>
          <Text variant="body2" component="label" my={1} sx={{ mb: 1 }}>
            Discharge Summary {<span style={{ color: "red" }}>*</span>}
          </Text>
          <Box
            sx={{
              maxHeight: "120px",
              height: "120px",
              overflowY: "auto",
            }}
          >
            <Flex
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <FileUploader
                setDeletedFileData={setDeletedFileData}
                isRequired={isRequired}
                apiFiles={files}
                onFilesChange={handleFiles}
                multiple={true}
              />
            </Flex>
          </Box>
        </Grid>
        <Flex>
          <FormButtons
            saveID="saveDischarge"
            cancelID="cancelDischarge"
            isCancel={pateintDetailSummary ? false : true}
            onClickSave={formik.submitForm}
            saveLoading={isLoading || isImageLoading}
            onClickCancel={() => {
              handleCancel();
            }}
          />
        </Flex>
      </Grid>
    </>
  );
};

export default NewDischargePatientForm;
