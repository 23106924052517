import axios from "axios";

const request = async (options) => {
  const token = localStorage.getItem("token") || null;

  const {
    method = "get",
    baseURL = process.env.REACT_APP_BASE_URL,
    url,
    headers = {
      Accept: "application/json",
    },
    params,
    data,
  } = options;

  const config = {
    method,
    baseURL,
    url,
    headers: token
      ? {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        }
      : headers,
    params,
    data,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default request;
