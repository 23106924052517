import { Box, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import Text from "../Typography/Text";

const CustomRadioGroup = ({
  defaultValue,
  onChange,
  formik,
  name,
  label,
  disabled = false,
  required = false,
  children = [],
  cols = 6,
  justifyContent = "",
  marginTop="0",
}) => {
  const handleChange = (e) => {
    if (formik) {
      formik?.setFieldValue(name, e.target.value);
    } else {
      onChange(e);
    }
  };

  const fieldError = formik?.errors?.[name];
  const fieldTouched = formik?.touched?.[name];

  return (
    <Grid item mobile={12} tablet={cols} laptop={cols} desktop={cols}>
      {label && (
        <Text variant="body2" my={1}>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </Text>
      )}

      <RadioGroup
        row
        aria-labelledby="row-radio-buttons-group"
        name={name}
        defaultValue={defaultValue}
        onChange={handleChange}
        sx={{ justifyContent: justifyContent }}
      >
        <Box display="flex" flexDirection="column">
          <Box>
            {children?.map((radioField, index) => {
              return (
                <FormControlLabel
                  disabled={disabled}
                  key={index}
                  value={radioField ?? ""}
                  control={<Radio />}
                  label={radioField ?? ""}
                  sx={{ marginRight: "10px" , marginTop:marginTop  }}
                />
              );
            })}
          </Box>
          {formik && fieldTouched && fieldError && (
            <Text variant="body2" color="red" fontSize="12px" mt={1} mb={0}>
              {fieldError}
            </Text>
          )}
        </Box>
      </RadioGroup>
    </Grid>
  );
};

export default CustomRadioGroup;
