import * as yup from "yup";

export const FeedBackSchema = yup.object().shape({
  clinical_id: yup.string().required("Clinical Id is required"),
  patient_id: yup.string().required("Patient Id is required"),
  discharge_id: yup.string().required("Discharge Id is required"),
  // assignDateTime: yup.string().required("Assign Date Time is required"),
  // date: yup.string().required("Date is required"),
  // time: yup.string().required("Time To is required"),
  // assignTo: yup.string().required("Assign To is required"),
  feedback: yup.string().required("Feedback is required"),
  contactMadeTo: yup.string().required("Contact Made To is required"),
  additionalSuggesstions: yup
    .string()
    .required("Additional Suggesstions is required"),
  // feedbackStatus: yup.string().required("Feedback Status is required"),
  // caregiverName: yup.string().required("Caregiver Name is required"),
});
