import { Delete as DeleteIcon } from "@mui/icons-material";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import elipses from "utils/elipses";

const FileUploader = ({ setDeletedFileData, multiple, onFilesChange, isRequired = false, apiFiles = [] }) => {
  const [selectedFiles, setSelectedFiles] = useState(apiFiles ?? []);

  const handleDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      name: file.name,
      size: file.size,
      type: file.type,
      previewURL: URL.createObjectURL(file), // Generate temporary URL for the file
    }));

    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    onFilesChange([...selectedFiles, ...newFiles]);
  };

  const handleDelete = (fileIndex) => {
    let updatedFiles;

    const fileIndexToRemove = selectedFiles.findIndex((_, index) => index === fileIndex);

    if (fileIndexToRemove !== -1 && selectedFiles[fileIndexToRemove]?.hasOwnProperty("pivot")) {
      setDeletedFileData((old) => [...old, selectedFiles[fileIndexToRemove]?.id])
      updatedFiles = selectedFiles.filter(
        (_, index) => index !== fileIndex
      );

    } else {
      if (fileIndexToRemove !== -1) {
        updatedFiles = selectedFiles.filter(
          (_, index) => index !== fileIndex
        );
      }
    }

    setSelectedFiles(updatedFiles);
    onFilesChange(updatedFiles);
  };


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: multiple,
  });

  return (
    <>
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? "drag-active" : ""}`}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              marginTop: "0px",
              background:
                "linear-gradient(180deg, #FDFDFD 0%, #E4E4E6 100%) 0% 0% no-repeat padding-box",
              cursor: "pointer",
            }}
          >
            Drop your files here...
          </p>
        ) : (
          <p
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              marginTop: "0px",
              borderRadius: "5px",
              background:
                "linear-gradient(180deg, #FDFDFD 0%, #E4E4E6 100%) 0% 0% no-repeat padding-box",
              cursor: "pointer",
            }}
          >
            Upload files
          </p>
        )}
      </div>
      {isRequired && !selectedFiles?.length && <p style={{ color: 'red' }}>Discharge Summary is required</p>}
      {selectedFiles?.length > 0 && (
        <div>
          <h5 style={{ marginBottom: "0px", marginTop: "0px" }}>
            Selected Files:
          </h5>
          <ul style={{ paddingLeft: "0px" }}>
            {selectedFiles.map((file, index) => (
              <li
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  listStyleType: "inherit ",
                }}
              >
                {elipses(file.name, 20)}
                <DeleteIcon
                  onClick={() => handleDelete(index)}
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    color: "#ff0000",
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default FileUploader;
