import { initialCallsOptions, initialCallsSubtypeOptions } from "./options";

export const initialCallsDataArray = [
  { label: "Care Plan Goals :", name: "carePlanGoals" },
  { label: "Medical Management :", name: "medicalManagement" },
  { label: "Care Coordination :", name: "careCoordination" },
  { label: "Psychosocial Support :", name: "psychosocialSupport" },
  {
    label: "Education and Lifestyle Modifications :",
    name: "educationAndLifestyleModification :",
  },
  { label: "Follow-Up and Monitoring :", name: "followUpAndMonitoring" },
  { label: "Care Plan Evaluation :", name: "carePlanEvaluation" },
];
export const initialCallsInputFieldsArray = [
  { label: "Patient Name :", name: "patientName", cols: 12 },
  { label: "Date of Diagnosis :", name: "dateOfDiagnosis", cols: 6 },
  { label: "DoB :", name: "dob", cols: 6 },
  { label: "Gender :", name: "gender", cols: 6 },
  {
    label: "Contact Number :",
    name: "contactNumber :",
    cols: 6,
  },
  { label: "Insurance :", name: "insurance", cols: 6 },
  { label: "Insurance # :", name: "insuranceNo", cols: 6 },
];
export const initialCallsSelectFieldsArray = [
  {
    label: "Chronic 1",
    name: "Chronic1",
    cols: 12,
    options: initialCallsOptions,
    require: "required",
  },
  {
    label: "Subtype :",
    name: "subType",
    cols: 12,
    options: initialCallsSubtypeOptions,
  },
  {
    label: "Chronic 2",
    name: "Chronic2",
    cols: 12,
    options: initialCallsOptions,
    require: "required",
  },
  {
    label: "Subtype :",
    name: "subType",
    cols: 12,
    options: initialCallsSubtypeOptions,
  },
  {
    label: "Chronic 3",
    name: "Chronic3",
    cols: 12,
    options: initialCallsOptions,
    require: "required",
  },
  {
    label: "Subtype :",
    name: "subType",
    cols: 12,
    options: initialCallsSubtypeOptions,
  },
];

export const MonthlyCallsDataArray = [
  {
    label: "Patient Current Condition :",
    name: "patientCurrentCondition",
    cols: 6,
  },
  { label: "Care Plan Goals :", name: "carePlanGoals", cols: 6 },
  { label: "Medical Management :", name: "medicalManagement", cols: 6 },
  {
    heading: "Medications :",
    type: "table",
    cols: 6,
    data: [
      {
        labTestName: "med1",
        value: "med2",
      },
      {
        labTestName: "med1",
        value: "med2",
      },
    ],
    tableColumns: [
      { Header: "Lab Test Name", accessor: "labTestName" },
      { Header: "Value", accessor: "value" },
    ],
  },
  { label: "Care Coordination :", name: "careCoordination", cols: 6 },
  { label: "Psychosocial Support :", name: "psychosocialSupport", cols: 6 },
  {
    label: "Current Treatment/Therapy :",
    name: "currentTreatmentTherapy",
    cols: 6,
  },
  { label: "Care Plan Evaluation :", name: "carePlanEvaluation", cols: 6 },
  {
    label: "Follow-Up and Monitoring",
    name: "followUpAndMonitoring",
    cols: 6,
  },

  {
    label: "Education and Lifestyle Modifications :",
    name: "educationAndLifestyleModification :",
    cols: 6,
  },
  {
    heading: "Medications :",
    type: "table",
    cols: 12,
    data: [
      {
        mediname: "med1",
        type: "med2",
        quantity: "med3",
        direction: "med4",
      },
      {
        mediname: "med1",
        type: "med2",
        quantity: "med3",
        direction: "med4",
      },
    ],
    tableColumns: [
      { Header: "Medi Name", accessor: "mediname" },
      { Header: "Type", accessor: "type" },
      { Header: "Quantity", accessor: "quantity" },
      { Header: "Direction", accessor: "direction" },
    ],
  },
];

export const callsHistoryRow = [
  {
    dateTime: "calls1",
    status: "calls2",
    remarks: "calls3",
  },
  {
    dateTime: "calls1",
    status: "calls2",
    remarks: "calls3",
  },
  {
    dateTime: "calls1",
    status: "calls2",
    remarks: "calls3",
  },
  {
    dateTime: "calls1",
    status: "calls2",
    remarks: "calls3",
  },
];
export const callsHistoryTablecolumns = [
  { Header: "Date & Time", accessor: "dateTime" },
  { Header: "Status", accessor: "status" },
  { Header: "Remarks", accessor: "remarks" },
];

export const Medication1Table = [
  { labTestName: "med1", value: "med2" },
  { labTestName: "med1", value: "med2" },
];
export const Medication1Tablecolumns = [
  { Header: "Lab Test Name", accessor: "labTestName" },
  { Header: "Value", accessor: "value" },
];

export const Medication2Table = [
  {
    mediname: "med1",
    type: "med2",
    quantity: "med3",
    direction: "med4",
  },
  {
    mediname: "med1",
    type: "med2",
    quantity: "med3",
    direction: "med4",
  },
];
export const Medication2Tablecolumns = [
  { Header: "Medi Name", accessor: "mediname" },
  { Header: "Type", accessor: "type" },
  { Header: "Quantity", accessor: "quantity" },
  { Header: "Direction", accessor: "direction" },
];

// Lab Test modal Data for table
export const LabTestModalTableData = [
  { labTestName: "Lorem Ipsum", lastName: "Lorem Ipsum" },
  { labTestName: "Lorem Ipsum", lastName: "Lorem Ipsum" },
  { labTestName: "Lorem Ipsum", lastName: "Lorem Ipsum" },
  { labTestName: "Lorem Ipsum", lastName: "Lorem Ipsum" },
  { labTestName: "Lorem Ipsum", lastName: "Lorem Ipsum" },
];
export const LabTestModalTableColumns = [
  { Header: "Lab Test Name", accessor: "labTestName" },
  { Header: "Last Name", accessor: "lastName" },
];

export const AddMedicationsModalTableData = [
  {
    medicineName: "Lorem Ipsum",
    type: "Type",
    quantity: "12",
    direction: "Lorem Ipsum",
  },
  {
    medicineName: "Lorem Ipsum",
    type: "",
    quantity: "",
    direction: "Lorem Ipsum",
  },
  {
    medicineName: "Lorem Ipsum",
    type: "",
    quantity: "",
    direction: "Lorem Ipsum",
  },
  {
    medicineName: "Lorem Ipsum",
    type: "",
    quantity: "",
    direction: "Lorem Ipsum",
  },
];
export const AddMedicationsModalTableColumns = [
  { Header: "Medicine Name", accessor: "medicineName" },
  { Header: "Type", accessor: "type" },
  { Header: "Quantity", accessor: "quantity" },
  { Header: "Direction", accessor: "direction" },
];
export const ImmunizationProtectionOptions = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];
export const PublicityCodeOptions = [
  { value: "01", label: "No reminder/recall" },
  { value: "02", label: "Reminder/recall - any method" },
  { value: "03", label: "Reminder/recall - no calls" },
  { value: "04", label: "Reminder only - any method" },
  { value: "05", label: "Reminder only - no calls" },
  { value: "06", label: "Recall only - any method" },
  { value: "07", label: "Recall only - no calls" },
  { value: "08", label: "Reminder/recall - to provider" },
  { value: "09", label: "Reminder to provider" },
  { value: "10", label: "Only reminder to provider, no recall" },
  { value: "11", label: "Recall to provider" },
  { value: "12", label: "Only recall to provider, no reminder" },
];
export const RelationOptions = [
  { value: "Father", label: "Father" },
  { value: "Mother", label: "Mother" },
  { value: "Brother", label: "Brother" },
  { value: "Sister", label: "Sister" },
  { value: "Care giver", label: "Care giver" },
  { value: "Child", label: "Child" },
  { value: "Foster child", label: "Foster child" },
  { value: "Guardian", label: "Guardian" },
  { value: "Grand parent", label: "Grand parent" },
  { value: "Spouse", label: "Spouse" },
  { value: "Parent", label: "Parent" },
  { value: "Step child", label: "Step child" },
  { value: "Self", label: "Self" },
  { value: "Sibling", label: "Sibling" },
  { value: "Other", label: "Other" },
];
export const HPIContactMethodOptions = [
  { value: "Voice Primary Phone", label: "Voice Primary Phone" },
  { value: "Voice Secondary Phone", label: "Voice Secondary Phone" },
  { value: "SMS Primary Phone", label: "SMS Primary Phone" },
  { value: "SMS Secondary Phone", label: "SMS Secondary Phone" },
  { value: "Secure Email", label: "Secure Email" },
];
export const StateSelectOption = [
  { label: 'AL', value: 'AL' },
  { label: 'AK', value: 'AK' },
  { label: 'AZ', value: 'AZ' },
  { label: 'AR', value: 'AR' },
  { label: 'CA', value: 'CA' },
  { label: 'CO', value: 'CO' },
  { label: 'CN', value: 'CN' },
  { label: 'DE', value: 'DE' },
  { label: 'DC', value: 'DC' },
  { label: 'FL', value: 'FL' },
  { label: 'GA', value: 'GA' },
  { label: 'HW', value: 'HW' },
  { label: 'ID', value: 'ID' },
  { label: 'IL', value: 'IL' },
  { label: 'IN', value: 'IN' },
  { label: 'IA', value: 'IA' },
  { label: 'KS', value: 'KS' },
  { label: 'KY', value: 'KY' },
  { label: 'LA', value: 'LA' },
  { label: 'ME', value: 'ME' },
  { label: 'MA', value: 'MA' },
  { label: 'MD', value: 'MD' },
  { label: 'MI', value: 'MI' },
  { label: 'MN', value: 'MN' },
  { label: 'MS', value: 'MS' },
  { label: 'MO', value: 'MO' },
  { label: 'MT', value: 'MT' },
  { label: 'NE', value: 'NE' },
  { label: 'NV', value: 'NV' },
  { label: 'NH', value: 'NH' },
  { label: 'NJ', value: 'NJ' },
  { label: 'NM', value: 'NM' },
  { label: 'NY', value: 'NY' },
  { label: 'NC', value: 'NC' },
  { label: 'ND', value: 'ND' },
  { label: 'OH', value: 'OH' },
  { label: 'OK', value: 'OK' },
  { label: 'OR', value: 'OR' },
  { label: 'PA', value: 'PA' },
  { label: 'RH', value: 'RH' },
  { label: 'SC', value: 'SC' },
  { label: 'SD', value: 'SD' },
  { label: 'TN', value: 'TN' },
  { label: 'TX', value: 'TX' },
  { label: 'UT', value: 'UT' },
  { label: 'VE', value: 'VE' },
  { label: 'VA', value: 'VA' },
  { label: 'WA', value: 'WA' },
  { label: 'WV', value: 'WV' },
  { label: 'WI', value: 'WI' },
  { label: 'WY', value: 'WY' }
];
