import { Box } from "@mui/material";
import Button from "jsx/Common/Button/Button";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import GenericTable from "jsx/Common/Table/GenericTable";
import { memo } from "react";

const DocumentsData = () => {
  const AdminPaneltHeaderData = [
    { Header: "Title", accessor: "title" },
    { Header: "Type", accessor: "type" },
    { Header: "Comments", accessor: "comments" },
    { Header: "Date", accessor: "date" },
  ];
  const TableData = [
    {
      title: "Title",
      type: "Type",
      comments: "Comments",
      date: "Date",
    },
  ];
  return (
    <>
      <Box>
        <Flex justifyContent="end" alignItems="center">
          <Button text={"Upload"} />
        </Flex>
      </Box>
      <GenericTable columns={AdminPaneltHeaderData} data={TableData} />
    </>
  );
};
export default memo(DocumentsData);
