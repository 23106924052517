import { Box } from "@mui/material";

const DischargePatientReport = () => {
  return (
    <>
      <Box
        id="pdf-content"
        className="actual-reciept"
        style={{ padding: "20px", width: "100%" }}
      >
        {/* <Reportsindex /> */}
      </Box>
    </>
  );
};

export default DischargePatientReport;
