
import CreateIcon from "@mui/icons-material/Create";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { Stack } from "@mui/material";
import Icon from "../Icons/Icon";

const TableActionButtons = ({
  onEdit,
  onDelete,
  showEdit = true,
  showDelete = true,
}) => {
  return (
    <Stack Stack gap={1} direction={"horizontal"}>
      {showEdit && (
        <>
          <Icon
            icon={<CreateIcon />}
            border="1px solid #1C4588"
            color="#1C4588"
            onClick={onEdit}
            width="30px"
            height="30px"
          />
        </>
      )}
      {showDelete && (
        <>
          <Icon
            icon={<DeleteSweepIcon />}
            border="1px solid red"
            color="red"
            onClick={onDelete}
            width="30px"
            height="30px"
          />
        </>
      )}
    </Stack>
  );
};

export default TableActionButtons;
