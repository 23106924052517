import { Box } from "@mui/material";

const IcdCodeDesc = ({ selectedEstablish }) => {
  return (
    <Box
      sx={{
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "10px",
        maxHeight: "400px",
        height: "200px",
        overflowY: "auto",
      }}
    >
      {selectedEstablish?.discharge_icd_codes?.map((item, index) => (
        <Box sx={{ marginBottom: "10px" }} key={index}>
          {`${item?.icd_code?.icd_code} ${item?.icd_code?.icd_code_description}`}
        </Box>
      ))}
    </Box>
  );
};

export default IcdCodeDesc;
