import { Box, Grid } from '@mui/material'
import Text from 'jsx/Common/Typography/Text'
import React from 'react'
import { useEffect } from 'react';
import logoImg from "../../../../../assets/images/logo.png"
import TopBar from './TopBar';

const TermsOfServices = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
    }, []);
    return (
        <>
            <TopBar />
            <Grid container sx={{ my: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Grid item laptop={10} desktop={9} tablet={11} mobile={11}>
                    <Box sx={{
                        padding: '30px 40px', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
                    }}>
                        <Text variant='h1' textAlign="center" marginBottom="40px">
                            Terms of Service for Discharge Health
                        </Text>
                        <Text variant='body2'>
                            Effective Date:July 01, 2022
                        </Text>
                        <Text variant='body2'>
                            1. Acceptance of Terms
                        </Text>
                        <Text variant='body2'>
                            Welcome to Discharge Health, Inc a DE corporation and its subsidiaries ("we," "us," or "our",
                            “Discharge Health”, “DH” ). These Terms of Service ("Terms") govern your use of our
                            website located at www.dischargehealth.com (the "Website") and our transitional care
                            management services (collectively, the "Services"). By accessing or using the Website or
                            Services, you agree to be bound by these Terms and our Privacy Policy, which is
                            incorporated by reference. If you do not agree to these Terms, please do not use our
                            Website or Services.
                        </Text>
                        <Text variant='body2'>
                            2. HIPAA Compliance
                        </Text>
                        <Text variant='body2'>
                            Discharge Health is a HIPAA compliant healthcare company. We are committed to
                            safeguarding your protected health information ("PHI") in accordance with the Health
                            Insurance Portability and Accountability Act ("HIPAA") and related regulations. Our Privacy
                            Policy outlines our HIPAA compliance practices.
                        </Text>
                        <Text variant='body2'>
                            3. Use of Services     </Text>
                        <Text variant='body2'>
                            3.1. Eligibility: You must be at least 18 years old and capable of forming a legally binding
                            agreement to use our Services. By using our Services, you represent and warrant that you
                            meet these eligibility requirements.
                        </Text>
                        <Text variant='body2'>
                            3.2. Access to Services: We grant you a limited, non-exclusive, non-transferable, and
                            revocable right to access and use our Services solely for your personal healthcare needs.
                        </Text>
                        <Text variant='body2'>
                            3.3. User Accounts: To access certain features of our Services, you may be required to
                            create an account. You agree to provide accurate, current, and complete information during
                            the registration process and to update such information to keep it accurate, current, and
                            complete. You are responsible for maintaining the confidentiality of your account credentials
                            and for all activities that occur under your account.
                        </Text>
                        <Text variant='body2'>
                            3.4. Prohibited Activities: You agree not to engage in any of the following activities while
                            using our Services:
                        </Text>
                        <Text variant='body2'>
                            Violating any applicable laws or regulations.
                            Providing false, misleading, or fraudulent information.
                            Using our Services for any unlawful or unauthorized purpose.
                            Transmitting viruses, malware, or other harmful code.
                            Attempting to gain unauthorized access to our systems or data.
                            Interfering with the integrity or performance of our Services
                        </Text>
                        <Text variant='body2'>
                            4. Privacy
                        </Text>
                        <Text variant='body2'>
                            Your use of our Services is also governed by our Privacy Policy, which outlines how we
                            collect, use, and protect your information. By using our Services, you consent to the
                            practices described in the Privacy Policy.
                        </Text>
                        <Text variant='body2'>
                            5. Termination
                        </Text>
                        <Text variant='body2'>
                            We reserve the right to suspend or terminate your access to the Website and Services at our
                            discretion, without notice, for any reason, including if you violate these Terms or engage in
                            any conduct that we believe is harmful to other users or us.
                        </Text>
                        <Text variant='body2'>
                            6. Disclaimers
                        </Text>
                        <Text variant='body2'>
                            6.1. No Medical Advice: The information provided on our Website and through our Services
                            is for informational purposes only and is not intended to be a substitute for professional
                            medical advice, diagnosis, or treatment. Always seek the advice of your physician or other
                            qualified healthcare provider with any questions you may have regarding a medical
                            condition.
                        </Text>
                        <Text variant='body2'>
                            6.2. No Guarantee: We do not guarantee the accuracy, completeness, or timeliness of the
                            information provided on our Website or through our Services. You acknowledge and agree
                            that your use of our Services is at your own risk.
                        </Text>
                        <Text variant='body2'>
                            7. Limitation of Liability
                        </Text>
                        <Text variant='body2'>
                            To the fullest extent permitted by law, Discharge Health and its affiliates, officers,
                            employees, and agents shall not be liable for any direct, indirect, incidental, special,
                            consequential, or punitive damages arising out of or in connection with your use of our
                            Services.
                        </Text>
                        <Text variant='body2'>
                            8. Changes to Terms
                        </Text>
                        <Text variant='body2'>
                            We may update these Terms from time to time without prior notice. Any changes will be
                            effective upon posting on our Website. Your continued use of our Services after the posting
                            of revised Terms constitutes your acceptance of such changes.
                        </Text>
                        <Text variant='body2'>
                            9. Contact Us
                        </Text>
                        <Text variant='body2'>
                            If you have any questions or concerns about these Terms or our Services, please contact us
                            at info@dischargehealth.com.
                        </Text>
                        <Text variant='body2'>
                            By using Discharge Health's Services, you acknowledge that you have read, understood,
                            and agree to these Terms of Service and our Privacy Policy. These Terms constitute a
                            legally binding agreement between you and Discharge Health.
                        </Text>

                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default TermsOfServices