import Disable from "assets/images/dashboard/disable_img.svg";
import Heart from "assets/images/dashboard/heart_img.svg";
import Patient from "assets/images/dashboard/patient_img.svg";
import People from "assets/images/dashboard/people_img.svg";
import Person from "assets/images/dashboard/person_img.svg";
import tcmImage from "assets/images/Ccmimages/tcm.png"
import tcmIcon from "assets/images/Ccmimages/tcmicon.png"
import ccmImage from "assets/images/Ccmimages/ccm.png"
import ccmIcon from "assets/images/Ccmimages/ccmicon.png"

export const dashboardCard1DataArray = [
  { text: "TCM Patients", number: "100", src: `${Person}` },
  { text: "Medicare", number: "70", src: `${Heart}` },
  { text: "Non Medicare", number: "70", src: `${Heart}` },
  { text: "Male", number: "30", src: `${Disable}` },
  { text: "Female", number: "70", src: `${Patient}` },
  { text: "Transgender", number: "30", src: `${People}` },
];
export const dashboardCard2DataArray = [
  { text: "Discharge Summary Received", number: "100", bgColor: "#FFE2E6" },
  {
    text: "Dr. Sajoud's Patients",
    number: "32%",
    value: -27,
    bgColor: "#FFF4DE",
  },
  { text: "Other Doctor's", number: "68%", value: -1, bgColor: "#DCFCE7" },
  { text: "Successful Calls", number: "40%", value: -11, bgColor: "#F4E8FF" },
  { text: "No Response Calls", number: "30%", value: -11, bgColor: "#DCFCE7" },
  {
    text: "Unsuccessful Calls",
    number: "30%",
    value: -5,
    bgColor: "#CDE9E9",
  },
];
export const data = {
  labels: ["Red", "Blue", "Yellow"],
  datasets: [
    {
      data: [300, 50, 100],
      backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
    },
  ],
};

export const graphData = [
  {
    title: "Actual Visits",
    value: "100",
    graphColor: "#A3A0FB",
    key: "actualVisits",
  },
  {
    title: "Pending Visits",
    value: "84",
    graphColor: "#5EE2A0",
    key: "pendingVisits",
  },
  {
    title: "Re-appoinments",
    value: "84",
    graphColor: "#55D8FE",
    key: "reAppointments",
  },
  {
    title: "No-show",
    value: "84",
    graphColor: "#B555FE",
    key: "noShows",
  },
];


//CCM StaticData
export const chooseModuleData = [
  {
    text: "Tranisitional Care Management",
    icon: ccmIcon,
    bgImage: ccmImage,
  },
  {
    text: "Chronical Care Management",
    icon: tcmIcon,
    bgImage: tcmImage,
  },
]
