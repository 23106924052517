import { generateSchema } from "validations/AdminPanel/NewUserSchema";
import * as Yup from "yup";

export const createGenericSchema = (fields = []) => {
  let schemaFields = [];

  if (fields?.length) {
    const fieldsData = fields?.map((item) => ({
      name: item,
      validation: Yup.string().required(
        `${item?.charAt(0).toUpperCase() + item?.slice(1)} is required`
      ),
    }));
    schemaFields = fieldsData;
  }
  const schema = schemaFields?.length ? generateSchema(schemaFields) : "";

  return schema;
};
