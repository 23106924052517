import { Box, Grid } from "@mui/material";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import GlobalSearch from "jsx/Common/FormComponents/GlobalSearch";
import InputField from "jsx/Common/FormComponents/InputField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import Text from "jsx/Common/Typography/Text";
const AddDiagnosisForm = () => {
  const currentDate = new Date().toISOString().split("T")[0]; // Get the current date in YYYY-MM-DD
  return (
    <Box>
      <Text my={1} variant="body2">
        Diagnosis
      </Text>
      <GlobalSearch />
      <Grid container>
        <InputField
          cols={12}
          max={currentDate}
          type={"date"}
          label={"Onsite Date"}
        />
        <InputField cols={12} multiline rows={5} label={"Comments"} />
        <Flex>
          <FormButtons />
        </Flex>
      </Grid>
    </Box>
  );
};
export default AddDiagnosisForm;
