import { Box, LinearProgress } from "@mui/material";

const LineLoader = () => {
  return (
    <Box width="100%">
      <LinearProgress variant="indeterminate" />
    </Box>
  );
};

export default LineLoader;
