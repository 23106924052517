import { Box, Grid, Typography } from "@mui/material";
import request from "api/request";
import { appRoutes } from "assets/static/appRoutes";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import CustomRadioGroup from "jsx/Common/FormComponents/CustomRadioGroup";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import SelectField from "jsx/Common/FormComponents/SelectField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import Logo from "jsx/Common/Logo/Logo";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { ChooseCompanySchema } from "validations/ChooseCompany/ChooseCompanySchema";

const ChooseCompany = () => {
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");

  const queryClient = useQueryClient();
  const { useAppMutation } = useReactQuery();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const { homeRedirect, ccmHomeRedirect } = appRoutes;

  const currentCompanyName = localStorage.getItem("company_name");
  const currentCompanyID = localStorage.getItem("company_id");
  const companies = JSON.parse(localStorage.getItem("companies"));
  const ccm_companies = JSON.parse(localStorage.getItem("ccm_companies"));

  const tcmCompanies = (companies || [])?.map(({ id: value, name: label }) => ({
    value,
    label,
  }));

  const ccmCompanies = (ccm_companies || [])?.map(
    ({ id: value, name: label }) => ({
      value,
      label,
    })
  );

  const { mutate: mutateCompany, isLoading: isLoadingCompany } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: "/update/user/company",
        data,
      }),
    {
      onSuccess: (data) => {
        localStorage.setItem("company_name", data?.data?.company?.name);
        localStorage.setItem("company_id", data?.data?.company_id);
        localStorage.setItem("permissions", JSON.stringify(data?.permissions));
        localStorage.setItem(
          "company_create_at",
          data?.data?.company?.created_at
        );
        localStorage.setItem("selected_company_name", selectedCompany);
        queryClient.invalidateQueries(["counts"]);
        window.location.replace(
          `${selectedCompany === "tcm" ? homeRedirect : ccmHomeRedirect}`
        );
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      companyId: "",
      selectTcmCcm: "",
      applyValidation:
        companies?.length && ccm_companies?.length ? true : false,
    },
    validationSchema: ChooseCompanySchema,
    onSubmit: async (values) => {
      mutateCompany({ company_id: values?.companyId });
    },
  });

  const handleLogOut = () => {
    localStorage.clear();
    queryClient.clear();
    navigate("/login");
  };

  useEffect(() => {
    if (formik?.values?.selectTcmCcm === "Transition Care Management") {
      setSelectedOption(tcmCompanies);
      setSelectedCompany("tcm");
    } else if (formik?.values?.selectTcmCcm === "Chronic Care Management") {
      setSelectedOption(ccmCompanies);
      setSelectedCompany("ccm");
    } else {
      setSelectedOption([]);
      setSelectedCompany("");
    }
    const clearButton = document.querySelector(
      ".Select_Practic .MuiAutocomplete-clearIndicator"
    );
    clearButton && clearButton.click();

    formik.setFieldValue("companyId", "");
  }, [formik?.values?.selectTcmCcm]);

  useEffect(() => {
    // Check if user is registered only in CCM company
    if (ccm_companies?.length && !companies?.length) {
      setSelectedOption(ccmCompanies);
      setSelectedCompany("ccm");
    }
    // Check if user is registered only in TCM company
    else if (companies?.length && !ccm_companies?.length) {
      setSelectedOption(tcmCompanies);
      setSelectedCompany("tcm");
    }
    // Reset selectedOption if user is registered in both TCM and CCM companies
    else {
      setSelectedOption([]);
      setSelectedCompany("");
    }
  }, []);

  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: "",
      }}
    >
      <Grid
        item
        tablet={6}
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          padding: "20px",
          minHeight: "250px",
        }}
      >
        <Flex marginTop="10px" marginBottom="40px">
          <Logo />
        </Flex>

        {companies?.length && ccm_companies?.length ? (
          <Box
            sx={{
              marginBottom: "20px",
            }}
          >
            <Flex>
              <CustomRadioGroup
                justifyContent="center"
                name="selectTcmCcm"
                required
                formik={formik}
                children={[
                  "Transition Care Management",
                  "Chronic Care Management",
                ]}
                cols={12}
              />
            </Flex>
          </Box>
        ) : (
          ""
        )}

        {!companies?.length && !ccm_companies?.length ? (
          <Box
            sx={{
              marginBottom: "20px",
            }}
          >
            <Flex>
              <Typography variant="body2" color="error">
                Company does not exist
              </Typography>
            </Flex>
          </Box>
        ) : (
          ""
        )}

        <Box
          sx={{
            px: "60px",
            marginBottom: "15px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <SelectField
            classes="Select_Practic"
            label={"Select Practice"}
            options={selectedOption}
            cols={12}
            formik={formik}
            name={"companyId"}
            required
          />
        </Box>

        {companies?.length || ccm_companies?.length ? (
          <FormButtons
            saveLoading={isLoadingCompany}
            onClickSave={formik.submitForm}
            save="continue"
            isCancel={false}
            saveID="selectPractic"
          />
        ) : (
          <FormButtons
            onClickSave={handleLogOut}
            save="Logout"
            isCancel={false}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ChooseCompany;
