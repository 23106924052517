import { Box } from "@mui/material";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import Text from "jsx/Common/Typography/Text";
import React from "react";
import RolesTable from "./RolesTable";

const RolesManagement = () => {
  return (
    <>
      <Box>
        <Flex>
          <Text variant="h1">Roles Management</Text>
        </Flex>
        <RolesTable />
      </Box>
    </>
  );
};

export default RolesManagement;
