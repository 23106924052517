import { Box, Grid } from "@mui/material";
import request from "api/request";
import {
  medicineReconciliation,
  pointOfServices,
  reviewQuestionaire,
} from "assets/static/options";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import CustomRadioGroup from "jsx/Common/FormComponents/CustomRadioGroup";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import InputField from "jsx/Common/FormComponents/InputField";
import CustomRadioCheckbox from "jsx/Common/FormComponents/RadioCheckField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import CustomModal from "jsx/Common/Modal/CustomModal";
import PatientDetail from "jsx/Common/PatientDetail/PatientDetail";
import Text from "jsx/Common/Typography/Text";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import elipses from "utils/elipses";
import {
  MinimumCaseclinicExaminationSchema,
  clinicExaminationSchema,
  reAppointmentSchema,
} from "validations/DischargePatient/clinicExaminationSchema";
import NurseComment from "../../Listing/NurseComment/NurseComment";

const ClinicalExaminationForm = ({
  validateCount = 0,
  setValidateCount,
  setOpen,
  selectedExamination,
  setSelectedExamination,
  pateintDetailSummary = false,
  dischargeData = {},
}) => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const { useAppMutation, useFetch } = useReactQuery();
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const [complxity, setComplxity] = useState(false);
  const [reAppointment, setReAppointment] = useState(false);
  const [summaryDisacharge, setSummaryDisacharge] = useState();
  const [commentModal, setCommentModal] = useState(false);
  const today = new Date().toISOString().split("T")[0];
  const handleCancel = () => {
    setOpen && setOpen(false);
  };

  const { data: patientData, isLoading: patientDataLoading } = useFetch(
    `/patient/${id}`,
    () =>
      request({
        method: "get",
        url: `/patient/${id ?? selectedExamination?.patient?.id}`,
      }),
    {
      enabled: !selectedExamination?.id,
    }
  );

  const { data: dataSummary } = useFetch(
    `dischargesummeriesdata${
      id ? `/${id}` : `/${selectedExamination?.patient?.id}`
    }`,
    () =>
      request({
        method: "get",
        url: `/dischargesummeries${
          id ? `/${id}` : `/${selectedExamination?.patient?.id}`
        }`,
      })
  );

  const { mutate: updateClinicalMutate, isLoading: isUpdateClinicalLoading } =
    useAppMutation(
      (data) =>
        request({
          method: "put",
          url: `/clinicalExaminations/${dischargeData?.clinical_examinations[0]?.id}`,
          data,
        }),
      {
        onSuccess: (data) => {
          showToast("Clinical Examinations Edited Successfully", {
            type: "success",
          });
          setValidateCount && setValidateCount(validateCount + 1);
        },
      }
    );

  const { mutate, isLoading } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: "/clinicalExaminations",
        data,
      }),
    {
      onSuccess: (data) => {
        showToast("Clinical Examination Record Added", {
          type: "success",
        });
        queryClient.invalidateQueries([`/patient/${id}`]);
        queryClient.invalidateQueries(["clinicalExaminations"]);
        queryClient.invalidateQueries([`clinicalExaminations/${id}`]);
        queryClient.invalidateQueries(["appointments"]);
        queryClient.invalidateQueries(["counts"]);
        formik.resetForm();
        setSelectedExamination && setSelectedExamination({});
        handleCancel();
        setShowModal(false);
      },
    }
  );
  const { mutate: reAppointmentApi, isLoadingReAppointment } = useAppMutation(
    (data) =>
      request({
        method: "put",
        url: `/appointments/${selectedExamination?.id}`,
        data,
      }),
    {
      onSuccess: (data) => {
        showToast("Re-appointment Record Added", {
          type: "success",
        });
        queryClient.invalidateQueries([`/patient/${id}`]);
        queryClient.invalidateQueries(["clinicalExaminations"]);
        queryClient.invalidateQueries([`clinicalExaminations/${id}`]);
        queryClient.invalidateQueries(["appointments"]);
        queryClient.invalidateQueries(["counts"]);
        formik.resetForm();
        setSelectedExamination && setSelectedExamination({});
        handleCancel();
      },
    },
    { enabled: selectedExamination?.id }
  );

  const formik = useFormik({
    initialValues: {
      appointmentDateIndicator: "",
      appointment_id: dischargeData?.id
        ? dischargeData?.clinical_examinations[0]?.appointment?.id
        : selectedExamination?.id ?? patientData?.patient?.appointment_last?.id,
      patient_id: dischargeData?.id
        ? dischargeData?.patient?.id
        : selectedExamination?.patient?.id ?? parseInt(id),
      discharge_id: dischargeData?.id
        ? dischargeData?.id
        : selectedExamination?.discharge_id ??
          patientData?.patient?.discharge_patient_last?.id,

      reviewComplexity: dischargeData?.id
        ? dischargeData?.clinical_examinations[0]?.reviewComplexity == 1
          ? true
          : false
        : false,
      data_at_emr: dischargeData?.id
        ? dischargeData?.clinical_examinations[0]?.data_at_emr == 1
          ? true
          : false
        : false,
      reviewQuestionnaire: dischargeData?.id
        ? dischargeData?.clinical_examinations[0]?.reviewQuestionnaire
        : "",
      pointOfService: dischargeData?.id
        ? dischargeData?.clinical_examinations[0]?.pointOfService
        : "",
      tcmProvider: dischargeData?.id
        ? dischargeData?.clinical_examinations[0]?.tcm_provider?.id
        : "",
      medicationChangesOrAdjustment: dischargeData?.id
        ? dischargeData?.clinical_examinations[0]?.medicationChangesOrAdjustment
        : "",
      medicineReconciliation: dischargeData?.id
        ? dischargeData?.clinical_examinations[0]?.medicineReconciliation
        : "",
      reviewDischargeSummary: dischargeData?.id
        ? dischargeData?.clinical_examinations?.[0]?.reviewDischargeSummary
        : "",
      patientOrCaregiverEducation: dischargeData?.id
        ? dischargeData?.clinical_examinations[0]?.patientOrCaregiverEducation
        : "",
      referralsWithOtherPoviders: dischargeData?.id
        ? dischargeData?.clinical_examinations[0]?.referralsWithOtherPoviders
        : "",
      otherServices: dischargeData?.id
        ? dischargeData?.clinical_examinations[0]?.otherServices
        : "",
      // assignTo: "",
      providerCheckin: dischargeData?.id
        ? dischargeData?.clinical_examinations[0]?.provider_check_in?.split(
            " "
          )[0]
        : "",
      assignDateAndTime: dischargeData?.id
        ? dischargeData?.clinical_examinations[0]?.assignDateAndTime?.split(
            " "
          )[0]
        : "",
      doctorConsent: dischargeData?.id
        ? dischargeData?.clinical_examinations[0]?.doctorConsent == 1
          ? true
          : false
        : false,
    },
    validationSchema: !reAppointment
      ? complxity
        ? MinimumCaseclinicExaminationSchema
        : clinicExaminationSchema
      : reAppointmentSchema,

    onSubmit: async (values) => {
      !reAppointment
        ? dischargeData?.id
          ? updateClinicalMutate({
              ...values,
              doctorConsent: values.doctorConsent === true ? 1 : 0,
              reviewComplexity: values.reviewComplexity === true ? 1 : 0,
              reviewDischargeSummary:
                values.reviewDischargeSummary == "Yes" ? 1 : 0,
            })
          : mutate({
              ...values,
              doctorConsent: values.doctorConsent === true ? 1 : 0,
              reviewComplexity: values.reviewComplexity === true ? 1 : 0,
              reviewDischargeSummary:
                values.reviewDischargeSummary == "Yes" ? 1 : 0,
            })
        : reAppointmentApi({
            appointmentDateIndicator: values.appointmentDateIndicator,
          });
    },
  });

  useEffect(() => {
    setComplxity(formik.values.data_at_emr);
  }, [formik.values.data_at_emr]);

  const { data: tcmProviderData, isLoading: isTcmProviderData } = useFetch(
    "tcmProvider",
    () =>
      request({
        method: "post",
        data: { name: "tcm provider" },
        url: "/provider",
      })
  );

  useEffect(() => {
    setSummaryDisacharge(
      dataSummary?.dischargeSummeries?.find(
        ({ patient: disPatient }) =>
          disPatient?.id === (id ? id : selectedExamination?.patient?.id)
      )
    );
  }, [dataSummary]);

  useEffect(() => {
    if (formik.values.data_at_emr && reAppointment) {
      setReAppointment(false);
    }
  }, [formik.values.data_at_emr]);

  useEffect(() => {
    if (reAppointment && formik.values.data_at_emr) {
      formik.setFieldValue("data_at_emr", false);
    }
  }, [reAppointment]);

  const handleNoShow = () => {
    mutate(
      {
        patient_id: formik.values.patient_id,
        appointment_id: formik.values.appointment_id,
        discharge_id: formik.values.discharge_id,
        noshow: 1,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["patient", formik.values.patient_id]);
          queryClient.invalidateQueries(["appointments"]);
          queryClient.invalidateQueries(["clinicalExaminations"]);
          queryClient.invalidateQueries(["counts"]);
        },
      }
    );
  };

  return (
    <>
      {(selectedExamination || patientData?.patient) &&
      !pateintDetailSummary ? (
        <PatientDetail
          selectedRecord={selectedExamination}
          patientRecord={patientData}
        />
      ) : (
        ""
      )}
      {(selectedExamination || patientData?.patient) &&
      !pateintDetailSummary ? (
        <Grid
          style={{
            border: "1px solid rgba(145,144,144,0.3)",
            borderRadius: "10px",
            padding: "0px 12px",
            marginTop: "10px",
          }}
          container
          spacing={2}
        >
          <Flex justifyContent="flex-start" paddingLeft="16px">
            <Text variant="h3" color="gray">
              Complexity Details
            </Text>
          </Flex>
          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Assign By: &nbsp;
              </Text>
              <Text variant="body2" color="grey">
                {`${
                  patientData?.patient?.discharge_patient_last?.assign_to
                    ?.firstName ?? summaryDisacharge?.assign_to?.firstName
                } ${
                  patientData?.patient?.discharge_patient_last?.assign_to
                    ?.lastName ?? summaryDisacharge?.assign_to?.lastName
                }`}
              </Text>
            </Flex>
          </Grid>
          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                CPT Code: &nbsp;
              </Text>
              <Text variant="body2" color="grey">
                {`${
                  patientData?.patient?.establish_complexity_last?.cptCode ??
                  selectedExamination?.discharge_patient?.establish_complexities
                    ?.cptCode
                }`}
              </Text>
            </Flex>
          </Grid>
          <Grid item mobile={12} tablet={4} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Patient Complexity: &nbsp;
              </Text>
              <Text variant="body2" color="grey">
                {`${
                  patientData?.patient?.establish_complexity_last
                    ?.patientComplexity ??
                  selectedExamination?.discharge_patient?.establish_complexities
                    ?.patientComplexity
                }`}
              </Text>
            </Flex>
          </Grid>
          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Visit Due Date: &nbsp;
              </Text>
              <Text variant="body2" color="grey">
                {moment(
                  `${
                    patientData?.patient?.establish_complexity_last
                      ?.dueDateToCheckPatient ??
                    selectedExamination?.discharge_patient
                      ?.establish_complexities?.dueDateToCheckPatient
                  }`
                ).format("MM-DD-YYYY")}
              </Text>
            </Flex>
          </Grid>
          <Grid item mobile={12} tablet={6} laptop={4}>
            <Flex alignItems="flex-start" justifyContent="flex-start">
              <Text variant="h4" color="blue">
                Nurse Comment: &nbsp;
              </Text>
              <Text variant="body2" color="grey">
                {elipses(
                  patientData?.patient?.establish_complexity_last
                    ?.nurseComments ??
                    selectedExamination?.discharge_patient
                      ?.establish_complexities?.nurseComments,
                  20
                )}
                {patientData?.patient?.establish_complexity_last?.nurseComments
                  ?.length > 20 ??
                selectedExamination?.discharge_patient?.establish_complexities
                  ?.nurseComments?.length > 20 ? (
                  <Box
                    sx={{ color: "#1C4588", cursor: "pointer" }}
                    onClick={() => {
                      setCommentModal(true);
                    }}
                  >
                    See more
                  </Box>
                ) : (
                  ""
                )}
              </Text>
            </Flex>
          </Grid>
          <CustomModal
            setOpen={setCommentModal}
            open={commentModal}
            title="Nurse Comment"
            width="auto"
            maxWidth="tablet"
          >
            <NurseComment
              selectedComplexity={selectedExamination}
              patientData={patientData}
            />
          </CustomModal>
        </Grid>
      ) : (
        ""
      )}
      <Grid
        style={{
          border: pateintDetailSummary
            ? "none"
            : "1px solid rgba(145,144,144,0.3)",
          borderRadius: pateintDetailSummary ? "" : "10px",
          padding: "10px 12px",
          marginTop: pateintDetailSummary ? "-40px" : "10px",
        }}
        container
        columnSpacing={2}
      >
        <Flex justifyContent="flex-start">
          {!pateintDetailSummary ? (
            <Text variant="h3" color="gray">
              Add Clinical Examination Form
            </Text>
          ) : (
            ""
          )}
          {pateintDetailSummary ? "" : <>&nbsp; &nbsp; &nbsp; &nbsp;</>}
          <Grid container spacing={3} sx={{ paddingLeft: "15px", mb: "30px" }}>
            <Grid
              item
              desktop={6}
              laptop={4}
              tablet={6}
              mobile={12}
              sx={{ paddingTop: "10px", marginTop: "10px" }}
            >
              <CustomRadioCheckbox
                formik={formik}
                checked={formik.values.data_at_emr}
                name={"data_at_emr"}
                checkRadioLabel="Clinical details already enter in provider EMR"
                type="checkbox"
                cols={pateintDetailSummary ? "12" : "12"}
              />
            </Grid>
            <Grid
              item
              desktop={3}
              laptop={4}
              tablet={6}
              mobile={12}
              sx={{ paddingTop: "0px", margin: "0px", marginTop: "10px" }}
            >
              <CustomRadioCheckbox
                checked={reAppointment}
                onChange={(e) => setReAppointment(e.target.checked)}
                checkRadioLabel="Re-Appointment"
                type="checkbox"
                cols={pateintDetailSummary ? "12" : "12"}
              />
            </Grid>
            <Grid
              item
              desktop={3}
              laptop={4}
              tablet={6}
              mobile={12}
              sx={{ marginTop: "10px" }}
            >
              <CustomRadioCheckbox
                checkRadioLabel="Patient No-Show"
                type="checkbox"
                cols={pateintDetailSummary ? "12" : "12"}
                checked={showModal}
                onChange={(e) => setShowModal(e.target.checked)}
              />
            </Grid>
          </Grid>
          <Box>
            <CustomModal
              setOpen={setShowModal}
              open={showModal}
              Confirmation
              title={"Confirmation"}
              width="100%"
              maxWidth="tablet"
            >
              <Text variant="h5">
                Are you sure the patient has not showed up and move it back to
                intial Contact stage?
              </Text>
              <FormButtons
                saveLoading={isLoading}
                save="Yes, move Back"
                onClickSave={handleNoShow}
                onClickCancel={() => setShowModal(false)}
              />
            </CustomModal>
          </Box>
        </Flex>
        {reAppointment ? (
          <>
            <InputField
              defaultValue={
                dischargeData?.id
                  ? dischargeData?.appointments?.[0]?.appointmentDateIndicator?.split(
                      " "
                    )[0]
                  : selectedExamination?.appointmentDateIndicator
                  ? selectedExamination?.appointmentDateIndicator?.split(" ")[0]
                  : ""
              }
              type="date"
              label="Appointment Date"
              disabled
            />
            <InputField
              formik={formik}
              name={"appointmentDateIndicator"}
              type="date"
              label={"New Appointment Date"}
              min={new Date().toISOString().split("T")[0]}
              required
            />
          </>
        ) : (
          <>
            {!formik.values.data_at_emr ? (
              <>
                <CustomRadioCheckbox
                  formik={formik}
                  checked={formik?.values?.reviewComplexity}
                  name={"reviewComplexity"}
                  label=""
                  checkRadioLabel="Review Complexity"
                  type="checkbox"
                />
                <SelectField
                  options={reviewQuestionaire}
                  formik={formik}
                  name="reviewQuestionnaire"
                  label={"Review questionnaire"}
                  required
                  defaultValue={
                    dischargeData?.id
                      ? pointOfServices?.find(
                          ({ value }) =>
                            value ==
                            dischargeData?.clinical_examinations[0]
                              ?.reviewQuestionnaire
                        )
                      : undefined
                  }
                />
              </>
            ) : (
              ""
            )}
            <SelectField
              options={tcmProviderData?.Providers?.map(
                ({ id, firstName, lastName }) => {
                  return { label: `${firstName} ${lastName}`, value: id };
                }
              )}
              label={"TCM Provider"}
              name="tcmProvider"
              formik={formik}
              required
              defaultValue={
                dischargeData?.id
                  ? {
                      label:
                        dischargeData?.clinical_examinations[0]?.tcm_provider
                          ?.firstName,
                      value:
                        dischargeData?.clinical_examinations[0]?.tcm_provider
                          ?.firstName,
                    }
                  : ""
              }
            />
            {!formik.values.data_at_emr ? (
              <>
                <CustomRadioGroup
                  label={"Review Discharge Summary"}
                  name="reviewDischargeSummary"
                  formik={formik}
                  defaultValue={
                    dischargeData?.id
                      ? dischargeData?.clinical_examinations?.[0]
                          ?.reviewDischargeSummary == 1
                        ? "Yes"
                        : "No"
                      : ""
                  }
                  children={["Yes", "No"]}
                />
                <SelectField
                  options={pointOfServices}
                  formik={formik}
                  name="pointOfService"
                  label={"Point of Service"}
                  required
                  defaultValue={
                    dischargeData?.id
                      ? pointOfServices?.find(
                          ({ value }) =>
                            value ==
                            dischargeData?.clinical_examinations[0]
                              ?.pointOfService
                        )
                      : undefined
                  }
                />
                <InputField
                  formik={formik}
                  name="medicationChangesOrAdjustment"
                  label="Medication Changes/Adjustment"
                  required
                />
                <SelectField
                  label={"Medicine Reconciliation"}
                  formik={formik}
                  name="medicineReconciliation"
                  options={medicineReconciliation}
                  required
                  defaultValue={
                    dischargeData?.id
                      ? {
                          label:
                            dischargeData?.clinical_examinations[0]
                              ?.medicineReconciliation,
                          value:
                            dischargeData?.clinical_examinations[0]
                              ?.medicineReconciliation,
                        }
                      : ""
                  }
                />
                <InputField
                  name="patientOrCaregiverEducation"
                  formik={formik}
                  label={"Patient/Caregiver Education"}
                  required
                />
                <InputField
                  name="referralsWithOtherPoviders"
                  formik={formik}
                  label={"Referrals with other providers"}
                  required
                />
                <InputField
                  name="otherServices"
                  formik={formik}
                  label={"Other Services"}
                  required
                />
              </>
            ) : (
              ""
            )}
            <InputField
              name="providerCheckin"
              formik={formik}
              type={"date"}
              max={today}
              label={"Actual Visit Date"}
            />
            <CustomRadioCheckbox
              label="Provider Consent"
              type="checkbox"
              name="doctorConsent"
              checked={formik.values.doctorConsent}
              formik={formik}
              checkRadioLabel="Provider Name, Certify that, Patient Name, Meets all the requirements of TCM"
              cols="12"
            />
          </>
        )}
        <Flex>
          <FormButtons
            saveID="saveExamination"
            cancelID="cancelExamination"
            isCancel={pateintDetailSummary ? false : true}
            onClickSave={formik.submitForm}
            saveLoading={
              isLoading || isUpdateClinicalLoading || isLoadingReAppointment
            }
            onClickCancel={() => {
              handleCancel();
            }}
          />
        </Flex>
      </Grid>
    </>
  );
};

export default ClinicalExaminationForm;
