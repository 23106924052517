import { Box, Grid } from "@mui/material";
import request from "api/request";
import { postingType } from "assets/static/options";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import InputField from "jsx/Common/FormComponents/InputField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import LineLoader from "jsx/Common/Loaders/LineLoader";
import PatientDetail from "jsx/Common/PatientDetail/PatientDetail";
import Text from "jsx/Common/Typography/Text";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import billingSchema from "validations/DischargePatient/BillingSchema";

const AwaitingReadyForBillingForm = ({
  validateCount = 0,
  setValidateCount,
  setOpen,
  setSelectedBilling,
  selectedBilling,
  alertMessage,
  pateintDetailSummary = false,
  dischargeData = {},
}) => {
  const { id } = useParams();
  const { useAppMutation, useFetch } = useReactQuery();
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const [flag, setFlag] = useState(false);

  const handleCancel = () => {
    setOpen && setOpen(false);
  };

  const { data: patientData, isLoading: patientDataLoading } = useFetch(
    `/patient/${id ?? selectedBilling?.patient?.id}`,
    () =>
      request({
        method: "get",
        url: `/patient/${id ?? selectedBilling?.patient?.id}`,
      })
  );

  // Define the two dates as Moment.js objects
  const startDate = moment(
    patientData?.patient?.discharge_patient_last?.dischargeDate,
    // selectedBilling?.discharge_patient?.dischargeDate,    //code for later use
    "YYYY-MM-DD HH:mm:ss"
  );

  const endDate = moment(
    patientData?.patient?.clinical_last?.provider_check_in,
    // selectedBilling?.provider_check_in, //code for later use
    "YYYY-MM-DD HH:mm:ss"
  );

  // Calculate the difference in days
  const daysDifference = endDate.diff(startDate, "days");

  const { mutate: updateBillingMutate, isLoading: isUpdateBillingLoading } =
    useAppMutation(
      (data) =>
        request({
          method: "put",
          url: `/update/awaiting/billings/${dischargeData?.tcm_billings?.id}`,
          data,
        }),
      {
        onSuccess: (data) => {
          showToast("TCM Billing Edited Successfully", {
            type: "success",
          });
          setValidateCount && setValidateCount(validateCount + 1);
          queryClient.invalidateQueries(
            `/dischargeSummaryDetail/${dischargeData?.id}`
          );
          queryClient.invalidateQueries([
            `/patient/${id ?? selectedBilling?.patient?.id}`,
          ]);
          queryClient.invalidateQueries(["billings"]);
          queryClient.invalidateQueries([`billings/${id}`]);
          queryClient.invalidateQueries(["feedBackCalls"]);

          queryClient.invalidateQueries(["counts"]);
        },
      }
    );

  const { mutate, isLoading } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: "/billings",
        data,
      }),
    {
      onSuccess: (data) => {
        showToast("Billing Record Added", {
          type: "success",
        });

        queryClient.invalidateQueries([
          `/patient/${id ?? selectedBilling?.patient?.id}`,
        ]);
        queryClient.invalidateQueries(["billings"]);
        queryClient.invalidateQueries([`billings/${id}`]);
        queryClient.invalidateQueries(["feedBackCalls"]);

        queryClient.invalidateQueries(["counts"]);
        setSelectedBilling && setSelectedBilling({});
        handleCancel();
        formik.resetForm();
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      clinical_id: dischargeData?.id
        ? dischargeData?.clinical_examinations[0]?.id
        : selectedBilling?.id ?? patientData?.patient?.feedbackcall_last?.id,
      patient_id: dischargeData?.id
        ? dischargeData?.patient?.id
        : selectedBilling?.patient?.id ?? parseInt(id),
      discharge_id: dischargeData?.id
        ? dischargeData?.patient?.id
        : selectedBilling?.discharge_id ??
          patientData?.patient?.discharge_patient_last?.id,
      postingType: dischargeData?.id
        ? dischargeData?.tcm_billings?.postingType
        : "",

      patientComplexity: dischargeData?.id
        ? patientData?.patient?.billing_last?.change_complexity
        : "",
      amount: dischargeData?.id
        ? dischargeData?.tcm_billings?.postingAmount
        : "",

      cptCode: dischargeData?.id
        ? patientData?.patient?.billing_last?.change_cpt_code
        : "",

      applyValidation: false,
    },
    validationSchema: billingSchema,
    onSubmit: async (values) => {
      let {
        clinical_id,
        patient_id,
        discharge_id,
        postingType,
        amount,
        patientComplexity,
        cptCode,
      } = values;

      (daysDifference > 7 &&
        patientData?.patient?.establish_complexity_last?.patientComplexity ===
          "high") ||
      patientData?.patient?.establish_complexity_last?.patientComplexity ===
        "moderate"
        ? dischargeData?.id
          ? updateBillingMutate({
              clinical_id,
              patient_id,
              discharge_id,
              postingType,
              amount,
              patientComplexity,
              cptCode,
            })
          : mutate({
              clinical_id,
              patient_id,
              discharge_id,
              postingType,
              amount,
              patientComplexity,
              cptCode,
            })
        : dischargeData?.id
        ? updateBillingMutate({
            clinical_id,
            patient_id,
            discharge_id,
            postingType,
            amount,
          })
        : mutate({
            clinical_id,
            patient_id,
            discharge_id,
            postingType,
            amount,
          });
    },
  });

  const options = [
    { value: "high", label: "High" },
    { value: "moderate", label: "Moderate" },
  ];

  useEffect(() => {
    if (formik.values.patientComplexity === "high") {
      formik.setFieldValue("cptCode", "99496");
    } else if (formik.values.patientComplexity === "moderate") {
      formik.setFieldValue("cptCode", "99495");
    } else {
      formik.setFieldValue("cptCode", "");
    }
  }, [formik.values.patientComplexity]);

  useEffect(() => {
    if (!isNaN(daysDifference)) {
      if (daysDifference > 7) {
        formik.setFieldValue("applyValidation", true);
      } else {
        formik.setFieldValue("applyValidation", false);
      }
    }
  }, [daysDifference]);


  return (
    <>
      {patientDataLoading ? (
        <LineLoader />
      ) : (
        <Box>
          {(selectedBilling || patientData?.patient) &&
          !pateintDetailSummary ? (
            <PatientDetail
              selectedRecord={selectedBilling}
              patientRecord={patientData}
            />
          ) : (
            ""
          )}
          <Grid
            style={{
              border: pateintDetailSummary
                ? "none"
                : "1px solid rgba(145,144,144,0.3)",
              borderRadius: pateintDetailSummary ? "" : "10px",
              padding: "10px 12px",
              marginTop: pateintDetailSummary ? "-40px" : "10px",
            }}
            container
            columnSpacing={2}
          >
            {!pateintDetailSummary ? (
              <Flex justifyContent="flex-start">
                <Text variant="h3" color="gray">
                  Add Billing
                </Text>
              </Flex>
            ) : (
              ""
            )}
            <InputField
              disabled
              value={
                dischargeData?.id
                  ? dischargeData?.admitDate?.split(" ")[0]
                  : `${
                      selectedBilling?.discharge_patient?.admitDate?.split(
                        " "
                      )[0]
                    }`
              }
              label={"Admission Date"}
              type={"date"}
            />
            <InputField
              disabled
              value={`${
                patientData?.patient?.discharge_patient_last?.dischargeDate?.split(
                  " "
                )[0]
              }`}
              label={"Discharge Date"}
              type={"date"}
            />
            <InputField
              disabled
              value={`${patientData?.patient?.establish_complexity_last?.patientComplexity}`}
              label={"Patient Complexity"}
            />
            <InputField
              disabled
              value={`${patientData?.patient?.establish_complexity_last?.cptCode}`}
              label={"CPT Code"}
            />

            {daysDifference > 7 &&
              patientData?.patient?.establish_complexity_last
                ?.patientComplexity === "high" && (
                <>
                  <SelectField
                    required
                    name="patientComplexity"
                    formik={formik}
                    defaultValue={
                      dischargeData?.id
                        ? options?.find(({ value }) =>
                            value === dischargeData?.id
                              ? patientData?.patient?.billing_last
                                  ?.change_complexity
                              : patientData?.patient?.establish_complexity_last
                                  ?.patientComplexity
                          )
                        : ""
                    }
                    options={options}
                    label={"Patient Complexity"}
                  />
                  <InputField
                    name="cptCode"
                    defaultValue={
                      dischargeData?.id
                        ? patientData?.patient?.billing_last?.change_cpt_code
                        : ""
                    }
                    type={"number"}
                    min={0}
                    formik={formik}
                    label={"CPT Code"}
                    disabled
                  />
                </>
              )}

            <InputField
              disabled
              value={
                dischargeData?.id
                  ? dischargeData?.establish_complexities?.assignDateTime?.split(
                      " "
                    )[0]
                  : `${selectedBilling?.assignDateAndTime?.split(" ")[0]}`
              }
              label={"Visit Due Date"}
              type={"date"}
            />
            <InputField
              disabled
              value={
                dischargeData?.id
                  ? dischargeData?.clinical_examinations[0]?.provider_check_in?.split(
                      " "
                    )[0]
                  : `${selectedBilling?.provider_check_in?.split(" ")[0]}`
              }
              label={"Actual Visit Date"}
              type={"date"}
            />

            <SelectField
              name={"postingType"}
              formik={formik}
              defaultValue={
                dischargeData?.tcm_billings?.id
                  ? postingType?.find(
                      ({ value }) =>
                        value == dischargeData?.tcm_billings?.postingType
                    )
                  : ""
              }
              options={postingType}
              label={"Posting Type"}
              required
            />
            <InputField
              name={"amount"}
              type={"number"}
              formik={formik}
              label={"Amount"}
              required
            />
            <Flex>
              <FormButtons
                saveID="saveBilling"
                cancelID="cancelBilling"
                isCancel={pateintDetailSummary ? false : true}
                saveLoading={isLoading || isUpdateBillingLoading}
                onClickSave={formik.submitForm}
                onClickCancel={() => {
                  handleCancel();
                }}
              />
            </Flex>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default AwaitingReadyForBillingForm;
