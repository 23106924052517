import { Grid } from "@mui/material";
import request from "api/request";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import InputField from "jsx/Common/FormComponents/InputField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import { useQueryClient } from "react-query";
import { dischargeProviderSchema } from "validations/DischargePatient/dischargeProviderSchema";

const DischargeProviderModal = ({ setOpenModal }) => {
  const { useFetch, useAppMutation } = useReactQuery();
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const handleCancel = () => {
    setOpenModal(false);
  };

  const { mutate, isLoading } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: "/create/discharge-provider",
        data,
      }),
    {
      onSuccess: (data) => {
        showToast("Discharge Provider Added Successfully", {
          type: "success",
        });

        queryClient.invalidateQueries(["dischargeProvider"]);
        handleCancel();
        formik.resetForm();
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
    },
    validationSchema: dischargeProviderSchema,

    onSubmit: async (values) => {
      mutate(values);
    },
  });
  return (
    <Grid container columnSpacing={2}>
      <InputField
        label="First Name"
        required
        cols={12}
        name="firstName"
        formik={formik}
      />
      <InputField
        label="Last Name"
        cols={12}
        name="lastName"
        formik={formik}
        required
      />
      <Flex>
        <FormButtons
          saveID="saveProvider"
          cancelID="cancelProvider"
          saveLoading={isLoading}
          onClickSave={formik.submitForm}
          onClickCancel={() => {
            handleCancel();
          }}
        />
      </Flex>
    </Grid>
  );
};

export default DischargeProviderModal;
