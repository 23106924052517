import { Box } from "@mui/material";

const Flex = ({
  children,
  height,
  alignItems = "center",
  direction = "row",
  justifyContent = "center",
  ...rest
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: justifyContent,
        alignItems: alignItems,
        flexWrap: "wrap",
        flexDirection: direction,
        width: "100%",
        height: "auto",
        ...rest,
      }}
    >
      {children}
    </Box>
  );
};

export default Flex;
