import { CssBaseline, ThemeProvider } from "@mui/material";
import LogoutContext from "contexts/LogoutContext";
import ScreenTitleContext from "contexts/ScreenTitleContext";
import ThemeContext from "contexts/ThemeContext";
import ErrorHandler from "jsx/Common/ErrorHandler";
import Loader from "jsx/Common/Loaders/Loader";
import { Suspense, useState } from "react";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import Routing from "routing/Routing";
import "styles/css/index.css";
import { createCustomTheme } from "styles/theming/theme";

const App = () => {
  const theme = createCustomTheme();
  const [title, setTitle] = useState("");
  const [titleBody, setTitleBody] = useState("");
  const [loading, setLoading] = useState(false);

  const queryClient = new QueryClient();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <ThemeContext.Provider value={theme}>
              <ScreenTitleContext.Provider
                value={{
                  setTitle: setTitle,
                  title: title,
                  titleBody: titleBody,
                  setTitleBody: setTitleBody,
                }}
              >
                <LogoutContext.Provider
                  value={{
                    setLoading: setLoading,
                  }}
                >
                  <CssBaseline />
                  <ErrorHandler>
                    {loading ? (
                      <Loader />
                    ) : (
                      <Suspense fallback={<Loader />}>
                        <Routing theme={theme} />
                      </Suspense>
                    )}
                  </ErrorHandler>
                  <Toaster />
                </LogoutContext.Provider>
              </ScreenTitleContext.Provider>
            </ThemeContext.Provider>
          </ThemeProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </>
  );
};

export default App;
