import { Grid } from "@mui/material";
import request from "api/request";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import InputField from "jsx/Common/FormComponents/InputField";
import SelectField from "jsx/Common/FormComponents/SelectField";
import Flex from "jsx/Common/Layouts/Flex/Flex";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { insuranceCompanySchema } from "validations/InsuranceCompany/insuranceCompanySchema";

const InsuranceForm = ({ setOpenModal }) => {
  const [phone, setPhone] = useState("");
  const { useFetch, useAppMutation } = useReactQuery();
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const handleCancel = () => {
    setOpenModal(false);
  };
  const { data: stateData } = useFetch("states", () =>
    request({
      method: "get",
      url: "/states",
    })
  );
  const { data: insuranceData } = useFetch("insurance-types", () =>
    request({
      method: "get",
      url: "/insurance-types",
    })
  );

  const { mutate, isLoading } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: "/insurances",
        data,
      }),
    {
      onSuccess: (data) => {
        showToast("Insurance Added Successfully", {
          type: "success",
        });

        queryClient.invalidateQueries(["insurances"]);
        handleCancel();
        formik.resetForm();
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      insuranceName: "",
      addressOne: "",
      addressTwo: "",
      city: "",
      zip: "",
      state: "",
      electronicPlayerId: "",
      phone: "",
      fax: "",
      insurance_type_id: "",
      insuranceClassification: "",
    },
    validationSchema: insuranceCompanySchema,

    onSubmit: async (values) => {
      //Comment for later use
      //   mutate(values);
    },
  });

  useEffect(() => {
    formik.setFieldValue("phone", phone);
  }, [phone]);

  return (
    <Grid container spacing={1}>
      <InputField
        label="Current Insurance"
        required
        cols={12}
        name="insuranceName"
        formik={formik}
      />
      <InputField
        label="Address 1"
        required
        cols={12}
        name="addressOne"
        formik={formik}
      />
      <InputField
        label="Address 2"
        cols={12}
        name="addressTwo"
        formik={formik}
      />
      <InputField
        required
        label={"City"}
        cols={4}
        name="city"
        formik={formik}
      />
      <SelectField
        label={"State"}
        cols={4}
        required
        name="state"
        formik={formik}
        options={stateData?.States.map((state) => ({
          label: state.name,
          value: state.state_id,
        }))}
      />
      <InputField
        required
        label={"Zip Code"}
        cols={4}
        name="zip"
        formik={formik}
      />
      <InputField
        label={"EPI (Electronic Payer ID)"}
        name="electronicPlayerId"
        formik={formik}
      />
      <InputField
        label={"Phone"}
        isPhone={true}
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        name="phone"
      />
      <InputField label={"Fax"} isPhone name="fax" formik={formik} />

      <SelectField
        required
        label={"Insurance Type"}
        name="insurance_type_id"
        options={insuranceData?.Insurance?.map(({ id, name }) => {
          return { value: id, label: name };
        })}
        formik={formik}
      />
      <InputField
        label={"Insurance Classification"}
        cols={12}
        name="insuranceClassification"
        formik={formik}
      />
      <Flex>
        <FormButtons
          saveLoading={isLoading}
          onClickSave={formik.submitForm}
          onClickCancel={() => {
            handleCancel();
          }}
        />
      </Flex>
    </Grid>
  );
};

export default InsuranceForm;
