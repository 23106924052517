import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import request from "api/request";
import useReactQuery from "hooks/useReactQuery";
import SelectField from "jsx/Common/FormComponents/SelectField";
import Loader from "jsx/Common/Loaders/Loader";
import Text from "jsx/Common/Typography/Text";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";

const QuestionAireComponent = ({ setOpen = false, selectedData, dischargeData = {} }) => {
  const [inputValue, setInputValue] = useState('');
  const [questionId, setQuestionId] = useState('');
  const { useFetch, useAppMutation } = useReactQuery();
  const { id } = useParams();
  const queryClient = useQueryClient();

  // State to track the debounce timer
  const [debounceTimer, setDebounceTimer] = useState(null);

  const { data: patientData, isLoading: patientDataLoading } = useFetch(
    `/patient/${id ?? selectedData?.patient?.id}`,
    () =>
      request({
        method: "get",
        url: `/patient/${id ?? selectedData?.patient?.id}`,
      })
  );

  const { data: questionsData, isLoading: questionsLoading } = useFetch(
    `questionnaires/${dischargeData?.id ?? patientData?.patient?.discharge_patient_last?.id}`,
    () =>
      request({
        method: "get",
        url: `/questionnaires/${dischargeData?.id ?? patientData?.patient?.discharge_patient_last?.id}`,
      }),
    {
      enabled: (dischargeData?.id || patientData?.patient?.discharge_patient_last?.id) ? true : false,
    }
  );
  const { mutate, isLoading: mutateLoading } = useAppMutation(
    (data) =>
      request({
        method: "post",
        url: "/questionnaires",
        data,
      }),
    {
      onSuccess: (data) => {
        // queryClient.invalidateQueries(`questionnaires/${dischargeData?.id ?? patientData?.patient?.discharge_patient_last?.id}`)
      },
    }
  );

  const handleCancel = () => {
    setOpen(false);
  };

  const handleInputChange = (e, id) => {
    setInputValue(e.target.value);
    setQuestionId(id);

    // Clear the existing debounce timer
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    // Set a new debounce timer to call mutate after 500ms of inactivity
    const newDebounceTimer = setTimeout(() => {
      if (id) {
        mutate({
          patient_id: patientData?.patient?.id,
          initial_contact_id: patientData?.patient?.initial_contact_last?.id,
          questionnaires_id: id,
          discharge_id: patientData?.patient?.discharge_patient_last?.id,
          answer: e.target.value,
        });
      }
    }, 500);

    // Set the new timer ID in the state
    setDebounceTimer(newDebounceTimer);
  };

  useEffect(() => {
    return () => {
      // Clear the debounce timer when the component unmounts
      if (debounceTimer) {
        queryClient.invalidateQueries(`questionnaires/${dischargeData?.id ?? patientData?.patient?.discharge_patient_last?.id}`)
        clearTimeout(debounceTimer);
      }
    };
  }, [debounceTimer]);

  return (
    <Box>
      {questionsLoading || patientDataLoading ? (
        <Loader />
      ) : (
        questionsData?.data?.map((question, index) => {
          return (
            <Box
              key={index}
              sx={{
                width: "100%",
                padding: "10px",
                boxShadow: "0px 3px 6px #00000012",
                borderRadius: "5px",
                background: "#fff",
                marginBottom: "20px",
                "&:last-child": { marginBottom: "0px" },
              }}
            >
              <Box>
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Text variant="h4" color="blue">
                      Q NO {index + 1}:
                    </Text>
                    <Text variant="body1">&nbsp;{question.title}</Text>
                  </Box>

                  {question?.questionnaires_type?.name === "text" ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="text"
                        style={{
                          borderTop: "none",
                          borderBottom: "1px solid #ccc",
                          borderLeft: "none",
                          borderRight: "none",
                          width: "100%",
                        }}
                        onInput={(e) => handleInputChange(e, question?.id)}
                        defaultValue={question?.question_answer?.answer}
                      />
                    </Box>
                  ) : question?.questionnaires_type?.name === "radio" ? (
                    <Box sx={{ marginTop: "20px" }}>
                      <RadioGroup
                        row
                        aria-labelledby="row-radio-buttons-group"
                        name="row-radio-buttons-group"
                        defaultValue={question?.question_answer?.answer}
                        onChange={(e) => {
                          mutate({
                            patient_id: patientData?.patient?.id,
                            initial_contact_id:
                              patientData?.patient?.initial_contact_last?.id,
                            questionnaires_id: question?.id,
                            discharge_id:
                              patientData?.patient?.discharge_patient_last?.id,
                            answer: e.target?.value,
                          });
                        }}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                          sx={{ marginRight: "10px" }}
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </Box>
                  ) : (
                    <SelectField
                      defaultValue={{
                        label: question?.question_answer?.answer,
                        value: question?.question_answer?.answer,
                      }}
                      placeholder={
                        question?.questionnaires_options[0]?.value ?? ""
                      }
                      options={question?.questionnaires_options?.map((item) => {
                        return { label: item.value, value: item.value };
                      })}
                      onChange={(e, val) => {
                        mutate({
                          patient_id: patientData?.patient?.id,
                          initial_contact_id:
                            patientData?.patient?.initial_contact_last?.id,
                          questionnaires_id: question?.id,
                          discharge_id:
                            patientData?.patient?.discharge_patient_last?.id,
                          answer: val?.value,
                        });
                      }}
                    />
                  )}
                </Box>
                {false && (
                  <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Text variant="h4" color="blue">
                        QNO1:
                      </Text>
                      <Text variant="body1">&nbsp;Question</Text>
                    </Box>

                    <Box sx={{ marginTop: "20px" }}>
                      <RadioGroup
                        row
                        aria-labelledby="row-radio-buttons-group"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                          sx={{ marginRight: "10px" }}
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="text"
                        style={{
                          borderTop: "none",
                          borderBottom: "1px solid #ccc",
                          borderLeft: "none",
                          borderRight: "none",
                          width: "100%",
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          );
        })
      )}

      {/* <Flex>
        <Button text="Save" onClick={handleCancel} />
      </Flex> */}
    </Box>
  );
};

export default QuestionAireComponent;
