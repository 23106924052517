import * as yup from "yup";

const billingSchema = yup.object().shape({
  clinical_id: yup.number().required("Clinical ID is required"),
  patient_id: yup.number().required("Patient ID is required"),
  discharge_id: yup.number().required("Discharge ID is required"),
  postingType: yup.string().required("Posting type is required"),
  applyValidation: yup.boolean(),
  amount: yup
    .number()
    .required("Amount is required")
    .min(0, "Amount cannot be negative"),

  patientComplexity: yup
    .string()
    .nullable()
    .test({
      name: "complexity-required",
      test: function (value) {
        // Access the `applyValidation` field from the context
        const applyValidation = this.parent.applyValidation;

        // Check if complexity should be validated
        if (applyValidation && !value) {
          throw this.createError({
            path: "patientComplexity",
            message: "Patient Complexity is required",
          });
        }
        return true;
      },
    }),
});

export default billingSchema;
