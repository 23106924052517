import { Box, Grid } from "@mui/material";
import request from "api/request";
import { useFormik } from "formik";
import useReactQuery from "hooks/useReactQuery";
import useToast from "hooks/useToast";
import FormButtons from "jsx/Common/FormComponents/FormButtons";
import SelectField from "jsx/Common/FormComponents/SelectField";
import Loader from "jsx/Common/Loaders/Loader";
import Text from "jsx/Common/Typography/Text";
import { isArray } from "lodash";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { convertToUnderscore } from "utils/convertToUnderscore";
import { createGenericSchema } from "utils/createGenericSchema";

const RolesModal = ({ setOpen, selectedUser }) => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const [initialValuesSet, setInitialValuesSet] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({}); // Store initial values separately
  const [fields, setFields] = useState([]);

  const { roleId, permissions } = selectedUser;

  const companyID = localStorage.getItem("company_id");
  const roleID = localStorage.getItem("role_id");

  const { useFetch, useAppMutation } = useReactQuery();

  const topRaduis = {
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    border: "1px solid #e2e2e2",
    padding: "5px 10px",
    alignItems: {
      laptop: "center",
      desktop: "center",
      tablet: "center",
      mobile: "start",
    },
    "&:hover": {
      background: "#f2f7ff",
    },
  };
  const bottomRaduis = {
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
    border: "1px solid #e2e2e2",
    padding: "5px 10px",
    alignItems: {
      laptop: "center",
      desktop: "center",
      tablet: "center",
      mobile: "start",
    },
    "&:hover": {
      background: "#f2f7ff",
    },
  };
  const styleRole = {
    border: "1px solid #e2e2e2",
    padding: "5px 10px",
    alignItems: {
      laptop: "center",
      desktop: "center",
      tablet: "center",
      mobile: "start",
    },
    flexDirection: { desktop: "row", tablet: "row" },
    "&:hover": {
      background: "#f2f7ff",
    },
  };

  function createLabelValueFormat(data) {
    if (isArray(data)) {
      let finalArr = [];
      data?.map((val) => finalArr.push({ label: val, value: val }));
      return finalArr;
    } else {
      return { label: data, value: data };
    }
  }

  const { data: rolesWithPermissions, isLoading: rolesWithPermissionsLoading } =
    useFetch(`/company/${companyID}/role/${roleID}/permissions/edit`, () =>
      request({
        method: "get",
        url: `/company/${companyID}/role/${roleID}/permissions/edit`,
      })
    );

  const { mutate, isLoading } = useAppMutation(
    (data) =>
      request({
        method: "put",
        url: "/company/role/permissions/update",
        data,
      }),
    {
      onSuccess: (data) => {
        showToast(`${data?.message} ` ?? "Record update successfully!", {
          type: "success",
        });
        localStorage.setItem("permissions", JSON.stringify(data?.permissions));
        window.dispatchEvent(new Event("storage"));
        queryClient.invalidateQueries([`/company/${companyID}/rol-with-per/`]);

        // comment code for future use
        // queryClient.invalidateQueries([`/company/${companyID}/rol-per/`]);

        formik.resetForm();
        handleCancel();
      },
    }
  );

  const formik = useFormik({
    initialValues: formInitialValues,
    enableReinitialize: true, // Allow reinitialization when initialValues change
    validationSchema: createGenericSchema(fields),
    onSubmit: async (values) => {
      const permissionsArray = Object.values(values).filter(
        (value) => value !== ""
      );

      mutate({
        role_id: roleId,
        company_id: companyID,
        permissions: permissionsArray,
      });
    },
  });
  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Set the form initial values only when the data is successfully fetched
    if (!rolesWithPermissionsLoading && !initialValuesSet) {
      setFields(Object.keys(rolesWithPermissions?.data?.permissions ?? {}));
      const initialValues = Object.keys(
        rolesWithPermissions?.data?.permissions ?? {}
      ).reduce((acc, key) => {
        const matchingPermission = permissions?.find((perm) =>
          perm.startsWith(key)
        );
        acc[key] =
          matchingPermission ||
          rolesWithPermissions?.data?.permissions?.[key]?.[0];
        return acc;
      }, {});
      setFormInitialValues(initialValues);
      setInitialValuesSet(true);
    }
  }, [
    rolesWithPermissions,
    rolesWithPermissionsLoading,
    initialValuesSet,
    permissions,
  ]);

  return (
    <Box>
      {rolesWithPermissionsLoading ? (
        <Loader />
      ) : (
        <Box>
          {/*------ GENERAL------ */}
          <Box>
            <Grid container>
              <Grid
                item
                laptop={12}
                dektop={12}
                tablet={12}
                sx={{
                  display: { mobile: "none", tablet: "flex", desktop: "flex" },
                  overflowX: "auto",
                }}
              >
                <Text variant="h2" color={"blue"} marginbottom="20px">
                  GENERAL
                </Text>
              </Grid>
              <Grid
                item
                laptop={5}
                dektop={6}
                tablet={6}
                sx={{
                  display: { mobile: "none", tablet: "flex", desktop: "flex" },
                  overflowX: "auto",
                }}
              >
                <Text variant="h3" color={"blue"} marginBottom="20px">
                  Modules
                </Text>
              </Grid>
              <Grid
                item
                laptop={7}
                dektop={6}
                tablet={6}
                sx={{
                  display: { mobile: "none", tablet: "flex", desktop: "flex" },
                }}
              >
                <Text variant="h3" color={"blue"} marginBottom="20px">
                  Permissions
                </Text>
              </Grid>
            </Grid>
            <Box>
              <Box>
                {Object.entries(
                  Object.fromEntries(
                    Object.entries(
                      rolesWithPermissions?.data?.permissions
                    ).filter(([key, _]) =>
                      ["rolesManagement", "user"].includes(key)
                    )
                  ) ?? {}
                )
                  ?.sort((a, b) => a[0].localeCompare(b[0]))
                  ?.map((data, index) => (
                    <Grid
                      container
                      sx={
                        index === 0
                          ? topRaduis
                          : index === data?.length - 1
                          ? bottomRaduis
                          : styleRole
                      }
                    >
                      <Grid item laptop={5} desktop={5} tablet={6} mobile={12}>
                        <Text variant="h4">
                          {data?.[0]
                            ? data?.[0].charAt(0).toUpperCase() +
                              data?.[0].slice(1)
                            : ""}
                        </Text>
                      </Grid>
                      <Grid item laptop={7} desktop={7} tablet={6} mobile={12}>
                        <Box>
                          <SelectField
                            name={data[0]}
                            formik={formik}
                            cols={12}
                            options={createLabelValueFormat(data[1])}
                            defaultValue={
                              permissions?.find((permission) =>
                                permission.startsWith(data[0])
                              )
                                ? {
                                    label: permissions?.find((permission) =>
                                      permission.startsWith(data[0])
                                    ),
                                    value: permissions?.find((permission) =>
                                      permission.startsWith(data[0])
                                    ),
                                  }
                                : createLabelValueFormat(data[1][0])
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
              </Box>
            </Box>
          </Box>
          {/*------ TCM------ */}

          <Box>
            <Grid container>
              <Grid
                item
                laptop={12}
                dektop={12}
                tablet={12}
                sx={{
                  display: { mobile: "none", tablet: "flex", desktop: "flex" },
                  overflowX: "auto",
                }}
              >
                <Text
                  variant="h2"
                  color={"blue"}
                  marginBottom="20px"
                  marginTop="40px"
                >
                  TCM
                </Text>
              </Grid>
              <Grid
                item
                laptop={5}
                dektop={6}
                tablet={6}
                sx={{
                  display: { mobile: "none", tablet: "flex", desktop: "flex" },
                  overflowX: "auto",
                }}
              >
                <Text variant="h3" color={"blue"} marginBottom="20px">
                  Modules
                </Text>
              </Grid>
              <Grid
                item
                laptop={7}
                dektop={6}
                tablet={6}
                sx={{
                  display: { mobile: "none", tablet: "flex", desktop: "flex" },
                }}
              >
                <Text variant="h3" color={"blue"} marginBottom="20px">
                  Permissions
                </Text>
              </Grid>
            </Grid>
            <Box>
              <Box>
                {Object.entries(
                  Object.fromEntries(
                    Object.entries(
                      rolesWithPermissions?.data?.permissions
                    ).filter(
                      ([key, _]) =>
                        !key.startsWith("ccm") &&
                        !["rolesManagement", "user"].includes(key)
                    )
                  ) ?? {}
                )
                  ?.sort((a, b) => a[0].localeCompare(b[0]))
                  ?.map((data, index) => (
                    <Grid
                      container
                      sx={
                        index === 0
                          ? topRaduis
                          : index === data?.length - 1
                          ? bottomRaduis
                          : styleRole
                      }
                    >
                      <Grid item laptop={5} desktop={5} tablet={6} mobile={12}>
                        <Text variant="h4">
                          {data?.[0]
                            ? data?.[0].charAt(0).toUpperCase() +
                              data?.[0].slice(1)
                            : ""}
                        </Text>
                      </Grid>
                      <Grid item laptop={7} desktop={7} tablet={6} mobile={12}>
                        <Box>
                          <SelectField
                            name={data[0]}
                            formik={formik}
                            cols={12}
                            options={createLabelValueFormat(data[1])}
                            defaultValue={
                              permissions?.find((permission) =>
                                permission.startsWith(data[0])
                              )
                                ? {
                                    label: permissions?.find((permission) =>
                                      permission.startsWith(data[0])
                                    ),
                                    value: permissions?.find((permission) =>
                                      permission.startsWith(data[0])
                                    ),
                                  }
                                : createLabelValueFormat(data[1][0])
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
              </Box>
            </Box>
          </Box>
          {/*------ CCM------ */}

          <Box>
            <Grid container>
              <Grid
                item
                laptop={12}
                dektop={12}
                tablet={12}
                sx={{
                  display: { mobile: "none", tablet: "flex", desktop: "flex" },
                  overflowX: "auto",
                }}
              >
                <Text variant="h2" color={"blue"} marginTop="40px">
                  CCM
                </Text>
              </Grid>
              <Grid
                item
                laptop={5}
                dektop={6}
                tablet={6}
                sx={{
                  display: { mobile: "none", tablet: "flex", desktop: "flex" },
                  overflowX: "auto",
                }}
              >
                <Text variant="h3" color={"blue"} marginBottom="20px">
                  Modules
                </Text>
              </Grid>
              <Grid
                item
                laptop={7}
                dektop={6}
                tablet={6}
                sx={{
                  display: { mobile: "none", tablet: "flex", desktop: "flex" },
                }}
              >
                <Text variant="h3" color={"blue"} marginBottom="20px">
                  Permissions
                </Text>
              </Grid>
            </Grid>

            <Box>
              {Object.entries(
                Object.fromEntries(
                  Object.entries(
                    rolesWithPermissions?.data?.permissions
                  ).filter(([key, _]) => key.startsWith("ccm"))
                ) ?? {}
              )
                ?.sort((a, b) => a[0].localeCompare(b[0]))
                ?.map((data, index) => (
                  <Grid
                    container
                    sx={
                      index === 0
                        ? topRaduis
                        : index === data?.length - 1
                        ? bottomRaduis
                        : styleRole
                    }
                  >
                    <Grid item laptop={5} desktop={5} tablet={6} mobile={12}>
                      <Text variant="h4">
                        {data?.[0]
                          ? data?.[0].charAt(0).toUpperCase() +
                            data?.[0].slice(1)
                          : ""}
                      </Text>
                    </Grid>
                    <Grid item laptop={7} desktop={7} tablet={6} mobile={12}>
                      <Box>
                        <SelectField
                          name={data[0]}
                          formik={formik}
                          cols={12}
                          options={createLabelValueFormat(data[1])}
                          defaultValue={
                            permissions?.find((permission) =>
                              permission.startsWith(
                                convertToUnderscore(data[0])
                              )
                            )
                              ? {
                                  label: permissions?.find((permission) =>
                                    permission.startsWith(
                                      convertToUnderscore(data[0])
                                    )
                                  ),

                                  value: permissions?.find((permission) =>
                                    permission.startsWith(
                                      convertToUnderscore(data[0])
                                    )
                                  ),
                                }
                              : createLabelValueFormat(data[1][0])
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                ))}
            </Box>
          </Box>

          <Box sx={{ mt: 1 }}>
            <FormButtons
              saveLoading={isLoading}
              onClickSave={formik.submitForm}
              onClickCancel={handleCancel}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default RolesModal;
