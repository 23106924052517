import CreateIcon from "@mui/icons-material/Create";
import { IconButton } from "@mui/material";
import request from "api/request";
import useFilteredPermissions from "hooks/useFilteredPermissions";
import useReactQuery from "hooks/useReactQuery";
import CustomModal from "jsx/Common/Modal/CustomModal";
import GenericTable from "jsx/Common/Table/GenericTable";
import { useState } from "react";
import RolesModal from "./RoleModal/RolesModal";

const RolesTable = () => {
  const [selectedUser, setSelectedUser] = useState({});

  const [open, setOpen] = useState(false);

  const companyID = localStorage.getItem("company_id");

  const { useFetch } = useReactQuery();
  const {
    hasPermission,
  } = useFilteredPermissions();

  const { data: rolesData, isLoading: rolesIsLoading } = useFetch(
    `/company/${companyID}/rol-with-per/`,
    () =>
      request({
        method: "get",
        url: `company/${companyID}/roles-permissions`,
      }),
    {
      enabled: !!companyID,
    }
  );

  const columns = [
    {
      Header: "Roles",
      accessor: "roles",
      Cell: ({ row }) => {
        return (
          row?.original?.role
            ?.replace(/_/g, " ")
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ") ?? ""
        );
      },
    },
    {
      Header: "Permissions",
      accessor: "permissions",
      Cell: ({ row }) => {
        const formattedData = row?.original?.permissions
          .map((str) => `${str},<br/>`)
          .join(" ");
        return formattedData ? (
          <div dangerouslySetInnerHTML={{ __html: formattedData }} />
        ) : (
          ""
        );
      },
    },

    ...(hasPermission("rolesManagement_view_create_edit")
      ? [
          {
            Header: "Actions",
            accessor: "actions",
            Cell: ({ row }) => {
              return (
                <IconButton
                  onClick={() => {
                    setOpen(true);
                    setSelectedUser(row?.original);
                  }}
                >
                  <CreateIcon
                    sx={{
                      padding: "2px",
                      borderRadius: "50%",
                      border: "1px solid #1C4588",
                      color: "#1C4588",
                      width: "30px",
                      height: "30px",
                    }}
                  />
                </IconButton>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <>
      <CustomModal
        setOpen={setOpen}
        open={open}
        title={
          selectedUser?.role
            ? `Edit ${selectedUser?.role
                ?.replace(/_/g, " ")
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
           Role`
            : "Edit Role"
        }
        width="100%"
        maxWidth="laptop"
        fullWidth={true}
      >
        <RolesModal
          setOpen={setOpen}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      </CustomModal>
      <GenericTable
        isLoading={rolesIsLoading}
        columns={columns}
        data={rolesData}
      />
    </>
  );
};

export default RolesTable;
