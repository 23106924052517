import Box from "@mui/material/Box";
import { GridLoader } from "react-spinners";
import EntranceAnimationWrapper from "../Animations/EntranceAnimationWrapper";
function Loader() {
  return (
    <EntranceAnimationWrapper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <GridLoader color="#1C4588" />
      </Box>
    </EntranceAnimationWrapper>
  );
}

export default Loader;
