import * as yup from "yup";
const clinicExaminationSchema = yup.object().shape({
  appointment_id: yup.number().required("Appointment ID is required."),
  patient_id: yup.number().required("Patient ID is required."),
  reviewComplexity: yup.boolean().required("Review Complexity is required."),
  reviewQuestionnaire: yup
    .number()
    .required("Review Questionnaire is required."),
  pointOfService: yup.string().required("Point of Service is required."),
  tcmProvider: yup.number().required("TCM Provider is required."),
  medicationChangesOrAdjustment: yup
    .string()
    .required("Medication Changes or Adjustment is required."),
  medicineReconciliation: yup
    .string()
    .required("Medicine Reconciliation is required."),
  reviewDischargeSummary: yup
    .string()
    .required("Review Discharge Summary is required."),
  patientOrCaregiverEducation: yup
    .string()
    .required("Patient or Caregiver Education is required."),
  referralsWithOtherPoviders: yup
    .string()
    .required("Referrals with Other Providers is required."),
  otherServices: yup.string().required("Other Services is required."),
  providerCheckin: yup.string().required("Check Date is required."),
  // assignTo: yup.number().required("Assign To is required."),
  assignDateAndTime: yup.string(),
  doctorConsent: yup.boolean().required("Doctor Consent is required."),
});
const reAppointmentSchema = yup.object().shape({
  appointmentDateIndicator: yup.string().required("Appointment Date is required."),
});
const MinimumCaseclinicExaminationSchema = yup.object().shape({
  appointment_id: yup.number().required("Appointment ID is required."),
  patient_id: yup.number().required("Patient ID is required."),
  reviewComplexity: yup.boolean().required("Review Complexity is required."),
  providerCheckin: yup.string().required("Check Date is required."),
  doctorConsent: yup.boolean().required("Doctor Consent is required."),
  // assignTo: yup.number().required("Assign To is required."),
  // reviewQuestionnaire: yup
  //   .number()
  //   .required("Review Questionnaire is required."),
  tcmProvider: yup.number().required("TCM Provider is required."),
  // reviewDischargeSummary: yup
  //   .string()
  //   .required("Review Discharge Summary is required."),

});

export { MinimumCaseclinicExaminationSchema, clinicExaminationSchema, reAppointmentSchema };

