import { Box } from "@mui/material";
import React from "react";

const Wrapper = ({
  children,
  padding = "15px",
  boxShadow = "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  borderRadius = "10px",
  marginTop = "",
  marginBottom = "",
  ...props
}) => {
  return (
    <Box
      sx={{
        padding: padding,
        boxShadow: boxShadow,
        borderRadius: borderRadius,
        marginTop: marginTop,
        marginBottom: marginBottom,
       ...props

      }}
    >
      {children}
    </Box>
  );
};

export default Wrapper;
