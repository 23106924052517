import * as Yup from "yup";

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

const NewUserSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  username: Yup.string(),
  email: Yup.string().required("Email is required"),
  role: Yup.string().required(" Role is required"),
  // hospital: Yup.string().required(" Facility is required"),
  status: Yup.string().required(" Status is required"),
  password: Yup.string()
    .matches(
      passwordRegex,
      "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one digit, and one special character."
    )
    .required("Password is required"),
  password_confirmation: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});
const FacilityUserSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  username: Yup.string(),
  email: Yup.string().required("Email is required"),
  role: Yup.string().required(" Role is required"),
  hospital: Yup.string().required(" Facility is required"),
  status: Yup.string().required(" Status is required"),
  password: Yup.string()
    .matches(
      passwordRegex,
      "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one digit, and one special character."
    )
    .required("Password is required"),
  password_confirmation: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});
const FacilityEditUserSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  username: Yup.string(),
  email: Yup.string().required("Email is required"),
  role: Yup.string().required(" Role is required"),
  hospital: Yup.string().required(" Facility is required"),
  status: Yup.string().required(" Status is required"),
});
const EditUserSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  username: Yup.string(),
  email: Yup.string().required("Email is required"),
  role: Yup.string().required(" Role is required"),
  // hospital: Yup.string().required(" Facility is required"),
  status: Yup.string().required(" Status is required"),
});
// not used now because we create generic schema for this
const EditRolePermisson = Yup.object().shape({
  user: Yup.string().required("User is required"),
  patient: Yup.string().required("Patient is required"),
  dischargeSummary: Yup.string().required("DischargeSummary is required"),
  complexity: Yup.string().required("Complexity is required"),
  initialContact: Yup.string().required("InitialContact is required"),
  appointment: Yup.string().required("Appointment is required"),
  clinical: Yup.string().required("Clinical is required"),
  feedback: Yup.string().required("Feedback is required"),
  tcmBilling: Yup.string().required("TcmBilling is required"),
  tcmReimbursement: Yup.string().required("TcmReimbursement is required"),
  dashboard: Yup.string().required("Dashboard is required"),
  report: Yup.string().required("Report is required"),
  rolesManagement: Yup.string().required("RolesManagement is required"),
});

// Function to generate a generic Yup schema
const generateSchema = (fields) => {
  const schemaObject = fields.reduce((accumulator, field) => {
    accumulator[field.name] = field.validation || Yup.string();
    return accumulator;
  }, {});

  return Yup.object().shape(schemaObject);
};

export {
  EditUserSchema,
  FacilityEditUserSchema,
  FacilityUserSchema,
  NewUserSchema,
  EditRolePermisson,
  generateSchema,
};
