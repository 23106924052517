import { Grid } from "@mui/material";
import {
  ImmunizationProtectionOptions,
  PublicityCodeOptions,
} from "assets/static/ccmStaticData";
import SelectField from "jsx/Common/FormComponents/SelectField";
import { memo } from "react";

const ImmunizationRegistryFrom = ({ formik }) => {
  return (
    <Grid container spacing={1} columnSpacing={1}>
      <SelectField
        cols={6}
        label={"Immunization Protection"}
        name="immunizationProtection"
        options={ImmunizationProtectionOptions}
        formik={formik}
        defaultValue={{
          label: formik.values.immunizationProtection
            ? ImmunizationProtectionOptions.find(
                (option) =>
                  option.value === formik.values.immunizationProtection
              )?.label
            : "",
          value: formik.values.immunizationProtection
            ? ImmunizationProtectionOptions.find(
                (option) =>
                  option.value === formik.values.immunizationProtection
              )?.value
            : "",
        }}
      />
      <SelectField
        label={"Publicity Code"}
        name="publicityCode"
        cols={6}
        options={PublicityCodeOptions}
        formik={formik}
        defaultValue={{
          label: formik.values.publicityCode
            ? PublicityCodeOptions.find(
                (option) => option.value === formik.values.publicityCode
              )?.label
            : "",
          value: formik.values.publicityCode
            ? PublicityCodeOptions.find(
                (option) => option.value === formik.values.publicityCode
              )?.value
            : "",
        }}
      />
    </Grid>
  );
};

export default memo(ImmunizationRegistryFrom);
