import { Grid, useTheme } from "@mui/material";
import Button from "../Button/Button";
import Flex from "../Layouts/Flex/Flex";

const FormButtons = ({
  save = "Save",
  cancel = "Cancel",
  onClickSave,
  onClickCancel,
  saveLoading = false,
  cancelLoading = false,
  saveID = "",
  cancelID = "",
  justifyContent = "center",
  isCancel = true,
}) => {
  const theme = useTheme();
  return (
    <Grid container spacing={1}>
      <Grid item mobile={12} tablet={12}>
        <Flex justifyContent={justifyContent}>
          <Button
            variant="contained"
            onClick={onClickSave}
            text={save}
            marginRight="10px"
            isLoading={saveLoading}
            {...(saveID ? { id: saveID } : {})}
          />
          {isCancel && (
            <Button
              variant="contained"
              onClick={onClickCancel}
              text={cancel}
              isLoading={cancelLoading}
              sx={{
                background: theme.palette.common.white,
                color: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.common.white,
                  color: theme.palette.primary.main,
                },
              }}
              {...(cancelID ? { id: cancelID } : {})}
            />
          )}
        </Flex>
      </Grid>
    </Grid>
  );
};

export default FormButtons;
