import ScreenTitleContext from "contexts/ScreenTitleContext";
import { useContext, useEffect } from "react";

const useTitle = ({ title, titleBody }) => {
  const { setTitle, setTitleBody } = useContext(ScreenTitleContext);

  useEffect(() => {
    setTitle(title);
    setTitleBody(titleBody);
  }, []);
};

export default useTitle;
